import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { TbFilterMinus } from 'react-icons/tb';

import { TableColumnSelectProps } from './TableColumnSelect.interface';
import { saveLocalStorageTablePreferences } from '../../../../localStorage/localStorageFunctions';
import Icon from '../../../../Atoms/Icon/Icon';
import { useContext } from 'react';
import { ProjectContext } from '../../../../../../Context/ContextAPI';
import { UserData } from '../../../../../Map/types';
import { useTranslation } from 'react-i18next';

export const TableColumnSelect = ({
  tableId,
  unselectedColumns,
  setUnselectColumns,
  columns
}: TableColumnSelectProps) => {
  const { t: translate } = useTranslation();
  const columnsKeys = columns.map((column) => column.key);
  const { userData } = useContext(ProjectContext) as { userData: UserData };
  const userId = userData.id;
  const handleChange = (event: SelectChangeEvent<typeof columnsKeys>) => {
    const {
      target: { value }
    } = event;
    const unselectColumns =
      typeof value === 'string' ? value.split(',') : (value as string[]);

    saveLocalStorageTablePreferences(
      userId,
      tableId,
      'unselectColumns',
      unselectColumns
    );
    setUnselectColumns(unselectColumns);
  };

  return (
    <Select
      labelId="demo-multiple-checkbox-label"
      multiple
      value={unselectedColumns}
      onChange={handleChange}
      input={
        <OutlinedInput
          label=""
          sx={{
            border: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          }}
        />
      }
      renderValue={() => <Icon Icon={TbFilterMinus} size={20} />}
    >
      {columnsKeys.map((name) => (
        <MenuItem key={name} value={name}>
          <Checkbox checked={!unselectedColumns.includes(name)} />
          <ListItemText primary={translate(name)} />
        </MenuItem>
      ))}
    </Select>
  );
};
