import {
  FieldPolicy,
  FieldReadFunction,
  TypePolicies,
  TypePolicy
} from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {}
};
export default result;

export type ActionPlanCommentFilesTypeKeySpecifier = (
  | 'fileName'
  | 'id'
  | 'url'
  | ActionPlanCommentFilesTypeKeySpecifier
)[];
export type ActionPlanCommentFilesTypeFieldPolicy = {
  fileName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActionPlanCommentsDataTypeKeySpecifier = (
  | 'comment'
  | 'createdAt'
  | 'files'
  | 'id'
  | 'userName'
  | ActionPlanCommentsDataTypeKeySpecifier
)[];
export type ActionPlanCommentsDataTypeFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  files?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  userName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActionPlanDataTypeKeySpecifier = (
  | 'code'
  | 'comments'
  | 'description'
  | 'images'
  | 'imagesCount'
  | 'priority'
  | 'responsible'
  | 'situation'
  | 'teamMembers'
  | 'timeFrame'
  | ActionPlanDataTypeKeySpecifier
)[];
export type ActionPlanDataTypeFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  imagesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  responsible?: FieldPolicy<any> | FieldReadFunction<any>;
  situation?: FieldPolicy<any> | FieldReadFunction<any>;
  teamMembers?: FieldPolicy<any> | FieldReadFunction<any>;
  timeFrame?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActionPlanResponsibleDataTypeKeySpecifier = (
  | 'id'
  | 'name'
  | ActionPlanResponsibleDataTypeKeySpecifier
)[];
export type ActionPlanResponsibleDataTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActionPlanResponsibleTypeKeySpecifier = (
  | 'email'
  | 'id'
  | 'name'
  | ActionPlanResponsibleTypeKeySpecifier
)[];
export type ActionPlanResponsibleTypeFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActionPlanTeamMembersDataTypeKeySpecifier = (
  | 'email'
  | 'id'
  | 'name'
  | ActionPlanTeamMembersDataTypeKeySpecifier
)[];
export type ActionPlanTeamMembersDataTypeFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdminBaseCompanyTypeKeySpecifier = (
  | 'cnpj'
  | 'id'
  | 'name'
  | AdminBaseCompanyTypeKeySpecifier
)[];
export type AdminBaseCompanyTypeFieldPolicy = {
  cnpj?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdminUserRoleTypeKeySpecifier = (
  | 'role'
  | AdminUserRoleTypeKeySpecifier
)[];
export type AdminUserRoleTypeFieldPolicy = {
  role?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdminUserTypeKeySpecifier = (
  | 'company'
  | 'email'
  | 'id'
  | 'name'
  | 'phoneNumber'
  | 'position'
  | 'role'
  | 'signature'
  | 'status'
  | AdminUserTypeKeySpecifier
)[];
export type AdminUserTypeFieldPolicy = {
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phoneNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
  signature?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AlertLevelToChartTypeKeySpecifier = (
  | 'name'
  | 'x'
  | 'y'
  | AlertLevelToChartTypeKeySpecifier
)[];
export type AlertLevelToChartTypeFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  x?: FieldPolicy<any> | FieldReadFunction<any>;
  y?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AssociatedStructureTypeKeySpecifier = (
  | 'associatedStructureId'
  | 'associatedStructureName'
  | AssociatedStructureTypeKeySpecifier
)[];
export type AssociatedStructureTypeFieldPolicy = {
  associatedStructureId?: FieldPolicy<any> | FieldReadFunction<any>;
  associatedStructureName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AssociatedTypeKeySpecifier = (
  | 'active'
  | 'hydrologyInfos'
  | 'id'
  | 'isAssociated'
  | 'name'
  | 'summary'
  | 'type'
  | AssociatedTypeKeySpecifier
)[];
export type AssociatedTypeFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  hydrologyInfos?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isAssociated?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  summary?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoDocsIdModelTypeKeySpecifier = (
  | 'id'
  | 'name'
  | AtoDocsIdModelTypeKeySpecifier
)[];
export type AtoDocsIdModelTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoImageTypeKeySpecifier = ('image' | AtoImageTypeKeySpecifier)[];
export type AtoImageTypeFieldPolicy = {
  image?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoImagesTypeKeySpecifier = (
  | 'images'
  | 'recordNumber'
  | AtoImagesTypeKeySpecifier
)[];
export type AtoImagesTypeFieldPolicy = {
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  recordNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoRecordActivitiesDataTypeKeySpecifier = (
  | 'description'
  | 'id'
  | 'images'
  | 'videos'
  | AtoRecordActivitiesDataTypeKeySpecifier
)[];
export type AtoRecordActivitiesDataTypeFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  videos?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoRecordActivitiesImagesTypeKeySpecifier = (
  | 'id'
  | 'image'
  | AtoRecordActivitiesImagesTypeKeySpecifier
)[];
export type AtoRecordActivitiesImagesTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoRecordActivitiesVideosTypeKeySpecifier = (
  | 'id'
  | 'video'
  | AtoRecordActivitiesVideosTypeKeySpecifier
)[];
export type AtoRecordActivitiesVideosTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  video?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoRecordEquipmentAndLaborDataTypeKeySpecifier = (
  | 'description'
  | 'id'
  | 'quantity'
  | AtoRecordEquipmentAndLaborDataTypeKeySpecifier
)[];
export type AtoRecordEquipmentAndLaborDataTypeFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  quantity?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoRecordImagesTypeKeySpecifier = (
  | 'id'
  | 'image'
  | 'observation'
  | AtoRecordImagesTypeKeySpecifier
)[];
export type AtoRecordImagesTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoRecordObservationsTypeKeySpecifier = (
  | 'id'
  | 'observation'
  | AtoRecordObservationsTypeKeySpecifier
)[];
export type AtoRecordObservationsTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoRecordRedAlertPeriodTypeKeySpecifier = (
  | 'end'
  | 'id'
  | 'start'
  | AtoRecordRedAlertPeriodTypeKeySpecifier
)[];
export type AtoRecordRedAlertPeriodTypeFieldPolicy = {
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoRecordSignaturesDataTypeKeySpecifier = (
  | 'id'
  | 'signature'
  | 'userType'
  | AtoRecordSignaturesDataTypeKeySpecifier
)[];
export type AtoRecordSignaturesDataTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  signature?: FieldPolicy<any> | FieldReadFunction<any>;
  userType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoRecordVideoTypeKeySpecifier = (
  | 'id'
  | 'observation'
  | 'video'
  | AtoRecordVideoTypeKeySpecifier
)[];
export type AtoRecordVideoTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  video?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoRecordWeatherConditionsTypeKeySpecifier = (
  | 'end'
  | 'id'
  | 'start'
  | 'weather'
  | AtoRecordWeatherConditionsTypeKeySpecifier
)[];
export type AtoRecordWeatherConditionsTypeFieldPolicy = {
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  weather?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoRecordWorkShiftTypeKeySpecifier = (
  | 'entry'
  | 'entryInterval'
  | 'exit'
  | 'exitInterval'
  | 'id'
  | 'observation'
  | 'totalHours'
  | AtoRecordWorkShiftTypeKeySpecifier
)[];
export type AtoRecordWorkShiftTypeFieldPolicy = {
  entry?: FieldPolicy<any> | FieldReadFunction<any>;
  entryInterval?: FieldPolicy<any> | FieldReadFunction<any>;
  exit?: FieldPolicy<any> | FieldReadFunction<any>;
  exitInterval?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  totalHours?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoReferencesDocsTypeKeySpecifier = (
  | 'id'
  | 'name'
  | AtoReferencesDocsTypeKeySpecifier
)[];
export type AtoReferencesDocsTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoUserTypesTypeKeySpecifier = (
  | 'id'
  | 'name'
  | 'userType'
  | AtoUserTypesTypeKeySpecifier
)[];
export type AtoUserTypesTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  userType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoVideoTypeKeySpecifier = ('video' | AtoVideoTypeKeySpecifier)[];
export type AtoVideoTypeFieldPolicy = {
  video?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AtoVideosTypeKeySpecifier = (
  | 'recordNumber'
  | 'videos'
  | AtoVideosTypeKeySpecifier
)[];
export type AtoVideosTypeFieldPolicy = {
  recordNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  videos?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BaseIdNameTypeKeySpecifier = (
  | 'id'
  | 'name'
  | BaseIdNameTypeKeySpecifier
)[];
export type BaseIdNameTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BaseInstrumentListTypeKeySpecifier = (
  | 'activationDate'
  | 'coordinateE'
  | 'coordinateN'
  | 'deactivationDate'
  | 'installLocation'
  | 'installationDate'
  | 'name'
  | 'operationalStatus'
  | 'readingType'
  | BaseInstrumentListTypeKeySpecifier
)[];
export type BaseInstrumentListTypeFieldPolicy = {
  activationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  installationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BaseInstrumentTypeKeySpecifier = (
  | 'activationDate'
  | 'coordinateE'
  | 'coordinateN'
  | 'deactivationDate'
  | 'id'
  | 'installLocation'
  | 'installationDate'
  | 'isUTM'
  | 'name'
  | 'operationalStatus'
  | 'readingType'
  | 'status'
  | 'type'
  | BaseInstrumentTypeKeySpecifier
)[];
export type BaseInstrumentTypeFieldPolicy = {
  activationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  installationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasePluviometerReadingTypeKeySpecifier = (
  | 'date'
  | 'id'
  | 'observation'
  | 'rainfall'
  | BasePluviometerReadingTypeKeySpecifier
)[];
export type BasePluviometerReadingTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  rainfall?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BaseSurfaceMarkersReadingTypeKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'date'
  | 'elevation'
  | 'id'
  | 'observation'
  | BaseSurfaceMarkersReadingTypeKeySpecifier
)[];
export type BaseSurfaceMarkersReadingTypeFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  elevation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BaseWaterLevelReadingTypeKeySpecifier = (
  | 'date'
  | 'id'
  | 'observation'
  | 'value'
  | BaseWaterLevelReadingTypeKeySpecifier
)[];
export type BaseWaterLevelReadingTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasicDataToChartTypeKeySpecifier = (
  | 'x'
  | 'y'
  | BasicDataToChartTypeKeySpecifier
)[];
export type BasicDataToChartTypeFieldPolicy = {
  x?: FieldPolicy<any> | FieldReadFunction<any>;
  y?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasicInstrumentTypeKeySpecifier = (
  | 'activationDate'
  | 'coordinateE'
  | 'coordinateN'
  | 'deactivationDate'
  | 'id'
  | 'installLocation'
  | 'installationDate'
  | 'isUTM'
  | 'name'
  | 'operationalStatus'
  | 'readingType'
  | 'status'
  | 'type'
  | BasicInstrumentTypeKeySpecifier
)[];
export type BasicInstrumentTypeFieldPolicy = {
  activationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  installationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasicInstrumentTypeTypeKeySpecifier = (
  | 'id'
  | 'instrumentType'
  | BasicInstrumentTypeTypeKeySpecifier
)[];
export type BasicInstrumentTypeTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasicInstrumentTypeWithSectionAndDocsKeySpecifier = (
  | 'activationDate'
  | 'coordinateE'
  | 'coordinateN'
  | 'deactivationDate'
  | 'docs'
  | 'id'
  | 'installLocation'
  | 'installationDate'
  | 'isUTM'
  | 'name'
  | 'operationalStatus'
  | 'readingType'
  | 'sections'
  | 'status'
  | 'type'
  | BasicInstrumentTypeWithSectionAndDocsKeySpecifier
)[];
export type BasicInstrumentTypeWithSectionAndDocsFieldPolicy = {
  activationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  docs?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  installationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  sections?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasicSectionsTypeKeySpecifier = (
  | 'id'
  | 'isUTM'
  | 'name'
  | 'status'
  | 'type'
  | BasicSectionsTypeKeySpecifier
)[];
export type BasicSectionsTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasicStructureTypeKeySpecifier = (
  | 'id'
  | 'name'
  | 'status'
  | 'type'
  | BasicStructureTypeKeySpecifier
)[];
export type BasicStructureTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasicUserTypeKeySpecifier = (
  | 'email'
  | 'name'
  | BasicUserTypeKeySpecifier
)[];
export type BasicUserTypeFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasicUserWithIdTypeKeySpecifier = (
  | 'email'
  | 'id'
  | 'name'
  | BasicUserWithIdTypeKeySpecifier
)[];
export type BasicUserWithIdTypeFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasicUserWithPhoneNumberTypeKeySpecifier = (
  | 'email'
  | 'name'
  | 'phoneNumber'
  | BasicUserWithPhoneNumberTypeKeySpecifier
)[];
export type BasicUserWithPhoneNumberTypeFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phoneNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CalculatedInfosTypeKeySpecifier = (
  | 'totalPlans'
  | 'totalPlansDone'
  | 'totalPlansHighPriority'
  | 'totalPlansInProgress'
  | 'totalPlansLate'
  | 'totalPlansOpened'
  | CalculatedInfosTypeKeySpecifier
)[];
export type CalculatedInfosTypeFieldPolicy = {
  totalPlans?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPlansDone?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPlansHighPriority?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPlansInProgress?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPlansLate?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPlansOpened?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChartPiezometerReadingTypeKeySpecifier = (
  | 'alertLevels'
  | 'cotes'
  | 'instrumentId'
  | 'name'
  | 'readings'
  | ChartPiezometerReadingTypeKeySpecifier
)[];
export type ChartPiezometerReadingTypeFieldPolicy = {
  alertLevels?: FieldPolicy<any> | FieldReadFunction<any>;
  cotes?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  readings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChartPluviometerReadingTypeKeySpecifier = (
  | 'instrumentId'
  | 'name'
  | 'readings'
  | ChartPluviometerReadingTypeKeySpecifier
)[];
export type ChartPluviometerReadingTypeFieldPolicy = {
  instrumentId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  readings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChartSurfaceMarkerReadingTypeKeySpecifier = (
  | 'displacementElevationData'
  | 'elevationData'
  | 'instrumentId'
  | 'name'
  | ChartSurfaceMarkerReadingTypeKeySpecifier
)[];
export type ChartSurfaceMarkerReadingTypeFieldPolicy = {
  displacementElevationData?: FieldPolicy<any> | FieldReadFunction<any>;
  elevationData?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChartWaterLevelReadingTypeKeySpecifier = (
  | 'instrumentId'
  | 'name'
  | 'readings'
  | 'spillwaySill'
  | ChartWaterLevelReadingTypeKeySpecifier
)[];
export type ChartWaterLevelReadingTypeFieldPolicy = {
  instrumentId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  readings?: FieldPolicy<any> | FieldReadFunction<any>;
  spillwaySill?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CompanyAddressTypeKeySpecifier = (
  | 'addressLine'
  | 'city'
  | 'country'
  | 'number'
  | 'state'
  | 'zipCode'
  | CompanyAddressTypeKeySpecifier
)[];
export type CompanyAddressTypeFieldPolicy = {
  addressLine?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  number?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  zipCode?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CompanyRelatedUsersAndStructuresTypeKeySpecifier = (
  | 'cnpj'
  | 'collaborators'
  | 'id'
  | 'logo'
  | 'name'
  | 'status'
  | 'structures'
  | CompanyRelatedUsersAndStructuresTypeKeySpecifier
)[];
export type CompanyRelatedUsersAndStructuresTypeFieldPolicy = {
  cnpj?: FieldPolicy<any> | FieldReadFunction<any>;
  collaborators?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  logo?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  structures?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CompanyTypeKeySpecifier = (
  | 'address'
  | 'cnpj'
  | 'id'
  | 'logo'
  | 'name'
  | 'status'
  | CompanyTypeKeySpecifier
)[];
export type CompanyTypeFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  cnpj?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  logo?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CompanyWithStructureTypeKeySpecifier = (
  | 'address'
  | 'cnpj'
  | 'id'
  | 'logo'
  | 'name'
  | 'status'
  | 'structures'
  | CompanyWithStructureTypeKeySpecifier
)[];
export type CompanyWithStructureTypeFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  cnpj?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  logo?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  structures?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ControlCotesTypeKeySpecifier = (
  | 'bottomCote'
  | 'id'
  | 'referenceDate'
  | 'topCote'
  | ControlCotesTypeKeySpecifier
)[];
export type ControlCotesTypeFieldPolicy = {
  bottomCote?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  referenceDate?: FieldPolicy<any> | FieldReadFunction<any>;
  topCote?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreatedInspectionGenericDataTypeKeySpecifier = (
  | 'answer'
  | 'id'
  | 'observation'
  | 'title'
  | 'topic'
  | CreatedInspectionGenericDataTypeKeySpecifier
)[];
export type CreatedInspectionGenericDataTypeFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreatedMassiveStructureShoulderDataTypeKeySpecifier = (
  | 'genericData'
  | 'identifiedCracks'
  | 'identifiedDeformations'
  | 'identifiedWaterLeaks'
  | CreatedMassiveStructureShoulderDataTypeKeySpecifier
)[];
export type CreatedMassiveStructureShoulderDataTypeFieldPolicy = {
  genericData?: FieldPolicy<any> | FieldReadFunction<any>;
  identifiedCracks?: FieldPolicy<any> | FieldReadFunction<any>;
  identifiedDeformations?: FieldPolicy<any> | FieldReadFunction<any>;
  identifiedWaterLeaks?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CrossSectionKeySpecifier = (
  | 'id'
  | 'name'
  | CrossSectionKeySpecifier
)[];
export type CrossSectionFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DamRaisingTypeKeySpecifier = (
  | 'date'
  | 'elevation'
  | 'id'
  | 'method'
  | 'stage'
  | DamRaisingTypeKeySpecifier
)[];
export type DamRaisingTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  elevation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  stage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataForSectionChartTypeKeySpecifier = (
  | 'imageHeight'
  | 'imageWidth'
  | 'inferiorPointY'
  | 'piezometers'
  | 'sectionImage'
  | 'sectionName'
  | DataForSectionChartTypeKeySpecifier
)[];
export type DataForSectionChartTypeFieldPolicy = {
  imageHeight?: FieldPolicy<any> | FieldReadFunction<any>;
  imageWidth?: FieldPolicy<any> | FieldReadFunction<any>;
  inferiorPointY?: FieldPolicy<any> | FieldReadFunction<any>;
  piezometers?: FieldPolicy<any> | FieldReadFunction<any>;
  sectionImage?: FieldPolicy<any> | FieldReadFunction<any>;
  sectionName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DisplacementsKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'elevation'
  | DisplacementsKeySpecifier
)[];
export type DisplacementsFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  elevation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FileTypeKeySpecifier = (
  | 'date'
  | 'file'
  | 'id'
  | 'name'
  | FileTypeKeySpecifier
)[];
export type FileTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  file?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FindAllAtoTypeKeySpecifier = (
  | 'canFinish'
  | 'client'
  | 'clientApprover'
  | 'contract'
  | 'docs'
  | 'finalDate'
  | 'id'
  | 'initialDate'
  | 'responsibleCompany'
  | 'situation'
  | 'title'
  | 'verifier'
  | FindAllAtoTypeKeySpecifier
)[];
export type FindAllAtoTypeFieldPolicy = {
  canFinish?: FieldPolicy<any> | FieldReadFunction<any>;
  client?: FieldPolicy<any> | FieldReadFunction<any>;
  clientApprover?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  docs?: FieldPolicy<any> | FieldReadFunction<any>;
  finalDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  initialDate?: FieldPolicy<any> | FieldReadFunction<any>;
  responsibleCompany?: FieldPolicy<any> | FieldReadFunction<any>;
  situation?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  verifier?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FindAllRecordsTypeKeySpecifier = (
  | 'date'
  | 'id'
  | 'recordImagesCount'
  | 'recordNumber'
  | 'recordVideosCount'
  | 'situation'
  | FindAllRecordsTypeKeySpecifier
)[];
export type FindAllRecordsTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  recordImagesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  recordNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  recordVideosCount?: FieldPolicy<any> | FieldReadFunction<any>;
  situation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FindDataByRecordTypeKeySpecifier = (
  | 'activities'
  | 'clientLogo'
  | 'contract'
  | 'contractualPeriod'
  | 'elaborator'
  | 'elapsedTerm'
  | 'equipments'
  | 'inspectionObservations'
  | 'labor'
  | 'recordDate'
  | 'recordImages'
  | 'recordNumber'
  | 'recordSignatures'
  | 'recordVideos'
  | 'redAlertPeriods'
  | 'referencesDocs'
  | 'remainingTerm'
  | 'responsibleCompany'
  | 'responsibleCompanyLogo'
  | 'responsibleCompanyObservations'
  | 'situation'
  | 'title'
  | 'userTypes'
  | 'weatherConditions'
  | 'workSafetyObservations'
  | 'workShifts'
  | FindDataByRecordTypeKeySpecifier
)[];
export type FindDataByRecordTypeFieldPolicy = {
  activities?: FieldPolicy<any> | FieldReadFunction<any>;
  clientLogo?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  contractualPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  elaborator?: FieldPolicy<any> | FieldReadFunction<any>;
  elapsedTerm?: FieldPolicy<any> | FieldReadFunction<any>;
  equipments?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionObservations?: FieldPolicy<any> | FieldReadFunction<any>;
  labor?: FieldPolicy<any> | FieldReadFunction<any>;
  recordDate?: FieldPolicy<any> | FieldReadFunction<any>;
  recordImages?: FieldPolicy<any> | FieldReadFunction<any>;
  recordNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  recordSignatures?: FieldPolicy<any> | FieldReadFunction<any>;
  recordVideos?: FieldPolicy<any> | FieldReadFunction<any>;
  redAlertPeriods?: FieldPolicy<any> | FieldReadFunction<any>;
  referencesDocs?: FieldPolicy<any> | FieldReadFunction<any>;
  remainingTerm?: FieldPolicy<any> | FieldReadFunction<any>;
  responsibleCompany?: FieldPolicy<any> | FieldReadFunction<any>;
  responsibleCompanyLogo?: FieldPolicy<any> | FieldReadFunction<any>;
  responsibleCompanyObservations?: FieldPolicy<any> | FieldReadFunction<any>;
  situation?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  userTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  weatherConditions?: FieldPolicy<any> | FieldReadFunction<any>;
  workSafetyObservations?: FieldPolicy<any> | FieldReadFunction<any>;
  workShifts?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FindGeneralInfoTypeKeySpecifier = (
  | 'activitiesCount'
  | 'contract'
  | 'contractualPeriod'
  | 'finalDate'
  | 'imagesCount'
  | 'initialDate'
  | 'observationsCount'
  | 'occurrencesCount'
  | 'recentPhotos'
  | 'recentRecords'
  | 'recentVideos'
  | 'recordsCount'
  | 'remainingTerm'
  | 'situation'
  | 'title'
  | 'videosCount'
  | FindGeneralInfoTypeKeySpecifier
)[];
export type FindGeneralInfoTypeFieldPolicy = {
  activitiesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  contractualPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  finalDate?: FieldPolicy<any> | FieldReadFunction<any>;
  imagesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  initialDate?: FieldPolicy<any> | FieldReadFunction<any>;
  observationsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  occurrencesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  recentPhotos?: FieldPolicy<any> | FieldReadFunction<any>;
  recentRecords?: FieldPolicy<any> | FieldReadFunction<any>;
  recentVideos?: FieldPolicy<any> | FieldReadFunction<any>;
  recordsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  remainingTerm?: FieldPolicy<any> | FieldReadFunction<any>;
  situation?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  videosCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GenericDisplacementSurfaceDataTypeKeySpecifier = (
  | 'date'
  | 'displacement'
  | GenericDisplacementSurfaceDataTypeKeySpecifier
)[];
export type GenericDisplacementSurfaceDataTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  displacement?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GenericSurfaceMarkerReadingTypeKeySpecifier = (
  | 'date'
  | 'value'
  | GenericSurfaceMarkerReadingTypeKeySpecifier
)[];
export type GenericSurfaceMarkerReadingTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GistmCommentsTypeKeySpecifier = (
  | 'comment'
  | 'id'
  | GistmCommentsTypeKeySpecifier
)[];
export type GistmCommentsTypeFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GistmDataTypeKeySpecifier = (
  | 'id'
  | 'name'
  | 'nested'
  | 'path'
  | GistmDataTypeKeySpecifier
)[];
export type GistmDataTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nested?: FieldPolicy<any> | FieldReadFunction<any>;
  path?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GistmFileTypeKeySpecifier = (
  | 'createdAt'
  | 'file'
  | 'fileName'
  | 'id'
  | GistmFileTypeKeySpecifier
)[];
export type GistmFileTypeFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  file?: FieldPolicy<any> | FieldReadFunction<any>;
  fileName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InfosInstrumentsTypeKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'id'
  | 'lat'
  | 'lon'
  | 'name'
  | 'operationalStatus'
  | InfosInstrumentsTypeKeySpecifier
)[];
export type InfosInstrumentsTypeFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lat?: FieldPolicy<any> | FieldReadFunction<any>;
  lon?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionConservationStateTypeKeySpecifier = (
  | 'id'
  | 'note'
  | 'title'
  | 'topic'
  | InspectionConservationStateTypeKeySpecifier
)[];
export type InspectionConservationStateTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  note?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionDataImageTypeKeySpecifier = (
  | 'date'
  | 'id'
  | 'image'
  | 'latitude'
  | 'longitude'
  | 'status'
  | InspectionDataImageTypeKeySpecifier
)[];
export type InspectionDataImageTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  latitude?: FieldPolicy<any> | FieldReadFunction<any>;
  longitude?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionDataTypeKeySpecifier = (
  | 'accessSituation'
  | 'conservationState'
  | 'extravasator'
  | 'instrumentation'
  | 'massiveStructureShoulder'
  | 'reservoir'
  | 'waterWastePumpingPiping'
  | InspectionDataTypeKeySpecifier
)[];
export type InspectionDataTypeFieldPolicy = {
  accessSituation?: FieldPolicy<any> | FieldReadFunction<any>;
  conservationState?: FieldPolicy<any> | FieldReadFunction<any>;
  extravasator?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentation?: FieldPolicy<any> | FieldReadFunction<any>;
  massiveStructureShoulder?: FieldPolicy<any> | FieldReadFunction<any>;
  reservoir?: FieldPolicy<any> | FieldReadFunction<any>;
  waterWastePumpingPiping?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionGenericDataTypeKeySpecifier = (
  | 'answer'
  | 'id'
  | 'images'
  | 'observation'
  | 'title'
  | 'topic'
  | InspectionGenericDataTypeKeySpecifier
)[];
export type InspectionGenericDataTypeFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionParticipantsTypeKeySpecifier = (
  | 'company'
  | 'id'
  | 'name'
  | 'position'
  | 'signature'
  | 'status'
  | InspectionParticipantsTypeKeySpecifier
)[];
export type InspectionParticipantsTypeFieldPolicy = {
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  signature?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionsTypeKeySpecifier = (
  | 'conservationStateCalc'
  | 'date'
  | 'generalComments'
  | 'id'
  | 'participants'
  | 'responsible'
  | 'structureId'
  | 'systemId'
  | 'systemPdf'
  | 'userPdf'
  | InspectionsTypeKeySpecifier
)[];
export type InspectionsTypeFieldPolicy = {
  conservationStateCalc?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  generalComments?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  participants?: FieldPolicy<any> | FieldReadFunction<any>;
  responsible?: FieldPolicy<any> | FieldReadFunction<any>;
  structureId?: FieldPolicy<any> | FieldReadFunction<any>;
  systemId?: FieldPolicy<any> | FieldReadFunction<any>;
  systemPdf?: FieldPolicy<any> | FieldReadFunction<any>;
  userPdf?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InstrumentDocsTypeKeySpecifier = (
  | 'fileName'
  | 'id'
  | 'url'
  | InstrumentDocsTypeKeySpecifier
)[];
export type InstrumentDocsTypeFieldPolicy = {
  fileName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InstrumentInfoByUserStructureTypeKeySpecifier = (
  | 'ina'
  | 'piezometer'
  | 'pluviometer'
  | 'structureId'
  | 'structureName'
  | 'surfaceMarker'
  | 'waterLevel'
  | InstrumentInfoByUserStructureTypeKeySpecifier
)[];
export type InstrumentInfoByUserStructureTypeFieldPolicy = {
  ina?: FieldPolicy<any> | FieldReadFunction<any>;
  piezometer?: FieldPolicy<any> | FieldReadFunction<any>;
  pluviometer?: FieldPolicy<any> | FieldReadFunction<any>;
  structureId?: FieldPolicy<any> | FieldReadFunction<any>;
  structureName?: FieldPolicy<any> | FieldReadFunction<any>;
  surfaceMarker?: FieldPolicy<any> | FieldReadFunction<any>;
  waterLevel?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InstrumentInfosTypeKeySpecifier = (
  | 'inAlert'
  | 'instruments'
  | 'quantity'
  | InstrumentInfosTypeKeySpecifier
)[];
export type InstrumentInfosTypeFieldPolicy = {
  inAlert?: FieldPolicy<any> | FieldReadFunction<any>;
  instruments?: FieldPolicy<any> | FieldReadFunction<any>;
  quantity?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InstrumentTypeKeySpecifier = (
  | 'id'
  | 'instrumentType'
  | InstrumentTypeKeySpecifier
)[];
export type InstrumentTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LastInstrumentReadingTypeKeySpecifier = (
  | 'lastReading'
  | 'name'
  | LastInstrumentReadingTypeKeySpecifier
)[];
export type LastInstrumentReadingTypeFieldPolicy = {
  lastReading?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LastPiezometerInaReadingTypeKeySpecifier = (
  | 'lastReading'
  | 'name'
  | LastPiezometerInaReadingTypeKeySpecifier
)[];
export type LastPiezometerInaReadingTypeFieldPolicy = {
  lastReading?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LastReadingTypeKeySpecifier = (
  | 'date'
  | 'observation'
  | 'reading'
  | 'readingId'
  | LastReadingTypeKeySpecifier
)[];
export type LastReadingTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  reading?: FieldPolicy<any> | FieldReadFunction<any>;
  readingId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LatestUserNotificationTypeKeySpecifier = (
  | 'alertLevel'
  | 'installLocation'
  | 'instrumentNotificationId'
  | 'readingDate'
  | 'sentAt'
  | 'structureName'
  | 'unread'
  | LatestUserNotificationTypeKeySpecifier
)[];
export type LatestUserNotificationTypeFieldPolicy = {
  alertLevel?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentNotificationId?: FieldPolicy<any> | FieldReadFunction<any>;
  readingDate?: FieldPolicy<any> | FieldReadFunction<any>;
  sentAt?: FieldPolicy<any> | FieldReadFunction<any>;
  structureName?: FieldPolicy<any> | FieldReadFunction<any>;
  unread?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListActionPlanPagTypeKeySpecifier = (
  | 'code'
  | 'createdAt'
  | 'id'
  | 'priority'
  | 'responsibleName'
  | 'situation'
  | 'timeFrame'
  | 'type'
  | ListActionPlanPagTypeKeySpecifier
)[];
export type ListActionPlanPagTypeFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  responsibleName?: FieldPolicy<any> | FieldReadFunction<any>;
  situation?: FieldPolicy<any> | FieldReadFunction<any>;
  timeFrame?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListAllActionPlansToExportTableTypeKeySpecifier = (
  | 'code'
  | 'createdAt'
  | 'id'
  | 'priority'
  | 'responsibleName'
  | 'situation'
  | 'teamMembersName'
  | 'timeFrame'
  | 'type'
  | ListAllActionPlansToExportTableTypeKeySpecifier
)[];
export type ListAllActionPlansToExportTableTypeFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  responsibleName?: FieldPolicy<any> | FieldReadFunction<any>;
  situation?: FieldPolicy<any> | FieldReadFunction<any>;
  teamMembersName?: FieldPolicy<any> | FieldReadFunction<any>;
  timeFrame?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListAllAssociatedActionPlansTypeKeySpecifier = (
  | 'code'
  | 'createdAt'
  | 'description'
  | 'id'
  | 'images'
  | ListAllAssociatedActionPlansTypeKeySpecifier
)[];
export type ListAllAssociatedActionPlansTypeFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListAllInstrumentsTypeKeySpecifier = (
  | 'sectionsList'
  | 'typesList'
  | ListAllInstrumentsTypeKeySpecifier
)[];
export type ListAllInstrumentsTypeFieldPolicy = {
  sectionsList?: FieldPolicy<any> | FieldReadFunction<any>;
  typesList?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListAllPiezometerReadingTypeKeySpecifier = (
  | 'activationDate'
  | 'bottomCote'
  | 'coordinateE'
  | 'coordinateN'
  | 'cote'
  | 'date'
  | 'deactivationDate'
  | 'installLocation'
  | 'installationDate'
  | 'instrumentId'
  | 'isDry'
  | 'name'
  | 'operationalStatus'
  | 'reading'
  | 'readingId'
  | 'readingType'
  | 'topCote'
  | ListAllPiezometerReadingTypeKeySpecifier
)[];
export type ListAllPiezometerReadingTypeFieldPolicy = {
  activationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  bottomCote?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  cote?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  installationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentId?: FieldPolicy<any> | FieldReadFunction<any>;
  isDry?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  reading?: FieldPolicy<any> | FieldReadFunction<any>;
  readingId?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  topCote?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListAllPluviometerReadingTypeKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'date'
  | 'installLocation'
  | 'name'
  | 'observation'
  | 'operationalStatus'
  | 'rainfall'
  | 'readingType'
  | 'structureType'
  | ListAllPluviometerReadingTypeKeySpecifier
)[];
export type ListAllPluviometerReadingTypeFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  rainfall?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  structureType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListAllSurfaceMarkerReadingTypeKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'displacements'
  | 'installLocation'
  | 'name'
  | 'observation'
  | 'operationalStatus'
  | 'reading'
  | 'readingType'
  | 'readingsType'
  | 'structureType'
  | ListAllSurfaceMarkerReadingTypeKeySpecifier
)[];
export type ListAllSurfaceMarkerReadingTypeFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  displacements?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  reading?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  readingsType?: FieldPolicy<any> | FieldReadFunction<any>;
  structureType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListAllWaterLevelReadingTypeKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'date'
  | 'isUTM'
  | 'name'
  | 'observation'
  | 'operationalStatus'
  | 'readingType'
  | 'structureType'
  | 'value'
  | ListAllWaterLevelReadingTypeKeySpecifier
)[];
export type ListAllWaterLevelReadingTypeFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  structureType?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListControlQuotasTypeKeySpecifier = (
  | 'bottomCote'
  | 'id'
  | 'referenceDate'
  | 'topCote'
  | ListControlQuotasTypeKeySpecifier
)[];
export type ListControlQuotasTypeFieldPolicy = {
  bottomCote?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  referenceDate?: FieldPolicy<any> | FieldReadFunction<any>;
  topCote?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListGistmCommentsTypeKeySpecifier = (
  | 'comment'
  | 'createdAt'
  | 'id'
  | 'userName'
  | ListGistmCommentsTypeKeySpecifier
)[];
export type ListGistmCommentsTypeFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  userName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListInstrumentsBySectionsTypeKeySpecifier = (
  | 'finalCoordinateE'
  | 'finalCoordinateN'
  | 'finalLat'
  | 'finalLon'
  | 'id'
  | 'initialCoordinateE'
  | 'initialCoordinateN'
  | 'initialLat'
  | 'initialLon'
  | 'name'
  | 'type'
  | 'typesList'
  | ListInstrumentsBySectionsTypeKeySpecifier
)[];
export type ListInstrumentsBySectionsTypeFieldPolicy = {
  finalCoordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  finalCoordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  finalLat?: FieldPolicy<any> | FieldReadFunction<any>;
  finalLon?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  initialCoordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  initialCoordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  initialLat?: FieldPolicy<any> | FieldReadFunction<any>;
  initialLon?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  typesList?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListInstrumentsByTypeTypeKeySpecifier = (
  | 'instruments'
  | 'totalActive'
  | 'totalInactive'
  | 'type'
  | ListInstrumentsByTypeTypeKeySpecifier
)[];
export type ListInstrumentsByTypeTypeFieldPolicy = {
  instruments?: FieldPolicy<any> | FieldReadFunction<any>;
  totalActive?: FieldPolicy<any> | FieldReadFunction<any>;
  totalInactive?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListLatestPiezometerReadingTypeKeySpecifier = (
  | 'alertLevels'
  | 'cote'
  | 'date'
  | 'id'
  | 'name'
  | 'operationalStatus'
  | ListLatestPiezometerReadingTypeKeySpecifier
)[];
export type ListLatestPiezometerReadingTypeFieldPolicy = {
  alertLevels?: FieldPolicy<any> | FieldReadFunction<any>;
  cote?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListLatestPluviometerReadingTypeKeySpecifier = (
  | 'alertLevels'
  | 'date'
  | 'id'
  | 'name'
  | 'observation'
  | 'operationalStatus'
  | 'rainfall'
  | ListLatestPluviometerReadingTypeKeySpecifier
)[];
export type ListLatestPluviometerReadingTypeFieldPolicy = {
  alertLevels?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  rainfall?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListLatestSurfaceMarkerReadingTypeKeySpecifier = (
  | 'alertLevels'
  | 'coordinateE'
  | 'coordinateN'
  | 'date'
  | 'elevation'
  | 'instrumentId'
  | 'name'
  | 'observation'
  | 'operationalStatus'
  | 'type'
  | ListLatestSurfaceMarkerReadingTypeKeySpecifier
)[];
export type ListLatestSurfaceMarkerReadingTypeFieldPolicy = {
  alertLevels?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  elevation?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListLatestUserNotificationTypeKeySpecifier = (
  | 'latestNotifications'
  | 'unreadCount'
  | ListLatestUserNotificationTypeKeySpecifier
)[];
export type ListLatestUserNotificationTypeFieldPolicy = {
  latestNotifications?: FieldPolicy<any> | FieldReadFunction<any>;
  unreadCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListLatestWaterLevelReadingTypeKeySpecifier = (
  | 'alertLevels'
  | 'date'
  | 'id'
  | 'name'
  | 'observation'
  | 'operationalStatus'
  | 'type'
  | 'value'
  | ListLatestWaterLevelReadingTypeKeySpecifier
)[];
export type ListLatestWaterLevelReadingTypeFieldPolicy = {
  alertLevels?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListModuleNotificationsTypeKeySpecifier = (
  | 'count'
  | 'module'
  | 'moduleId'
  | ListModuleNotificationsTypeKeySpecifier
)[];
export type ListModuleNotificationsTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  module?: FieldPolicy<any> | FieldReadFunction<any>;
  moduleId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListPiezometerInaAlertLevelsTypeKeySpecifier = (
  | 'coteValue'
  | 'drained'
  | 'id'
  | 'name'
  | 'undrained'
  | ListPiezometerInaAlertLevelsTypeKeySpecifier
)[];
export type ListPiezometerInaAlertLevelsTypeFieldPolicy = {
  coteValue?: FieldPolicy<any> | FieldReadFunction<any>;
  drained?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  undrained?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListPiezometerInaByStructureKeySpecifier = (
  | 'activationDate'
  | 'bottomCote'
  | 'coordinateE'
  | 'coordinateN'
  | 'deactivationDate'
  | 'depth'
  | 'id'
  | 'installLocation'
  | 'installationDate'
  | 'isUTM'
  | 'name'
  | 'operationalStatus'
  | 'readingType'
  | 'status'
  | 'topCote'
  | 'type'
  | ListPiezometerInaByStructureKeySpecifier
)[];
export type ListPiezometerInaByStructureFieldPolicy = {
  activationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  bottomCote?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  depth?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  installationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  topCote?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListPiezometerInaReadingTypeKeySpecifier = (
  | 'activationDate'
  | 'bottomCote'
  | 'coordinateE'
  | 'coordinateN'
  | 'cote'
  | 'date'
  | 'deactivationDate'
  | 'installLocation'
  | 'installationDate'
  | 'instrumentId'
  | 'isDry'
  | 'isUTM'
  | 'name'
  | 'observation'
  | 'operationalStatus'
  | 'reading'
  | 'readingId'
  | 'readingType'
  | 'structureType'
  | 'topCote'
  | ListPiezometerInaReadingTypeKeySpecifier
)[];
export type ListPiezometerInaReadingTypeFieldPolicy = {
  activationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  bottomCote?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  cote?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  installationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentId?: FieldPolicy<any> | FieldReadFunction<any>;
  isDry?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  reading?: FieldPolicy<any> | FieldReadFunction<any>;
  readingId?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  structureType?: FieldPolicy<any> | FieldReadFunction<any>;
  topCote?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListSectionsTypeKeySpecifier = (
  | 'finalCoordinateE'
  | 'finalCoordinateN'
  | 'id'
  | 'initialCoordinateE'
  | 'initialCoordinateN'
  | 'isUTM'
  | 'name'
  | 'status'
  | 'structureName'
  | 'type'
  | ListSectionsTypeKeySpecifier
)[];
export type ListSectionsTypeFieldPolicy = {
  finalCoordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  finalCoordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  initialCoordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  initialCoordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  structureName?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListStructureModulesTypeKeySpecifier = (
  | 'end'
  | 'id'
  | 'moduleName'
  | 'start'
  | 'status'
  | 'structureName'
  | ListStructureModulesTypeKeySpecifier
)[];
export type ListStructureModulesTypeFieldPolicy = {
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  moduleName?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  structureName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListStructureSummaryKeySpecifier = (
  | 'ANMClassification'
  | 'coordinateE'
  | 'coordinateN'
  | 'datumName'
  | 'decharacterizationDate'
  | 'id'
  | 'isUTM'
  | 'latitude'
  | 'longitude'
  | 'maxHeight'
  | 'operationEnd'
  | 'operationStart'
  | 'operationalStatus'
  | 'potentialDamage'
  | 'riskClassification'
  | 'utmZone'
  | ListStructureSummaryKeySpecifier
)[];
export type ListStructureSummaryFieldPolicy = {
  ANMClassification?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  datumName?: FieldPolicy<any> | FieldReadFunction<any>;
  decharacterizationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  latitude?: FieldPolicy<any> | FieldReadFunction<any>;
  longitude?: FieldPolicy<any> | FieldReadFunction<any>;
  maxHeight?: FieldPolicy<any> | FieldReadFunction<any>;
  operationEnd?: FieldPolicy<any> | FieldReadFunction<any>;
  operationStart?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialDamage?: FieldPolicy<any> | FieldReadFunction<any>;
  riskClassification?: FieldPolicy<any> | FieldReadFunction<any>;
  utmZone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListStructuresTypeKeySpecifier = (
  | 'companyName'
  | 'name'
  | 'status'
  | 'structureId'
  | ListStructuresTypeKeySpecifier
)[];
export type ListStructuresTypeFieldPolicy = {
  companyName?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  structureId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListSubscribedUserNotificationTypeKeySpecifier = (
  | 'criticality'
  | 'id'
  | 'instrumentType'
  | 'status'
  | 'user'
  | ListSubscribedUserNotificationTypeKeySpecifier
)[];
export type ListSubscribedUserNotificationTypeFieldPolicy = {
  criticality?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentType?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListSurfaceMarkerAlertLevelTypeKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'date'
  | 'elevation'
  | 'id'
  | 'name'
  | ListSurfaceMarkerAlertLevelTypeKeySpecifier
)[];
export type ListSurfaceMarkerAlertLevelTypeFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  elevation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListSurfaceMarkerTypeKeySpecifier = (
  | 'activationDate'
  | 'coordinateE'
  | 'coordinateN'
  | 'deactivationDate'
  | 'id'
  | 'installLocation'
  | 'installationDate'
  | 'isUTM'
  | 'name'
  | 'operationalStatus'
  | 'readingType'
  | 'status'
  | 'surfaceMarker'
  | 'type'
  | ListSurfaceMarkerTypeKeySpecifier
)[];
export type ListSurfaceMarkerTypeFieldPolicy = {
  activationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  installationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  surfaceMarker?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListSurfaceMarkersReferenceTypeKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'date'
  | 'elevation'
  | 'id'
  | ListSurfaceMarkersReferenceTypeKeySpecifier
)[];
export type ListSurfaceMarkersReferenceTypeFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  elevation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListUserPermissionTypeKeySpecifier = (
  | 'companyName'
  | 'moduleAssignmentId'
  | 'moduleName'
  | 'moduleRoleName'
  | 'structureName'
  | 'structureUserPermissionId'
  | 'userEmail'
  | 'userName'
  | ListUserPermissionTypeKeySpecifier
)[];
export type ListUserPermissionTypeFieldPolicy = {
  companyName?: FieldPolicy<any> | FieldReadFunction<any>;
  moduleAssignmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  moduleName?: FieldPolicy<any> | FieldReadFunction<any>;
  moduleRoleName?: FieldPolicy<any> | FieldReadFunction<any>;
  structureName?: FieldPolicy<any> | FieldReadFunction<any>;
  structureUserPermissionId?: FieldPolicy<any> | FieldReadFunction<any>;
  userEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  userName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LoginKeySpecifier = (
  | 'hasAcceptedTerms'
  | 'isLoggedWithTemporaryPassword'
  | 'token'
  | 'user'
  | LoginKeySpecifier
)[];
export type LoginFieldPolicy = {
  hasAcceptedTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  isLoggedWithTemporaryPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  token?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LongitudinalSectionKeySpecifier = (
  | 'id'
  | 'name'
  | LongitudinalSectionKeySpecifier
)[];
export type LongitudinalSectionFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MassiveStructureShoulderDataTypeKeySpecifier = (
  | 'coating'
  | 'identifiedCracks'
  | 'identifiedDeformations'
  | 'identifiedWaterLeaks'
  | 'internalDrainage'
  | 'presenceOfTreesAnimalsInsects'
  | 'surfaceDrainage'
  | 'surfaceErosions'
  | MassiveStructureShoulderDataTypeKeySpecifier
)[];
export type MassiveStructureShoulderDataTypeFieldPolicy = {
  coating?: FieldPolicy<any> | FieldReadFunction<any>;
  identifiedCracks?: FieldPolicy<any> | FieldReadFunction<any>;
  identifiedDeformations?: FieldPolicy<any> | FieldReadFunction<any>;
  identifiedWaterLeaks?: FieldPolicy<any> | FieldReadFunction<any>;
  internalDrainage?: FieldPolicy<any> | FieldReadFunction<any>;
  presenceOfTreesAnimalsInsects?: FieldPolicy<any> | FieldReadFunction<any>;
  surfaceDrainage?: FieldPolicy<any> | FieldReadFunction<any>;
  surfaceErosions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MobInitialInspectionDataModelTypeKeySpecifier = (
  | 'id'
  | 'modules'
  | 'name'
  | MobInitialInspectionDataModelTypeKeySpecifier
)[];
export type MobInitialInspectionDataModelTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  modules?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MobInspectionDataModelTypeKeySpecifier = (
  | 'data'
  | 'date'
  | 'generalComments'
  | 'id'
  | 'participants'
  | 'responsible'
  | MobInspectionDataModelTypeKeySpecifier
)[];
export type MobInspectionDataModelTypeFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  generalComments?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  participants?: FieldPolicy<any> | FieldReadFunction<any>;
  responsible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MobModuleInfoTypeKeySpecifier = (
  | 'id'
  | 'lastData'
  | 'name'
  | MobModuleInfoTypeKeySpecifier
)[];
export type MobModuleInfoTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastData?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ModuleFlagTypeKeySpecifier = (
  | 'id'
  | 'name'
  | 'role'
  | ModuleFlagTypeKeySpecifier
)[];
export type ModuleFlagTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ModuleRoleTypeKeySpecifier = (
  | 'id'
  | 'role'
  | ModuleRoleTypeKeySpecifier
)[];
export type ModuleRoleTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ModuleTypeKeySpecifier = (
  | 'id'
  | 'index'
  | 'name'
  | ModuleTypeKeySpecifier
)[];
export type ModuleTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  index?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ModulesTypesKeySpecifier = (
  | 'active'
  | 'end'
  | 'module'
  | 'moduleId'
  | 'start'
  | ModulesTypesKeySpecifier
)[];
export type ModulesTypesFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  module?: FieldPolicy<any> | FieldReadFunction<any>;
  moduleId?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MssGenericBaseDataTypeKeySpecifier = (
  | 'answer'
  | 'id'
  | 'observation'
  | 'title'
  | MssGenericBaseDataTypeKeySpecifier
)[];
export type MssGenericBaseDataTypeFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MssGenericDataTypeKeySpecifier = (
  | 'answer'
  | 'id'
  | 'observation'
  | 'title'
  | 'topic'
  | MssGenericDataTypeKeySpecifier
)[];
export type MssGenericDataTypeFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MssGenericDataWithTopicTypeKeySpecifier = (
  | 'answer'
  | 'id'
  | 'images'
  | 'observation'
  | 'title'
  | 'topic'
  | MssGenericDataWithTopicTypeKeySpecifier
)[];
export type MssGenericDataWithTopicTypeFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MssGenericDataWithoutTopicTypeKeySpecifier = (
  | 'answer'
  | 'id'
  | 'images'
  | 'observation'
  | 'title'
  | MssGenericDataWithoutTopicTypeKeySpecifier
)[];
export type MssGenericDataWithoutTopicTypeFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MssIdentifiedCracksTypeKeySpecifier = (
  | 'id'
  | 'length'
  | 'longitudinalTransverse'
  | 'name'
  | 'observation'
  | 'opening'
  | MssIdentifiedCracksTypeKeySpecifier
)[];
export type MssIdentifiedCracksTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  length?: FieldPolicy<any> | FieldReadFunction<any>;
  longitudinalTransverse?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  opening?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MssIdentifiedCracksWithImageTypeKeySpecifier = (
  | 'id'
  | 'images'
  | 'length'
  | 'longitudinalTransverse'
  | 'name'
  | 'observation'
  | 'opening'
  | MssIdentifiedCracksWithImageTypeKeySpecifier
)[];
export type MssIdentifiedCracksWithImageTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  length?: FieldPolicy<any> | FieldReadFunction<any>;
  longitudinalTransverse?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  opening?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MssIdentifiedDeformationsTypeKeySpecifier = (
  | 'id'
  | 'identifiedAnomaly'
  | 'local'
  | 'observation'
  | MssIdentifiedDeformationsTypeKeySpecifier
)[];
export type MssIdentifiedDeformationsTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  identifiedAnomaly?: FieldPolicy<any> | FieldReadFunction<any>;
  local?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MssIdentifiedDeformationsWithImageTypeKeySpecifier = (
  | 'id'
  | 'identifiedAnomaly'
  | 'images'
  | 'local'
  | 'observation'
  | MssIdentifiedDeformationsWithImageTypeKeySpecifier
)[];
export type MssIdentifiedDeformationsWithImageTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  identifiedAnomaly?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  local?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MssIdentifiedWaterLeaksTypeKeySpecifier = (
  | 'id'
  | 'local'
  | 'name'
  | 'observation'
  | 'visibleSolidTransport'
  | MssIdentifiedWaterLeaksTypeKeySpecifier
)[];
export type MssIdentifiedWaterLeaksTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  local?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  visibleSolidTransport?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MssIdentifiedWaterLeaksWithImageTypeKeySpecifier = (
  | 'id'
  | 'images'
  | 'local'
  | 'name'
  | 'observation'
  | 'visibleSolidTransport'
  | MssIdentifiedWaterLeaksWithImageTypeKeySpecifier
)[];
export type MssIdentifiedWaterLeaksWithImageTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  local?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  visibleSolidTransport?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'acceptTerms'
  | 'actionPlanApprovalFlow'
  | 'actionPlanReprovalFlow'
  | 'addIdentifiedCrack'
  | 'addIdentifiedDeformation'
  | 'addIdentifiedWaterLeak'
  | 'addParticipants'
  | 'addPiezometerInaAlertLevels'
  | 'addPluviometerAlertLevels'
  | 'addStructureDamTailingVolume'
  | 'addSurfaceMarkerAlertLevels'
  | 'addWaterLevelAlertLevels'
  | 'associateActionPlans'
  | 'createActionPlan'
  | 'createAto'
  | 'createCompany'
  | 'createInspection'
  | 'createPiezometer'
  | 'createPiezometerReading'
  | 'createPluviometer'
  | 'createPluviometerReading'
  | 'createSection'
  | 'createStructure'
  | 'createStructureDamRaising'
  | 'createStructureGeometry'
  | 'createStructureHydrology'
  | 'createStructureModules'
  | 'createStructureReservoirHandling'
  | 'createStructureRiskAssessment'
  | 'createStructureSummary'
  | 'createSurfaceMarker'
  | 'createSurfaceMarkersReading'
  | 'createUser'
  | 'createUserInstrumentNotification'
  | 'createUserPermission'
  | 'createWaterLevel'
  | 'createWaterLevelReading'
  | 'deleteActionPlanComment'
  | 'deleteActionPlanTeamMember'
  | 'deleteAtoDocs'
  | 'deleteCompany'
  | 'deleteCompanyLogo'
  | 'deleteGistmComment'
  | 'deleteGistmFile'
  | 'deleteIdentifiedCrack'
  | 'deleteIdentifiedDeformation'
  | 'deleteIdentifiedWaterLeak'
  | 'deleteInspection'
  | 'deleteInspectionDataImage'
  | 'deleteInstrument'
  | 'deleteInstrumentAlertLevel'
  | 'deleteInstrumentDocById'
  | 'deleteInstrumentReading'
  | 'deleteParticipant'
  | 'deletePsbFile'
  | 'deleteRecordActivities'
  | 'deleteRecordActivitiesImage'
  | 'deleteRecordActivitiesVideo'
  | 'deleteRecordEquipmentAndLabor'
  | 'deleteRecordImage'
  | 'deleteRecordObservation'
  | 'deleteRecordRedAlertPeriod'
  | 'deleteRecordVideo'
  | 'deleteRecordWeatherConditions'
  | 'deleteRecordWorkShift'
  | 'deleteSection'
  | 'deleteStructure'
  | 'deleteStructureDamRaising'
  | 'deleteStructureModule'
  | 'deleteSubscribedUserNotification'
  | 'deleteUser'
  | 'deleteUserPermission'
  | 'editCompany'
  | 'editPassword'
  | 'editPiezometer'
  | 'editPiezometerAlert'
  | 'editPiezometerControlCote'
  | 'editPiezometerReading'
  | 'editPluviometer'
  | 'editPluviometerAndWaterLevelAlert'
  | 'editPluviometerReading'
  | 'editSection'
  | 'editStructure'
  | 'editStructureAndModules'
  | 'editStructureDamRaising'
  | 'editStructureDamTailingVolume'
  | 'editStructureGeometry'
  | 'editStructureHydrology'
  | 'editStructureReservoirHandling'
  | 'editStructureRiskAssessment'
  | 'editStructureSummary'
  | 'editSurfaceMarker'
  | 'editSurfaceMarkerAlertLevel'
  | 'editSurfaceMarkerReference'
  | 'editSurfaceMarkersReading'
  | 'editUser'
  | 'editUserPermission'
  | 'editWaterLevel'
  | 'editWaterLevelReading'
  | 'forgotPassword'
  | 'generateInspectionPdf'
  | 'login'
  | 'mobSaveAllInpectionData'
  | 'reactivateAto'
  | 'recordApprovalFlow'
  | 'recordReprovalFlow'
  | 'saveActionPlanComment'
  | 'saveActionPlanTeamMembers'
  | 'saveAtoDocs'
  | 'saveConservationState'
  | 'saveDataToSectionChart'
  | 'saveGeneralComments'
  | 'saveGenericData'
  | 'saveGistmComments'
  | 'saveInstrumentDocs'
  | 'saveMassiveStructureShoulder'
  | 'savePiezometerControlQuota'
  | 'saveRecordActivities'
  | 'saveRecordEquipmentAndLabor'
  | 'saveRecordObservation'
  | 'saveRecordRedAlertPeriod'
  | 'saveRecordWeatherConditions'
  | 'saveRecordWorkShift'
  | 'setActionPlanCanceled'
  | 'setActionPlanProgress'
  | 'setAtoCanceled'
  | 'setAtoDone'
  | 'setInspectionSituationDone'
  | 'setNotificationUnreadStatus'
  | 'signAtoRecord'
  | 'updateActionPlan'
  | 'updateAto'
  | 'updateConservationState'
  | 'updateDate'
  | 'updateGeneralComments'
  | 'updateGenericData'
  | 'updateIdentifiedCrack'
  | 'updateIdentifiedDeformation'
  | 'updateIdentifiedWaterLeaks'
  | 'updateMssGenericData'
  | 'updateRecordActivities'
  | 'updateRecordEquipmentAndLabor'
  | 'updateRecordImageObservation'
  | 'updateRecordObservation'
  | 'updateRecordRedAlertPeriod'
  | 'updateRecordVideoObservation'
  | 'updateRecordWeatherConditions'
  | 'updateRecordWorkShift'
  | 'updateSectionChartParameters'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  acceptTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  actionPlanApprovalFlow?: FieldPolicy<any> | FieldReadFunction<any>;
  actionPlanReprovalFlow?: FieldPolicy<any> | FieldReadFunction<any>;
  addIdentifiedCrack?: FieldPolicy<any> | FieldReadFunction<any>;
  addIdentifiedDeformation?: FieldPolicy<any> | FieldReadFunction<any>;
  addIdentifiedWaterLeak?: FieldPolicy<any> | FieldReadFunction<any>;
  addParticipants?: FieldPolicy<any> | FieldReadFunction<any>;
  addPiezometerInaAlertLevels?: FieldPolicy<any> | FieldReadFunction<any>;
  addPluviometerAlertLevels?: FieldPolicy<any> | FieldReadFunction<any>;
  addStructureDamTailingVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  addSurfaceMarkerAlertLevels?: FieldPolicy<any> | FieldReadFunction<any>;
  addWaterLevelAlertLevels?: FieldPolicy<any> | FieldReadFunction<any>;
  associateActionPlans?: FieldPolicy<any> | FieldReadFunction<any>;
  createActionPlan?: FieldPolicy<any> | FieldReadFunction<any>;
  createAto?: FieldPolicy<any> | FieldReadFunction<any>;
  createCompany?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  createPiezometer?: FieldPolicy<any> | FieldReadFunction<any>;
  createPiezometerReading?: FieldPolicy<any> | FieldReadFunction<any>;
  createPluviometer?: FieldPolicy<any> | FieldReadFunction<any>;
  createPluviometerReading?: FieldPolicy<any> | FieldReadFunction<any>;
  createSection?: FieldPolicy<any> | FieldReadFunction<any>;
  createStructure?: FieldPolicy<any> | FieldReadFunction<any>;
  createStructureDamRaising?: FieldPolicy<any> | FieldReadFunction<any>;
  createStructureGeometry?: FieldPolicy<any> | FieldReadFunction<any>;
  createStructureHydrology?: FieldPolicy<any> | FieldReadFunction<any>;
  createStructureModules?: FieldPolicy<any> | FieldReadFunction<any>;
  createStructureReservoirHandling?: FieldPolicy<any> | FieldReadFunction<any>;
  createStructureRiskAssessment?: FieldPolicy<any> | FieldReadFunction<any>;
  createStructureSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  createSurfaceMarker?: FieldPolicy<any> | FieldReadFunction<any>;
  createSurfaceMarkersReading?: FieldPolicy<any> | FieldReadFunction<any>;
  createUser?: FieldPolicy<any> | FieldReadFunction<any>;
  createUserInstrumentNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  createUserPermission?: FieldPolicy<any> | FieldReadFunction<any>;
  createWaterLevel?: FieldPolicy<any> | FieldReadFunction<any>;
  createWaterLevelReading?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteActionPlanComment?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteActionPlanTeamMember?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteAtoDocs?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteCompany?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteCompanyLogo?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteGistmComment?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteGistmFile?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteIdentifiedCrack?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteIdentifiedDeformation?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteIdentifiedWaterLeak?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteInspectionDataImage?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteInstrument?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteInstrumentAlertLevel?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteInstrumentDocById?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteInstrumentReading?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteParticipant?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePsbFile?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecordActivities?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecordActivitiesImage?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecordActivitiesVideo?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecordEquipmentAndLabor?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecordImage?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecordObservation?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecordRedAlertPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecordVideo?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecordWeatherConditions?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecordWorkShift?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteSection?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStructure?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStructureDamRaising?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStructureModule?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteSubscribedUserNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUser?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUserPermission?: FieldPolicy<any> | FieldReadFunction<any>;
  editCompany?: FieldPolicy<any> | FieldReadFunction<any>;
  editPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  editPiezometer?: FieldPolicy<any> | FieldReadFunction<any>;
  editPiezometerAlert?: FieldPolicy<any> | FieldReadFunction<any>;
  editPiezometerControlCote?: FieldPolicy<any> | FieldReadFunction<any>;
  editPiezometerReading?: FieldPolicy<any> | FieldReadFunction<any>;
  editPluviometer?: FieldPolicy<any> | FieldReadFunction<any>;
  editPluviometerAndWaterLevelAlert?: FieldPolicy<any> | FieldReadFunction<any>;
  editPluviometerReading?: FieldPolicy<any> | FieldReadFunction<any>;
  editSection?: FieldPolicy<any> | FieldReadFunction<any>;
  editStructure?: FieldPolicy<any> | FieldReadFunction<any>;
  editStructureAndModules?: FieldPolicy<any> | FieldReadFunction<any>;
  editStructureDamRaising?: FieldPolicy<any> | FieldReadFunction<any>;
  editStructureDamTailingVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  editStructureGeometry?: FieldPolicy<any> | FieldReadFunction<any>;
  editStructureHydrology?: FieldPolicy<any> | FieldReadFunction<any>;
  editStructureReservoirHandling?: FieldPolicy<any> | FieldReadFunction<any>;
  editStructureRiskAssessment?: FieldPolicy<any> | FieldReadFunction<any>;
  editStructureSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  editSurfaceMarker?: FieldPolicy<any> | FieldReadFunction<any>;
  editSurfaceMarkerAlertLevel?: FieldPolicy<any> | FieldReadFunction<any>;
  editSurfaceMarkerReference?: FieldPolicy<any> | FieldReadFunction<any>;
  editSurfaceMarkersReading?: FieldPolicy<any> | FieldReadFunction<any>;
  editUser?: FieldPolicy<any> | FieldReadFunction<any>;
  editUserPermission?: FieldPolicy<any> | FieldReadFunction<any>;
  editWaterLevel?: FieldPolicy<any> | FieldReadFunction<any>;
  editWaterLevelReading?: FieldPolicy<any> | FieldReadFunction<any>;
  forgotPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  generateInspectionPdf?: FieldPolicy<any> | FieldReadFunction<any>;
  login?: FieldPolicy<any> | FieldReadFunction<any>;
  mobSaveAllInpectionData?: FieldPolicy<any> | FieldReadFunction<any>;
  reactivateAto?: FieldPolicy<any> | FieldReadFunction<any>;
  recordApprovalFlow?: FieldPolicy<any> | FieldReadFunction<any>;
  recordReprovalFlow?: FieldPolicy<any> | FieldReadFunction<any>;
  saveActionPlanComment?: FieldPolicy<any> | FieldReadFunction<any>;
  saveActionPlanTeamMembers?: FieldPolicy<any> | FieldReadFunction<any>;
  saveAtoDocs?: FieldPolicy<any> | FieldReadFunction<any>;
  saveConservationState?: FieldPolicy<any> | FieldReadFunction<any>;
  saveDataToSectionChart?: FieldPolicy<any> | FieldReadFunction<any>;
  saveGeneralComments?: FieldPolicy<any> | FieldReadFunction<any>;
  saveGenericData?: FieldPolicy<any> | FieldReadFunction<any>;
  saveGistmComments?: FieldPolicy<any> | FieldReadFunction<any>;
  saveInstrumentDocs?: FieldPolicy<any> | FieldReadFunction<any>;
  saveMassiveStructureShoulder?: FieldPolicy<any> | FieldReadFunction<any>;
  savePiezometerControlQuota?: FieldPolicy<any> | FieldReadFunction<any>;
  saveRecordActivities?: FieldPolicy<any> | FieldReadFunction<any>;
  saveRecordEquipmentAndLabor?: FieldPolicy<any> | FieldReadFunction<any>;
  saveRecordObservation?: FieldPolicy<any> | FieldReadFunction<any>;
  saveRecordRedAlertPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  saveRecordWeatherConditions?: FieldPolicy<any> | FieldReadFunction<any>;
  saveRecordWorkShift?: FieldPolicy<any> | FieldReadFunction<any>;
  setActionPlanCanceled?: FieldPolicy<any> | FieldReadFunction<any>;
  setActionPlanProgress?: FieldPolicy<any> | FieldReadFunction<any>;
  setAtoCanceled?: FieldPolicy<any> | FieldReadFunction<any>;
  setAtoDone?: FieldPolicy<any> | FieldReadFunction<any>;
  setInspectionSituationDone?: FieldPolicy<any> | FieldReadFunction<any>;
  setNotificationUnreadStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  signAtoRecord?: FieldPolicy<any> | FieldReadFunction<any>;
  updateActionPlan?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAto?: FieldPolicy<any> | FieldReadFunction<any>;
  updateConservationState?: FieldPolicy<any> | FieldReadFunction<any>;
  updateDate?: FieldPolicy<any> | FieldReadFunction<any>;
  updateGeneralComments?: FieldPolicy<any> | FieldReadFunction<any>;
  updateGenericData?: FieldPolicy<any> | FieldReadFunction<any>;
  updateIdentifiedCrack?: FieldPolicy<any> | FieldReadFunction<any>;
  updateIdentifiedDeformation?: FieldPolicy<any> | FieldReadFunction<any>;
  updateIdentifiedWaterLeaks?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMssGenericData?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecordActivities?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecordEquipmentAndLabor?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecordImageObservation?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecordObservation?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecordRedAlertPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecordVideoObservation?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecordWeatherConditions?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecordWorkShift?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSectionChartParameters?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NotificationsByModuleTypeKeySpecifier = (
  | 'body'
  | 'contentId'
  | 'createdAt'
  | 'unread'
  | NotificationsByModuleTypeKeySpecifier
)[];
export type NotificationsByModuleTypeFieldPolicy = {
  body?: FieldPolicy<any> | FieldReadFunction<any>;
  contentId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  unread?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PagWaterLevelReadingTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PagWaterLevelReadingTypeKeySpecifier
)[];
export type PagWaterLevelReadingTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PageInfoKeySpecifier = (
  | 'hasNextPage'
  | 'hasPreviousPage'
  | 'limit'
  | 'offset'
  | 'page'
  | 'totalPages'
  | PageInfoKeySpecifier
)[];
export type PageInfoFieldPolicy = {
  hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>;
  hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>;
  limit?: FieldPolicy<any> | FieldReadFunction<any>;
  offset?: FieldPolicy<any> | FieldReadFunction<any>;
  page?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPages?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedFileTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedFileTypeKeySpecifier
)[];
export type PaginatedFileTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedFindAllRecordsTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedFindAllRecordsTypeKeySpecifier
)[];
export type PaginatedFindAllRecordsTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedGistmFileTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedGistmFileTypeKeySpecifier
)[];
export type PaginatedGistmFileTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedGistmFilesTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedGistmFilesTypeKeySpecifier
)[];
export type PaginatedGistmFilesTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedListActionPlanPagTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedListActionPlanPagTypeKeySpecifier
)[];
export type PaginatedListActionPlanPagTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedListActionPlanTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedListActionPlanTypeKeySpecifier
)[];
export type PaginatedListActionPlanTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedListControlQuotasTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedListControlQuotasTypeKeySpecifier
)[];
export type PaginatedListControlQuotasTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedListNotificationsByModuleTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedListNotificationsByModuleTypeKeySpecifier
)[];
export type PaginatedListNotificationsByModuleTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedListPiezometerInaAlertLevelsTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedListPiezometerInaAlertLevelsTypeKeySpecifier
)[];
export type PaginatedListPiezometerInaAlertLevelsTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedListPiezometerInaReadingTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedListPiezometerInaReadingTypeKeySpecifier
)[];
export type PaginatedListPiezometerInaReadingTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedListSurfaceMarkerAlertLevelTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedListSurfaceMarkerAlertLevelTypeKeySpecifier
)[];
export type PaginatedListSurfaceMarkerAlertLevelTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedListSurfaceMarkersReferenceTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedListSurfaceMarkersReferenceTypeKeySpecifier
)[];
export type PaginatedListSurfaceMarkersReferenceTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedNotificationsByModuleTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedNotificationsByModuleTypeKeySpecifier
)[];
export type PaginatedNotificationsByModuleTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedPaginatedPluviometerReadingTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedPaginatedPluviometerReadingTypeKeySpecifier
)[];
export type PaginatedPaginatedPluviometerReadingTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedPaginatedSurfaceMarkersReadingTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedPaginatedSurfaceMarkersReadingTypeKeySpecifier
)[];
export type PaginatedPaginatedSurfaceMarkersReadingTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedPaginatedWaterLevelReadingTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedPaginatedWaterLevelReadingTypeKeySpecifier
)[];
export type PaginatedPaginatedWaterLevelReadingTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedPiezometerInaAlertLevelsKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedPiezometerInaAlertLevelsKeySpecifier
)[];
export type PaginatedPiezometerInaAlertLevelsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedPiezometerInaControlQuotasKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedPiezometerInaControlQuotasKeySpecifier
)[];
export type PaginatedPiezometerInaControlQuotasFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedPiezometerInaReadingKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedPiezometerInaReadingKeySpecifier
)[];
export type PaginatedPiezometerInaReadingFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedPluviometerAlertLevelsKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedPluviometerAlertLevelsKeySpecifier
)[];
export type PaginatedPluviometerAlertLevelsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedPluviometerReadingTypeKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'date'
  | 'installLocation'
  | 'instrumentId'
  | 'name'
  | 'observation'
  | 'operationalStatus'
  | 'rainfall'
  | 'readingId'
  | 'readingType'
  | 'structureType'
  | PaginatedPluviometerReadingTypeKeySpecifier
)[];
export type PaginatedPluviometerReadingTypeFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  rainfall?: FieldPolicy<any> | FieldReadFunction<any>;
  readingId?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  structureType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedPluviometerWaterLevelAlertLevelsTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedPluviometerWaterLevelAlertLevelsTypeKeySpecifier
)[];
export type PaginatedPluviometerWaterLevelAlertLevelsTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedPsbFileTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedPsbFileTypeKeySpecifier
)[];
export type PaginatedPsbFileTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedReadingTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedReadingTypeKeySpecifier
)[];
export type PaginatedReadingTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedRecordsTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedRecordsTypeKeySpecifier
)[];
export type PaginatedRecordsTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedSurfaceMarkerAlertLevelsKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedSurfaceMarkerAlertLevelsKeySpecifier
)[];
export type PaginatedSurfaceMarkerAlertLevelsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedSurfaceMarkerReferencesKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedSurfaceMarkerReferencesKeySpecifier
)[];
export type PaginatedSurfaceMarkerReferencesFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedSurfaceMarkersReadingTypeKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'displacements'
  | 'installLocation'
  | 'instrumentId'
  | 'name'
  | 'observation'
  | 'operationalStatus'
  | 'reading'
  | 'readingType'
  | 'readingsType'
  | 'structureType'
  | PaginatedSurfaceMarkersReadingTypeKeySpecifier
)[];
export type PaginatedSurfaceMarkersReadingTypeFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  displacements?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  reading?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  readingsType?: FieldPolicy<any> | FieldReadFunction<any>;
  structureType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedWaterLevelAlertLevelsKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedWaterLevelAlertLevelsKeySpecifier
)[];
export type PaginatedWaterLevelAlertLevelsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedWaterLevelAlertLevelsTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | PaginatedWaterLevelAlertLevelsTypeKeySpecifier
)[];
export type PaginatedWaterLevelAlertLevelsTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginatedWaterLevelReadingTypeKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'date'
  | 'instrumentId'
  | 'isUTM'
  | 'name'
  | 'observation'
  | 'operationalStatus'
  | 'readingId'
  | 'readingType'
  | 'structureType'
  | 'value'
  | PaginatedWaterLevelReadingTypeKeySpecifier
)[];
export type PaginatedWaterLevelReadingTypeFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  instrumentId?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  readingId?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  structureType?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PiezometerInaAlertLevelsTypeKeySpecifier = (
  | 'coteValue'
  | 'drained'
  | 'id'
  | 'name'
  | 'undrained'
  | PiezometerInaAlertLevelsTypeKeySpecifier
)[];
export type PiezometerInaAlertLevelsTypeFieldPolicy = {
  coteValue?: FieldPolicy<any> | FieldReadFunction<any>;
  drained?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  undrained?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PiezometerInaInfosTypeKeySpecifier = (
  | 'inAlert'
  | 'instruments'
  | 'quantity'
  | PiezometerInaInfosTypeKeySpecifier
)[];
export type PiezometerInaInfosTypeFieldPolicy = {
  inAlert?: FieldPolicy<any> | FieldReadFunction<any>;
  instruments?: FieldPolicy<any> | FieldReadFunction<any>;
  quantity?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PiezometerInaLastReadingTypeKeySpecifier = (
  | 'cote'
  | 'date'
  | 'isDry'
  | 'observation'
  | 'reading'
  | 'readingId'
  | PiezometerInaLastReadingTypeKeySpecifier
)[];
export type PiezometerInaLastReadingTypeFieldPolicy = {
  cote?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  isDry?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  reading?: FieldPolicy<any> | FieldReadFunction<any>;
  readingId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PiezometerInaReadingValuesTypeKeySpecifier = (
  | 'cote'
  | 'date'
  | 'id'
  | 'isDry'
  | PiezometerInaReadingValuesTypeKeySpecifier
)[];
export type PiezometerInaReadingValuesTypeFieldPolicy = {
  cote?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isDry?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PiezometerToChartTypeKeySpecifier = (
  | 'alertLevels'
  | 'chartItemType'
  | 'id'
  | 'name'
  | 'readings'
  | PiezometerToChartTypeKeySpecifier
)[];
export type PiezometerToChartTypeFieldPolicy = {
  alertLevels?: FieldPolicy<any> | FieldReadFunction<any>;
  chartItemType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  readings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PluviometerListTypeKeySpecifier = (
  | 'activationDate'
  | 'coordinateE'
  | 'coordinateN'
  | 'deactivationDate'
  | 'id'
  | 'installLocation'
  | 'installationDate'
  | 'isUTM'
  | 'name'
  | 'operationalStatus'
  | 'pluviometerType'
  | 'readingType'
  | 'status'
  | 'type'
  | PluviometerListTypeKeySpecifier
)[];
export type PluviometerListTypeFieldPolicy = {
  activationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  installationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  pluviometerType?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PluviometerWaterLevelAlertLevelsTypeKeySpecifier = (
  | 'date'
  | 'id'
  | 'name'
  | 'value'
  | PluviometerWaterLevelAlertLevelsTypeKeySpecifier
)[];
export type PluviometerWaterLevelAlertLevelsTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PsbDataTypeKeySpecifier = (
  | 'id'
  | 'name'
  | 'nested'
  | 'path'
  | PsbDataTypeKeySpecifier
)[];
export type PsbDataTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nested?: FieldPolicy<any> | FieldReadFunction<any>;
  path?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'ListAllPluviometerReadingModel'
  | 'findActionPlanData'
  | 'findActionPlanMembers'
  | 'findActionPlanPag'
  | 'findActionPlanResponsible'
  | 'findAllActionPlanImages'
  | 'findAllActionPlans'
  | 'findAllAssociatedActionPlans'
  | 'findAllAto'
  | 'findAllAtoImages'
  | 'findAllAtoVideos'
  | 'findAllGistmData'
  | 'findAllInspectionsByStructure'
  | 'findAllPsbData'
  | 'findAllRecordsPag'
  | 'findAtoApprovers'
  | 'findAtoVerifiers'
  | 'findCalculatedInfos'
  | 'findDataByInspection'
  | 'findDataByRecord'
  | 'findDataToChart'
  | 'findFilesPag'
  | 'findGeneralInfo'
  | 'findGistmComments'
  | 'findGistmFilesPag'
  | 'findInspectionConservationStateData'
  | 'findInspectionGenericData'
  | 'findInspectionMssData'
  | 'getModuleByStructure'
  | 'getStructureSummary'
  | 'getUserStructuresList'
  | 'listAlertsLevelByPiezometersPag'
  | 'listAlertsLevelByPluviometerPag'
  | 'listAlertsLevelBySurfaceMarkerPag'
  | 'listAlertsLevelByWaterLevelPag'
  | 'listAllPiezometerReading'
  | 'listAllSurfaceMarkerReading'
  | 'listAllWaterLevelReading'
  | 'listAssociatedStructures'
  | 'listCompanies'
  | 'listCompanyRelatedUsersAndStructures'
  | 'listControlQuotasByPiezometersPag'
  | 'listInstrumentById'
  | 'listInstrumentTypes'
  | 'listInstrumentsByStructure'
  | 'listInsturmentUserNotification'
  | 'listLatestReadingByPiezometer'
  | 'listLatestReadingByPluviometer'
  | 'listLatestSurfaceMarkerReading'
  | 'listLatestUserNotification'
  | 'listLatestWaterLevelReading'
  | 'listModules'
  | 'listModulesByStructureId'
  | 'listModulesRoles'
  | 'listNotificationsByModulePag'
  | 'listPiezometersByStructure'
  | 'listPluviometersByStructure'
  | 'listReadingByPiezometer'
  | 'listReadingByPiezometersPag'
  | 'listReadingByPluviometer'
  | 'listReadingByPluviometerPag'
  | 'listReadingBySurfaceMarkers'
  | 'listReadingBySurfaceMarkersPag'
  | 'listReadingByWaterLevel'
  | 'listReadingByWaterLevelPag'
  | 'listReferencesBySurfaceMarkerPag'
  | 'listSection'
  | 'listSectionsByStructure'
  | 'listStructureAndModules'
  | 'listStructures'
  | 'listSubscribedUserNotification'
  | 'listSurfaceMarkersByStructure'
  | 'listUnreadModuleNotifications'
  | 'listUsers'
  | 'listUsersPermissions'
  | 'listWaterLevelByStructure'
  | 'mobGetInstrumentInfosByUserStructures'
  | 'mobLoadStructuresInitialData'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  ListAllPluviometerReadingModel?: FieldPolicy<any> | FieldReadFunction<any>;
  findActionPlanData?: FieldPolicy<any> | FieldReadFunction<any>;
  findActionPlanMembers?: FieldPolicy<any> | FieldReadFunction<any>;
  findActionPlanPag?: FieldPolicy<any> | FieldReadFunction<any>;
  findActionPlanResponsible?: FieldPolicy<any> | FieldReadFunction<any>;
  findAllActionPlanImages?: FieldPolicy<any> | FieldReadFunction<any>;
  findAllActionPlans?: FieldPolicy<any> | FieldReadFunction<any>;
  findAllAssociatedActionPlans?: FieldPolicy<any> | FieldReadFunction<any>;
  findAllAto?: FieldPolicy<any> | FieldReadFunction<any>;
  findAllAtoImages?: FieldPolicy<any> | FieldReadFunction<any>;
  findAllAtoVideos?: FieldPolicy<any> | FieldReadFunction<any>;
  findAllGistmData?: FieldPolicy<any> | FieldReadFunction<any>;
  findAllInspectionsByStructure?: FieldPolicy<any> | FieldReadFunction<any>;
  findAllPsbData?: FieldPolicy<any> | FieldReadFunction<any>;
  findAllRecordsPag?: FieldPolicy<any> | FieldReadFunction<any>;
  findAtoApprovers?: FieldPolicy<any> | FieldReadFunction<any>;
  findAtoVerifiers?: FieldPolicy<any> | FieldReadFunction<any>;
  findCalculatedInfos?: FieldPolicy<any> | FieldReadFunction<any>;
  findDataByInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  findDataByRecord?: FieldPolicy<any> | FieldReadFunction<any>;
  findDataToChart?: FieldPolicy<any> | FieldReadFunction<any>;
  findFilesPag?: FieldPolicy<any> | FieldReadFunction<any>;
  findGeneralInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  findGistmComments?: FieldPolicy<any> | FieldReadFunction<any>;
  findGistmFilesPag?: FieldPolicy<any> | FieldReadFunction<any>;
  findInspectionConservationStateData?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  findInspectionGenericData?: FieldPolicy<any> | FieldReadFunction<any>;
  findInspectionMssData?: FieldPolicy<any> | FieldReadFunction<any>;
  getModuleByStructure?: FieldPolicy<any> | FieldReadFunction<any>;
  getStructureSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  getUserStructuresList?: FieldPolicy<any> | FieldReadFunction<any>;
  listAlertsLevelByPiezometersPag?: FieldPolicy<any> | FieldReadFunction<any>;
  listAlertsLevelByPluviometerPag?: FieldPolicy<any> | FieldReadFunction<any>;
  listAlertsLevelBySurfaceMarkerPag?: FieldPolicy<any> | FieldReadFunction<any>;
  listAlertsLevelByWaterLevelPag?: FieldPolicy<any> | FieldReadFunction<any>;
  listAllPiezometerReading?: FieldPolicy<any> | FieldReadFunction<any>;
  listAllSurfaceMarkerReading?: FieldPolicy<any> | FieldReadFunction<any>;
  listAllWaterLevelReading?: FieldPolicy<any> | FieldReadFunction<any>;
  listAssociatedStructures?: FieldPolicy<any> | FieldReadFunction<any>;
  listCompanies?: FieldPolicy<any> | FieldReadFunction<any>;
  listCompanyRelatedUsersAndStructures?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  listControlQuotasByPiezometersPag?: FieldPolicy<any> | FieldReadFunction<any>;
  listInstrumentById?: FieldPolicy<any> | FieldReadFunction<any>;
  listInstrumentTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  listInstrumentsByStructure?: FieldPolicy<any> | FieldReadFunction<any>;
  listInsturmentUserNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  listLatestReadingByPiezometer?: FieldPolicy<any> | FieldReadFunction<any>;
  listLatestReadingByPluviometer?: FieldPolicy<any> | FieldReadFunction<any>;
  listLatestSurfaceMarkerReading?: FieldPolicy<any> | FieldReadFunction<any>;
  listLatestUserNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  listLatestWaterLevelReading?: FieldPolicy<any> | FieldReadFunction<any>;
  listModules?: FieldPolicy<any> | FieldReadFunction<any>;
  listModulesByStructureId?: FieldPolicy<any> | FieldReadFunction<any>;
  listModulesRoles?: FieldPolicy<any> | FieldReadFunction<any>;
  listNotificationsByModulePag?: FieldPolicy<any> | FieldReadFunction<any>;
  listPiezometersByStructure?: FieldPolicy<any> | FieldReadFunction<any>;
  listPluviometersByStructure?: FieldPolicy<any> | FieldReadFunction<any>;
  listReadingByPiezometer?: FieldPolicy<any> | FieldReadFunction<any>;
  listReadingByPiezometersPag?: FieldPolicy<any> | FieldReadFunction<any>;
  listReadingByPluviometer?: FieldPolicy<any> | FieldReadFunction<any>;
  listReadingByPluviometerPag?: FieldPolicy<any> | FieldReadFunction<any>;
  listReadingBySurfaceMarkers?: FieldPolicy<any> | FieldReadFunction<any>;
  listReadingBySurfaceMarkersPag?: FieldPolicy<any> | FieldReadFunction<any>;
  listReadingByWaterLevel?: FieldPolicy<any> | FieldReadFunction<any>;
  listReadingByWaterLevelPag?: FieldPolicy<any> | FieldReadFunction<any>;
  listReferencesBySurfaceMarkerPag?: FieldPolicy<any> | FieldReadFunction<any>;
  listSection?: FieldPolicy<any> | FieldReadFunction<any>;
  listSectionsByStructure?: FieldPolicy<any> | FieldReadFunction<any>;
  listStructureAndModules?: FieldPolicy<any> | FieldReadFunction<any>;
  listStructures?: FieldPolicy<any> | FieldReadFunction<any>;
  listSubscribedUserNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  listSurfaceMarkersByStructure?: FieldPolicy<any> | FieldReadFunction<any>;
  listUnreadModuleNotifications?: FieldPolicy<any> | FieldReadFunction<any>;
  listUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  listUsersPermissions?: FieldPolicy<any> | FieldReadFunction<any>;
  listWaterLevelByStructure?: FieldPolicy<any> | FieldReadFunction<any>;
  mobGetInstrumentInfosByUserStructures?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  mobLoadStructuresInitialData?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReadingsToChartTypeKeySpecifier = (
  | 'bottomQuote'
  | 'quota'
  | 'topQuote'
  | 'x'
  | 'y'
  | ReadingsToChartTypeKeySpecifier
)[];
export type ReadingsToChartTypeFieldPolicy = {
  bottomQuote?: FieldPolicy<any> | FieldReadFunction<any>;
  quota?: FieldPolicy<any> | FieldReadFunction<any>;
  topQuote?: FieldPolicy<any> | FieldReadFunction<any>;
  x?: FieldPolicy<any> | FieldReadFunction<any>;
  y?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecentRecordsTypeKeySpecifier = (
  | 'date'
  | 'id'
  | 'imagesCount'
  | 'recordNumber'
  | 'situation'
  | 'videosCount'
  | RecentRecordsTypeKeySpecifier
)[];
export type RecentRecordsTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imagesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  recordNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  situation?: FieldPolicy<any> | FieldReadFunction<any>;
  videosCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResponsibleTypeKeySpecifier = (
  | 'id'
  | 'name'
  | ResponsibleTypeKeySpecifier
)[];
export type ResponsibleTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RiskAssessmentTypeKeySpecifier = (
  | 'downstreamEnvironmentalInterest'
  | 'downstreamHumanOccupation'
  | 'downstreamInstallations'
  | 'id'
  | 'wasteType'
  | RiskAssessmentTypeKeySpecifier
)[];
export type RiskAssessmentTypeFieldPolicy = {
  downstreamEnvironmentalInterest?: FieldPolicy<any> | FieldReadFunction<any>;
  downstreamHumanOccupation?: FieldPolicy<any> | FieldReadFunction<any>;
  downstreamInstallations?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  wasteType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RoleTypeKeySpecifier = ('role' | RoleTypeKeySpecifier)[];
export type RoleTypeFieldPolicy = {
  role?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SaveAtoRecordRedAlertPeriodTypeKeySpecifier = (
  | 'end'
  | 'recordId'
  | 'start'
  | SaveAtoRecordRedAlertPeriodTypeKeySpecifier
)[];
export type SaveAtoRecordRedAlertPeriodTypeFieldPolicy = {
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  recordId?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SavedActionPlanCommentTypeKeySpecifier = (
  | 'comment'
  | 'files'
  | 'id'
  | SavedActionPlanCommentTypeKeySpecifier
)[];
export type SavedActionPlanCommentTypeFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  files?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SavedActionPlanTeamMembersTypeKeySpecifier = (
  | 'email'
  | 'id'
  | 'name'
  | SavedActionPlanTeamMembersTypeKeySpecifier
)[];
export type SavedActionPlanTeamMembersTypeFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SectionTypeKeySpecifier = (
  | 'finalCoordinateE'
  | 'finalCoordinateN'
  | 'id'
  | 'initialCoordinateE'
  | 'initialCoordinateN'
  | 'isUTM'
  | 'name'
  | 'status'
  | 'type'
  | SectionTypeKeySpecifier
)[];
export type SectionTypeFieldPolicy = {
  finalCoordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  finalCoordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  initialCoordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  initialCoordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StructureBaseTypeKeySpecifier = (
  | 'id'
  | 'name'
  | StructureBaseTypeKeySpecifier
)[];
export type StructureBaseTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StructureComplexTypeKeySpecifier = ('address' | 'city' | 'country' | 'id' | 'name' | 'neighborhood' | 'phone' | 'state' | 'zipCode' | StructureComplexTypeKeySpecifier)[];
export type StructureComplexTypeFieldPolicy = {
	address?: FieldPolicy<any> | FieldReadFunction<any>,
	city?: FieldPolicy<any> | FieldReadFunction<any>,
	country?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	neighborhood?: FieldPolicy<any> | FieldReadFunction<any>,
	phone?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	zipCode?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StructureDamTailingVolumeTypeKeySpecifier = ('date' | 'id' | 'volume' | StructureDamTailingVolumeTypeKeySpecifier)[];
export type StructureDamTailingVolumeTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  volume?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StructureGeometryTypeKeySpecifier = (
  | 'bermLength'
  | 'crestElevation'
  | 'crestLength'
  | 'crestWidth'
  | 'globalSlopeDownstream'
  | 'id'
  | 'slopeBetweenBerms'
  | 'upstreamSlopeInclination'
  | StructureGeometryTypeKeySpecifier
)[];
export type StructureGeometryTypeFieldPolicy = {
  bermLength?: FieldPolicy<any> | FieldReadFunction<any>;
  crestElevation?: FieldPolicy<any> | FieldReadFunction<any>;
  crestLength?: FieldPolicy<any> | FieldReadFunction<any>;
  crestWidth?: FieldPolicy<any> | FieldReadFunction<any>;
  globalSlopeDownstream?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  slopeBetweenBerms?: FieldPolicy<any> | FieldReadFunction<any>;
  upstreamSlopeInclination?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StructureModulesTypeKeySpecifier = (
  | 'associatedStructures'
  | 'id'
  | 'modules'
  | 'name'
  | StructureModulesTypeKeySpecifier
)[];
export type StructureModulesTypeFieldPolicy = {
  associatedStructures?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  modules?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StructureReservoirHandlingTypeKeySpecifier = (
  | 'id'
  | 'reservoirOccupiedArea'
  | 'reservoirOccupiedVolume'
  | 'reservoirTotalVolume'
  | 'tailingVolumeHistory'
  | 'waterVolume'
  | StructureReservoirHandlingTypeKeySpecifier
)[];
export type StructureReservoirHandlingTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  reservoirOccupiedArea?: FieldPolicy<any> | FieldReadFunction<any>;
  reservoirOccupiedVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  reservoirTotalVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  tailingVolumeHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  waterVolume?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StructureResponsibleTypeKeySpecifier = (
  | 'id'
  | 'name'
  | StructureResponsibleTypeKeySpecifier
)[];
export type StructureResponsibleTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StructureSummaryTypeKeySpecifier = (
  | 'ANMClassification'
  | 'coordinateE'
  | 'coordinateN'
  | 'datumName'
  | 'decharacterizationDate'
  | 'id'
  | 'isUTM'
  | 'maxHeight'
  | 'operationEnd'
  | 'operationStart'
  | 'operationalStatus'
  | 'potentialDamage'
  | 'riskClassification'
  | 'utmZone'
  | StructureSummaryTypeKeySpecifier
)[];
export type StructureSummaryTypeFieldPolicy = {
  ANMClassification?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  datumName?: FieldPolicy<any> | FieldReadFunction<any>;
  decharacterizationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  maxHeight?: FieldPolicy<any> | FieldReadFunction<any>;
  operationEnd?: FieldPolicy<any> | FieldReadFunction<any>;
  operationStart?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialDamage?: FieldPolicy<any> | FieldReadFunction<any>;
  riskClassification?: FieldPolicy<any> | FieldReadFunction<any>;
  utmZone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StructureTypeKeySpecifier = ('associatedStructures' | 'company' | 'complex' | 'damRaisingHistory' | 'geometry' | 'hydrologyInfos' | 'id' | 'name' | 'reservoirHandling' | 'responsible' | 'riskAssessment' | 'sections' | 'status' | 'summary' | 'type' | StructureTypeKeySpecifier)[];
export type StructureTypeFieldPolicy = {
	associatedStructures?: FieldPolicy<any> | FieldReadFunction<any>,
	company?: FieldPolicy<any> | FieldReadFunction<any>,
	complex?: FieldPolicy<any> | FieldReadFunction<any>,
	damRaisingHistory?: FieldPolicy<any> | FieldReadFunction<any>,
	geometry?: FieldPolicy<any> | FieldReadFunction<any>,
	hydrologyInfos?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	reservoirHandling?: FieldPolicy<any> | FieldReadFunction<any>,
	responsible?: FieldPolicy<any> | FieldReadFunction<any>,
	riskAssessment?: FieldPolicy<any> | FieldReadFunction<any>,
	sections?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	summary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SummaryHydrologyTypeKeySpecifier = (
  | 'NAMaximoMaximorum'
  | 'criticalDuration'
  | 'id'
  | 'inflowRate'
  | 'outflowRate'
  | 'projectRainfall'
  | 'reservoirContributionArea'
  | 'returnPeriod'
  | 'spillwaySill'
  | 'spillwayType'
  | 'volumeTotalElevationCrest'
  | SummaryHydrologyTypeKeySpecifier
)[];
export type SummaryHydrologyTypeFieldPolicy = {
  NAMaximoMaximorum?: FieldPolicy<any> | FieldReadFunction<any>;
  criticalDuration?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inflowRate?: FieldPolicy<any> | FieldReadFunction<any>;
  outflowRate?: FieldPolicy<any> | FieldReadFunction<any>;
  projectRainfall?: FieldPolicy<any> | FieldReadFunction<any>;
  reservoirContributionArea?: FieldPolicy<any> | FieldReadFunction<any>;
  returnPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  spillwaySill?: FieldPolicy<any> | FieldReadFunction<any>;
  spillwayType?: FieldPolicy<any> | FieldReadFunction<any>;
  volumeTotalElevationCrest?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SurfaceMarkerAlertLevelKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'date'
  | 'elevation'
  | 'id'
  | 'name'
  | SurfaceMarkerAlertLevelKeySpecifier
)[];
export type SurfaceMarkerAlertLevelFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  elevation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SurfaceMarkerTypeKeySpecifier = (
  | 'id'
  | SurfaceMarkerTypeKeySpecifier
)[];
export type SurfaceMarkerTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SurfaceMarkersBaseDataKeySpecifier = (
  | 'coordinateE'
  | 'coordinateN'
  | 'date'
  | 'elevation'
  | 'id'
  | SurfaceMarkersBaseDataKeySpecifier
)[];
export type SurfaceMarkersBaseDataFieldPolicy = {
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  elevation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SurfaceMarkersReadingPaginatedTypeKeySpecifier = (
  | 'count'
  | 'nodes'
  | 'pageInfo'
  | SurfaceMarkersReadingPaginatedTypeKeySpecifier
)[];
export type SurfaceMarkersReadingPaginatedTypeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpdatedGenericDataTypeKeySpecifier = (
  | 'answer'
  | 'id'
  | 'observation'
  | 'title'
  | 'topic'
  | UpdatedGenericDataTypeKeySpecifier
)[];
export type UpdatedGenericDataTypeFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserKeySpecifier = (
  | 'email'
  | 'id'
  | 'name'
  | 'role'
  | UserKeySpecifier
)[];
export type UserFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserNotificationTypeKeySpecifier = (
  | 'alertLevel'
  | 'alertLevelValue'
  | 'id'
  | 'installLocation'
  | 'name'
  | 'reading'
  | 'readingDate'
  | 'sentAt'
  | 'structureName'
  | 'unread'
  | UserNotificationTypeKeySpecifier
)[];
export type UserNotificationTypeFieldPolicy = {
  alertLevel?: FieldPolicy<any> | FieldReadFunction<any>;
  alertLevelValue?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  reading?: FieldPolicy<any> | FieldReadFunction<any>;
  readingDate?: FieldPolicy<any> | FieldReadFunction<any>;
  sentAt?: FieldPolicy<any> | FieldReadFunction<any>;
  structureName?: FieldPolicy<any> | FieldReadFunction<any>;
  unread?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ValidateReadingValuesInfosModelTypeKeySpecifier = (
  | 'error'
  | 'instrument'
  | 'line'
  | ValidateReadingValuesInfosModelTypeKeySpecifier
)[];
export type ValidateReadingValuesInfosModelTypeFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  instrument?: FieldPolicy<any> | FieldReadFunction<any>;
  line?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VerifierApproverTypeKeySpecifier = (
  | 'email'
  | 'id'
  | 'name'
  | VerifierApproverTypeKeySpecifier
)[];
export type VerifierApproverTypeFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WaterLevelAlertLevelsTypeKeySpecifier = (
  | 'date'
  | 'id'
  | 'name'
  | 'value'
  | WaterLevelAlertLevelsTypeKeySpecifier
)[];
export type WaterLevelAlertLevelsTypeFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WaterLevelListTypeKeySpecifier = (
  | 'activationDate'
  | 'coordinateE'
  | 'coordinateN'
  | 'deactivationDate'
  | 'id'
  | 'installLocation'
  | 'installationDate'
  | 'isUTM'
  | 'name'
  | 'operationalStatus'
  | 'readingType'
  | 'status'
  | 'type'
  | WaterLevelListTypeKeySpecifier
)[];
export type WaterLevelListTypeFieldPolicy = {
  activationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateE?: FieldPolicy<any> | FieldReadFunction<any>;
  coordinateN?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  installLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  installationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  isUTM?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operationalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  readingType?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
	ActionPlanCommentFilesType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActionPlanCommentFilesTypeKeySpecifier | (() => undefined | ActionPlanCommentFilesTypeKeySpecifier),
		fields?: ActionPlanCommentFilesTypeFieldPolicy,
	},
	ActionPlanCommentsDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActionPlanCommentsDataTypeKeySpecifier | (() => undefined | ActionPlanCommentsDataTypeKeySpecifier),
		fields?: ActionPlanCommentsDataTypeFieldPolicy,
	},
	ActionPlanDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActionPlanDataTypeKeySpecifier | (() => undefined | ActionPlanDataTypeKeySpecifier),
		fields?: ActionPlanDataTypeFieldPolicy,
	},
	ActionPlanResponsibleDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActionPlanResponsibleDataTypeKeySpecifier | (() => undefined | ActionPlanResponsibleDataTypeKeySpecifier),
		fields?: ActionPlanResponsibleDataTypeFieldPolicy,
	},
	ActionPlanResponsibleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActionPlanResponsibleTypeKeySpecifier | (() => undefined | ActionPlanResponsibleTypeKeySpecifier),
		fields?: ActionPlanResponsibleTypeFieldPolicy,
	},
	ActionPlanTeamMembersDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActionPlanTeamMembersDataTypeKeySpecifier | (() => undefined | ActionPlanTeamMembersDataTypeKeySpecifier),
		fields?: ActionPlanTeamMembersDataTypeFieldPolicy,
	},
	AdminBaseCompanyType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AdminBaseCompanyTypeKeySpecifier | (() => undefined | AdminBaseCompanyTypeKeySpecifier),
		fields?: AdminBaseCompanyTypeFieldPolicy,
	},
	AdminUserRoleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AdminUserRoleTypeKeySpecifier | (() => undefined | AdminUserRoleTypeKeySpecifier),
		fields?: AdminUserRoleTypeFieldPolicy,
	},
	AdminUserType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AdminUserTypeKeySpecifier | (() => undefined | AdminUserTypeKeySpecifier),
		fields?: AdminUserTypeFieldPolicy,
	},
	AssociatedStructureType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AssociatedStructureTypeKeySpecifier | (() => undefined | AssociatedStructureTypeKeySpecifier),
		fields?: AssociatedStructureTypeFieldPolicy,
	},
	AssociatedType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AssociatedTypeKeySpecifier | (() => undefined | AssociatedTypeKeySpecifier),
		fields?: AssociatedTypeFieldPolicy,
	},
	AtoDocsIdModelType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoDocsIdModelTypeKeySpecifier | (() => undefined | AtoDocsIdModelTypeKeySpecifier),
		fields?: AtoDocsIdModelTypeFieldPolicy,
	},
	AtoImageType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoImageTypeKeySpecifier | (() => undefined | AtoImageTypeKeySpecifier),
		fields?: AtoImageTypeFieldPolicy,
	},
	AtoImagesType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoImagesTypeKeySpecifier | (() => undefined | AtoImagesTypeKeySpecifier),
		fields?: AtoImagesTypeFieldPolicy,
	},
	AtoRecordActivitiesDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoRecordActivitiesDataTypeKeySpecifier | (() => undefined | AtoRecordActivitiesDataTypeKeySpecifier),
		fields?: AtoRecordActivitiesDataTypeFieldPolicy,
	},
	AtoRecordActivitiesImagesType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoRecordActivitiesImagesTypeKeySpecifier | (() => undefined | AtoRecordActivitiesImagesTypeKeySpecifier),
		fields?: AtoRecordActivitiesImagesTypeFieldPolicy,
	},
	AtoRecordActivitiesVideosType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoRecordActivitiesVideosTypeKeySpecifier | (() => undefined | AtoRecordActivitiesVideosTypeKeySpecifier),
		fields?: AtoRecordActivitiesVideosTypeFieldPolicy,
	},
	AtoRecordEquipmentAndLaborDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoRecordEquipmentAndLaborDataTypeKeySpecifier | (() => undefined | AtoRecordEquipmentAndLaborDataTypeKeySpecifier),
		fields?: AtoRecordEquipmentAndLaborDataTypeFieldPolicy,
	},
	AtoRecordImagesType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoRecordImagesTypeKeySpecifier | (() => undefined | AtoRecordImagesTypeKeySpecifier),
		fields?: AtoRecordImagesTypeFieldPolicy,
	},
	AtoRecordObservationsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoRecordObservationsTypeKeySpecifier | (() => undefined | AtoRecordObservationsTypeKeySpecifier),
		fields?: AtoRecordObservationsTypeFieldPolicy,
	},
	AtoRecordRedAlertPeriodType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoRecordRedAlertPeriodTypeKeySpecifier | (() => undefined | AtoRecordRedAlertPeriodTypeKeySpecifier),
		fields?: AtoRecordRedAlertPeriodTypeFieldPolicy,
	},
	AtoRecordSignaturesDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoRecordSignaturesDataTypeKeySpecifier | (() => undefined | AtoRecordSignaturesDataTypeKeySpecifier),
		fields?: AtoRecordSignaturesDataTypeFieldPolicy,
	},
	AtoRecordVideoType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoRecordVideoTypeKeySpecifier | (() => undefined | AtoRecordVideoTypeKeySpecifier),
		fields?: AtoRecordVideoTypeFieldPolicy,
	},
	AtoRecordWeatherConditionsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoRecordWeatherConditionsTypeKeySpecifier | (() => undefined | AtoRecordWeatherConditionsTypeKeySpecifier),
		fields?: AtoRecordWeatherConditionsTypeFieldPolicy,
	},
	AtoRecordWorkShiftType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoRecordWorkShiftTypeKeySpecifier | (() => undefined | AtoRecordWorkShiftTypeKeySpecifier),
		fields?: AtoRecordWorkShiftTypeFieldPolicy,
	},
	AtoReferencesDocsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoReferencesDocsTypeKeySpecifier | (() => undefined | AtoReferencesDocsTypeKeySpecifier),
		fields?: AtoReferencesDocsTypeFieldPolicy,
	},
	AtoUserTypesType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoUserTypesTypeKeySpecifier | (() => undefined | AtoUserTypesTypeKeySpecifier),
		fields?: AtoUserTypesTypeFieldPolicy,
	},
	AtoVideoType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoVideoTypeKeySpecifier | (() => undefined | AtoVideoTypeKeySpecifier),
		fields?: AtoVideoTypeFieldPolicy,
	},
	AtoVideosType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AtoVideosTypeKeySpecifier | (() => undefined | AtoVideosTypeKeySpecifier),
		fields?: AtoVideosTypeFieldPolicy,
	},
	BaseIdNameType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BaseIdNameTypeKeySpecifier | (() => undefined | BaseIdNameTypeKeySpecifier),
		fields?: BaseIdNameTypeFieldPolicy,
	},
	BaseInstrumentListType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BaseInstrumentListTypeKeySpecifier | (() => undefined | BaseInstrumentListTypeKeySpecifier),
		fields?: BaseInstrumentListTypeFieldPolicy,
	},
	BaseInstrumentType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BaseInstrumentTypeKeySpecifier | (() => undefined | BaseInstrumentTypeKeySpecifier),
		fields?: BaseInstrumentTypeFieldPolicy,
	},
	BasePluviometerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BasePluviometerReadingTypeKeySpecifier | (() => undefined | BasePluviometerReadingTypeKeySpecifier),
		fields?: BasePluviometerReadingTypeFieldPolicy,
	},
	BaseSurfaceMarkersReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BaseSurfaceMarkersReadingTypeKeySpecifier | (() => undefined | BaseSurfaceMarkersReadingTypeKeySpecifier),
		fields?: BaseSurfaceMarkersReadingTypeFieldPolicy,
	},
	BaseWaterLevelReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BaseWaterLevelReadingTypeKeySpecifier | (() => undefined | BaseWaterLevelReadingTypeKeySpecifier),
		fields?: BaseWaterLevelReadingTypeFieldPolicy,
	},
	BasicInstrumentType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BasicInstrumentTypeKeySpecifier | (() => undefined | BasicInstrumentTypeKeySpecifier),
		fields?: BasicInstrumentTypeFieldPolicy,
	},
	BasicInstrumentTypeType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BasicInstrumentTypeTypeKeySpecifier | (() => undefined | BasicInstrumentTypeTypeKeySpecifier),
		fields?: BasicInstrumentTypeTypeFieldPolicy,
	},
	BasicInstrumentTypeWithSectionAndDocs?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BasicInstrumentTypeWithSectionAndDocsKeySpecifier | (() => undefined | BasicInstrumentTypeWithSectionAndDocsKeySpecifier),
		fields?: BasicInstrumentTypeWithSectionAndDocsFieldPolicy,
	},
	BasicSectionsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BasicSectionsTypeKeySpecifier | (() => undefined | BasicSectionsTypeKeySpecifier),
		fields?: BasicSectionsTypeFieldPolicy,
	},
	BasicStructureType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BasicStructureTypeKeySpecifier | (() => undefined | BasicStructureTypeKeySpecifier),
		fields?: BasicStructureTypeFieldPolicy,
	},
	BasicUserType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BasicUserTypeKeySpecifier | (() => undefined | BasicUserTypeKeySpecifier),
		fields?: BasicUserTypeFieldPolicy,
	},
	BasicUserWithIdType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BasicUserWithIdTypeKeySpecifier | (() => undefined | BasicUserWithIdTypeKeySpecifier),
		fields?: BasicUserWithIdTypeFieldPolicy,
	},
	BasicUserWithPhoneNumberType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BasicUserWithPhoneNumberTypeKeySpecifier | (() => undefined | BasicUserWithPhoneNumberTypeKeySpecifier),
		fields?: BasicUserWithPhoneNumberTypeFieldPolicy,
	},
	CalculatedInfosType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CalculatedInfosTypeKeySpecifier | (() => undefined | CalculatedInfosTypeKeySpecifier),
		fields?: CalculatedInfosTypeFieldPolicy,
	},
	ChartPiezometerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ChartPiezometerReadingTypeKeySpecifier | (() => undefined | ChartPiezometerReadingTypeKeySpecifier),
		fields?: ChartPiezometerReadingTypeFieldPolicy,
	},
	ChartPluviometerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ChartPluviometerReadingTypeKeySpecifier | (() => undefined | ChartPluviometerReadingTypeKeySpecifier),
		fields?: ChartPluviometerReadingTypeFieldPolicy,
	},
	ChartSurfaceMarkerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ChartSurfaceMarkerReadingTypeKeySpecifier | (() => undefined | ChartSurfaceMarkerReadingTypeKeySpecifier),
		fields?: ChartSurfaceMarkerReadingTypeFieldPolicy,
	},
	ChartWaterLevelReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ChartWaterLevelReadingTypeKeySpecifier | (() => undefined | ChartWaterLevelReadingTypeKeySpecifier),
		fields?: ChartWaterLevelReadingTypeFieldPolicy,
	},
	CompanyAddressType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyAddressTypeKeySpecifier | (() => undefined | CompanyAddressTypeKeySpecifier),
		fields?: CompanyAddressTypeFieldPolicy,
	},
	CompanyRelatedUsersAndStructuresType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyRelatedUsersAndStructuresTypeKeySpecifier | (() => undefined | CompanyRelatedUsersAndStructuresTypeKeySpecifier),
		fields?: CompanyRelatedUsersAndStructuresTypeFieldPolicy,
	},
	CompanyType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyTypeKeySpecifier | (() => undefined | CompanyTypeKeySpecifier),
		fields?: CompanyTypeFieldPolicy,
	},
	CompanyWithStructureType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyWithStructureTypeKeySpecifier | (() => undefined | CompanyWithStructureTypeKeySpecifier),
		fields?: CompanyWithStructureTypeFieldPolicy,
	},
	ControlCotesType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ControlCotesTypeKeySpecifier | (() => undefined | ControlCotesTypeKeySpecifier),
		fields?: ControlCotesTypeFieldPolicy,
	},
	CreatedInspectionGenericDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreatedInspectionGenericDataTypeKeySpecifier | (() => undefined | CreatedInspectionGenericDataTypeKeySpecifier),
		fields?: CreatedInspectionGenericDataTypeFieldPolicy,
	},
	CreatedMassiveStructureShoulderDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreatedMassiveStructureShoulderDataTypeKeySpecifier | (() => undefined | CreatedMassiveStructureShoulderDataTypeKeySpecifier),
		fields?: CreatedMassiveStructureShoulderDataTypeFieldPolicy,
	},
	CrossSection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CrossSectionKeySpecifier | (() => undefined | CrossSectionKeySpecifier),
		fields?: CrossSectionFieldPolicy,
	},
	DamRaisingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DamRaisingTypeKeySpecifier | (() => undefined | DamRaisingTypeKeySpecifier),
		fields?: DamRaisingTypeFieldPolicy,
	},
	Displacements?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DisplacementsKeySpecifier | (() => undefined | DisplacementsKeySpecifier),
		fields?: DisplacementsFieldPolicy,
	},
	FileType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FileTypeKeySpecifier | (() => undefined | FileTypeKeySpecifier),
		fields?: FileTypeFieldPolicy,
	},
	FindAllAtoType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FindAllAtoTypeKeySpecifier | (() => undefined | FindAllAtoTypeKeySpecifier),
		fields?: FindAllAtoTypeFieldPolicy,
	},
	FindAllRecordsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FindAllRecordsTypeKeySpecifier | (() => undefined | FindAllRecordsTypeKeySpecifier),
		fields?: FindAllRecordsTypeFieldPolicy,
	},
	FindDataByRecordType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FindDataByRecordTypeKeySpecifier | (() => undefined | FindDataByRecordTypeKeySpecifier),
		fields?: FindDataByRecordTypeFieldPolicy,
	},
	FindGeneralInfoType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FindGeneralInfoTypeKeySpecifier | (() => undefined | FindGeneralInfoTypeKeySpecifier),
		fields?: FindGeneralInfoTypeFieldPolicy,
	},
	GenericDisplacementSurfaceDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GenericDisplacementSurfaceDataTypeKeySpecifier | (() => undefined | GenericDisplacementSurfaceDataTypeKeySpecifier),
		fields?: GenericDisplacementSurfaceDataTypeFieldPolicy,
	},
	GenericSurfaceMarkerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GenericSurfaceMarkerReadingTypeKeySpecifier | (() => undefined | GenericSurfaceMarkerReadingTypeKeySpecifier),
		fields?: GenericSurfaceMarkerReadingTypeFieldPolicy,
	},
	GistmCommentsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GistmCommentsTypeKeySpecifier | (() => undefined | GistmCommentsTypeKeySpecifier),
		fields?: GistmCommentsTypeFieldPolicy,
	},
	GistmDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GistmDataTypeKeySpecifier | (() => undefined | GistmDataTypeKeySpecifier),
		fields?: GistmDataTypeFieldPolicy,
	},
	GistmFileType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GistmFileTypeKeySpecifier | (() => undefined | GistmFileTypeKeySpecifier),
		fields?: GistmFileTypeFieldPolicy,
	},
	InfosInstrumentsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InfosInstrumentsTypeKeySpecifier | (() => undefined | InfosInstrumentsTypeKeySpecifier),
		fields?: InfosInstrumentsTypeFieldPolicy,
	},
	InspectionConservationStateType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InspectionConservationStateTypeKeySpecifier | (() => undefined | InspectionConservationStateTypeKeySpecifier),
		fields?: InspectionConservationStateTypeFieldPolicy,
	},
	InspectionDataImageType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InspectionDataImageTypeKeySpecifier | (() => undefined | InspectionDataImageTypeKeySpecifier),
		fields?: InspectionDataImageTypeFieldPolicy,
	},
	InspectionDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InspectionDataTypeKeySpecifier | (() => undefined | InspectionDataTypeKeySpecifier),
		fields?: InspectionDataTypeFieldPolicy,
	},
	InspectionGenericDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InspectionGenericDataTypeKeySpecifier | (() => undefined | InspectionGenericDataTypeKeySpecifier),
		fields?: InspectionGenericDataTypeFieldPolicy,
	},
	InspectionParticipantsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InspectionParticipantsTypeKeySpecifier | (() => undefined | InspectionParticipantsTypeKeySpecifier),
		fields?: InspectionParticipantsTypeFieldPolicy,
	},
	InspectionsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InspectionsTypeKeySpecifier | (() => undefined | InspectionsTypeKeySpecifier),
		fields?: InspectionsTypeFieldPolicy,
	},
	InstrumentDocsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstrumentDocsTypeKeySpecifier | (() => undefined | InstrumentDocsTypeKeySpecifier),
		fields?: InstrumentDocsTypeFieldPolicy,
	},
	InstrumentInfoByUserStructureType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstrumentInfoByUserStructureTypeKeySpecifier | (() => undefined | InstrumentInfoByUserStructureTypeKeySpecifier),
		fields?: InstrumentInfoByUserStructureTypeFieldPolicy,
	},
	InstrumentInfosType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstrumentInfosTypeKeySpecifier | (() => undefined | InstrumentInfosTypeKeySpecifier),
		fields?: InstrumentInfosTypeFieldPolicy,
	},
	InstrumentType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstrumentTypeKeySpecifier | (() => undefined | InstrumentTypeKeySpecifier),
		fields?: InstrumentTypeFieldPolicy,
	},
	LastInstrumentReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LastInstrumentReadingTypeKeySpecifier | (() => undefined | LastInstrumentReadingTypeKeySpecifier),
		fields?: LastInstrumentReadingTypeFieldPolicy,
	},
	LastPiezometerInaReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LastPiezometerInaReadingTypeKeySpecifier | (() => undefined | LastPiezometerInaReadingTypeKeySpecifier),
		fields?: LastPiezometerInaReadingTypeFieldPolicy,
	},
	LastReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LastReadingTypeKeySpecifier | (() => undefined | LastReadingTypeKeySpecifier),
		fields?: LastReadingTypeFieldPolicy,
	},
	LatestUserNotificationType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LatestUserNotificationTypeKeySpecifier | (() => undefined | LatestUserNotificationTypeKeySpecifier),
		fields?: LatestUserNotificationTypeFieldPolicy,
	},
	ListActionPlanPagType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListActionPlanPagTypeKeySpecifier | (() => undefined | ListActionPlanPagTypeKeySpecifier),
		fields?: ListActionPlanPagTypeFieldPolicy,
	},
	ListAllActionPlansToExportTableType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListAllActionPlansToExportTableTypeKeySpecifier | (() => undefined | ListAllActionPlansToExportTableTypeKeySpecifier),
		fields?: ListAllActionPlansToExportTableTypeFieldPolicy,
	},
	ListAllAssociatedActionPlansType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListAllAssociatedActionPlansTypeKeySpecifier | (() => undefined | ListAllAssociatedActionPlansTypeKeySpecifier),
		fields?: ListAllAssociatedActionPlansTypeFieldPolicy,
	},
	ListAllInstrumentsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListAllInstrumentsTypeKeySpecifier | (() => undefined | ListAllInstrumentsTypeKeySpecifier),
		fields?: ListAllInstrumentsTypeFieldPolicy,
	},
	ListAllPiezometerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListAllPiezometerReadingTypeKeySpecifier | (() => undefined | ListAllPiezometerReadingTypeKeySpecifier),
		fields?: ListAllPiezometerReadingTypeFieldPolicy,
	},
	ListAllPluviometerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListAllPluviometerReadingTypeKeySpecifier | (() => undefined | ListAllPluviometerReadingTypeKeySpecifier),
		fields?: ListAllPluviometerReadingTypeFieldPolicy,
	},
	ListAllSurfaceMarkerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListAllSurfaceMarkerReadingTypeKeySpecifier | (() => undefined | ListAllSurfaceMarkerReadingTypeKeySpecifier),
		fields?: ListAllSurfaceMarkerReadingTypeFieldPolicy,
	},
	ListAllWaterLevelReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListAllWaterLevelReadingTypeKeySpecifier | (() => undefined | ListAllWaterLevelReadingTypeKeySpecifier),
		fields?: ListAllWaterLevelReadingTypeFieldPolicy,
	},
	ListControlQuotasType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListControlQuotasTypeKeySpecifier | (() => undefined | ListControlQuotasTypeKeySpecifier),
		fields?: ListControlQuotasTypeFieldPolicy,
	},
	ListGistmCommentsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListGistmCommentsTypeKeySpecifier | (() => undefined | ListGistmCommentsTypeKeySpecifier),
		fields?: ListGistmCommentsTypeFieldPolicy,
	},
	ListInstrumentsBySectionsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListInstrumentsBySectionsTypeKeySpecifier | (() => undefined | ListInstrumentsBySectionsTypeKeySpecifier),
		fields?: ListInstrumentsBySectionsTypeFieldPolicy,
	},
	ListInstrumentsByTypeType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListInstrumentsByTypeTypeKeySpecifier | (() => undefined | ListInstrumentsByTypeTypeKeySpecifier),
		fields?: ListInstrumentsByTypeTypeFieldPolicy,
	},
	ListLatestPiezometerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListLatestPiezometerReadingTypeKeySpecifier | (() => undefined | ListLatestPiezometerReadingTypeKeySpecifier),
		fields?: ListLatestPiezometerReadingTypeFieldPolicy,
	},
	ListLatestPluviometerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListLatestPluviometerReadingTypeKeySpecifier | (() => undefined | ListLatestPluviometerReadingTypeKeySpecifier),
		fields?: ListLatestPluviometerReadingTypeFieldPolicy,
	},
	ListLatestSurfaceMarkerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListLatestSurfaceMarkerReadingTypeKeySpecifier | (() => undefined | ListLatestSurfaceMarkerReadingTypeKeySpecifier),
		fields?: ListLatestSurfaceMarkerReadingTypeFieldPolicy,
	},
	ListLatestUserNotificationType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListLatestUserNotificationTypeKeySpecifier | (() => undefined | ListLatestUserNotificationTypeKeySpecifier),
		fields?: ListLatestUserNotificationTypeFieldPolicy,
	},
	ListLatestWaterLevelReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListLatestWaterLevelReadingTypeKeySpecifier | (() => undefined | ListLatestWaterLevelReadingTypeKeySpecifier),
		fields?: ListLatestWaterLevelReadingTypeFieldPolicy,
	},
	ListModuleNotificationsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListModuleNotificationsTypeKeySpecifier | (() => undefined | ListModuleNotificationsTypeKeySpecifier),
		fields?: ListModuleNotificationsTypeFieldPolicy,
	},
	ListPiezometerInaAlertLevelsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListPiezometerInaAlertLevelsTypeKeySpecifier | (() => undefined | ListPiezometerInaAlertLevelsTypeKeySpecifier),
		fields?: ListPiezometerInaAlertLevelsTypeFieldPolicy,
	},
	ListPiezometerInaByStructure?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListPiezometerInaByStructureKeySpecifier | (() => undefined | ListPiezometerInaByStructureKeySpecifier),
		fields?: ListPiezometerInaByStructureFieldPolicy,
	},
	ListPiezometerInaReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListPiezometerInaReadingTypeKeySpecifier | (() => undefined | ListPiezometerInaReadingTypeKeySpecifier),
		fields?: ListPiezometerInaReadingTypeFieldPolicy,
	},
	ListSectionsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListSectionsTypeKeySpecifier | (() => undefined | ListSectionsTypeKeySpecifier),
		fields?: ListSectionsTypeFieldPolicy,
	},
	ListStructureModulesType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListStructureModulesTypeKeySpecifier | (() => undefined | ListStructureModulesTypeKeySpecifier),
		fields?: ListStructureModulesTypeFieldPolicy,
	},
	ListStructureSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListStructureSummaryKeySpecifier | (() => undefined | ListStructureSummaryKeySpecifier),
		fields?: ListStructureSummaryFieldPolicy,
	},
	ListStructuresType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListStructuresTypeKeySpecifier | (() => undefined | ListStructuresTypeKeySpecifier),
		fields?: ListStructuresTypeFieldPolicy,
	},
	ListSubscribedUserNotificationType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListSubscribedUserNotificationTypeKeySpecifier | (() => undefined | ListSubscribedUserNotificationTypeKeySpecifier),
		fields?: ListSubscribedUserNotificationTypeFieldPolicy,
	},
	ListSurfaceMarkerAlertLevelType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListSurfaceMarkerAlertLevelTypeKeySpecifier | (() => undefined | ListSurfaceMarkerAlertLevelTypeKeySpecifier),
		fields?: ListSurfaceMarkerAlertLevelTypeFieldPolicy,
	},
	ListSurfaceMarkerType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListSurfaceMarkerTypeKeySpecifier | (() => undefined | ListSurfaceMarkerTypeKeySpecifier),
		fields?: ListSurfaceMarkerTypeFieldPolicy,
	},
	ListSurfaceMarkersReferenceType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListSurfaceMarkersReferenceTypeKeySpecifier | (() => undefined | ListSurfaceMarkersReferenceTypeKeySpecifier),
		fields?: ListSurfaceMarkersReferenceTypeFieldPolicy,
	},
	ListUserPermissionType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ListUserPermissionTypeKeySpecifier | (() => undefined | ListUserPermissionTypeKeySpecifier),
		fields?: ListUserPermissionTypeFieldPolicy,
	},
	Login?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LoginKeySpecifier | (() => undefined | LoginKeySpecifier),
		fields?: LoginFieldPolicy,
	},
	LongitudinalSection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LongitudinalSectionKeySpecifier | (() => undefined | LongitudinalSectionKeySpecifier),
		fields?: LongitudinalSectionFieldPolicy,
	},
	MassiveStructureShoulderDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MassiveStructureShoulderDataTypeKeySpecifier | (() => undefined | MassiveStructureShoulderDataTypeKeySpecifier),
		fields?: MassiveStructureShoulderDataTypeFieldPolicy,
	},
	MobInitialInspectionDataModelType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MobInitialInspectionDataModelTypeKeySpecifier | (() => undefined | MobInitialInspectionDataModelTypeKeySpecifier),
		fields?: MobInitialInspectionDataModelTypeFieldPolicy,
	},
	MobInspectionDataModelType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MobInspectionDataModelTypeKeySpecifier | (() => undefined | MobInspectionDataModelTypeKeySpecifier),
		fields?: MobInspectionDataModelTypeFieldPolicy,
	},
	MobModuleInfoType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MobModuleInfoTypeKeySpecifier | (() => undefined | MobModuleInfoTypeKeySpecifier),
		fields?: MobModuleInfoTypeFieldPolicy,
	},
	ModuleFlagType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ModuleFlagTypeKeySpecifier | (() => undefined | ModuleFlagTypeKeySpecifier),
		fields?: ModuleFlagTypeFieldPolicy,
	},
	ModuleRoleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ModuleRoleTypeKeySpecifier | (() => undefined | ModuleRoleTypeKeySpecifier),
		fields?: ModuleRoleTypeFieldPolicy,
	},
	ModuleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ModuleTypeKeySpecifier | (() => undefined | ModuleTypeKeySpecifier),
		fields?: ModuleTypeFieldPolicy,
	},
	ModulesTypes?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ModulesTypesKeySpecifier | (() => undefined | ModulesTypesKeySpecifier),
		fields?: ModulesTypesFieldPolicy,
	},
	MssGenericBaseDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MssGenericBaseDataTypeKeySpecifier | (() => undefined | MssGenericBaseDataTypeKeySpecifier),
		fields?: MssGenericBaseDataTypeFieldPolicy,
	},
	MssGenericDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MssGenericDataTypeKeySpecifier | (() => undefined | MssGenericDataTypeKeySpecifier),
		fields?: MssGenericDataTypeFieldPolicy,
	},
	MssGenericDataWithTopicType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MssGenericDataWithTopicTypeKeySpecifier | (() => undefined | MssGenericDataWithTopicTypeKeySpecifier),
		fields?: MssGenericDataWithTopicTypeFieldPolicy,
	},
	MssGenericDataWithoutTopicType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MssGenericDataWithoutTopicTypeKeySpecifier | (() => undefined | MssGenericDataWithoutTopicTypeKeySpecifier),
		fields?: MssGenericDataWithoutTopicTypeFieldPolicy,
	},
	MssIdentifiedCracksType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MssIdentifiedCracksTypeKeySpecifier | (() => undefined | MssIdentifiedCracksTypeKeySpecifier),
		fields?: MssIdentifiedCracksTypeFieldPolicy,
	},
	MssIdentifiedCracksWithImageType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MssIdentifiedCracksWithImageTypeKeySpecifier | (() => undefined | MssIdentifiedCracksWithImageTypeKeySpecifier),
		fields?: MssIdentifiedCracksWithImageTypeFieldPolicy,
	},
	MssIdentifiedDeformationsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MssIdentifiedDeformationsTypeKeySpecifier | (() => undefined | MssIdentifiedDeformationsTypeKeySpecifier),
		fields?: MssIdentifiedDeformationsTypeFieldPolicy,
	},
	MssIdentifiedDeformationsWithImageType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MssIdentifiedDeformationsWithImageTypeKeySpecifier | (() => undefined | MssIdentifiedDeformationsWithImageTypeKeySpecifier),
		fields?: MssIdentifiedDeformationsWithImageTypeFieldPolicy,
	},
	MssIdentifiedWaterLeaksType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MssIdentifiedWaterLeaksTypeKeySpecifier | (() => undefined | MssIdentifiedWaterLeaksTypeKeySpecifier),
		fields?: MssIdentifiedWaterLeaksTypeFieldPolicy,
	},
	MssIdentifiedWaterLeaksWithImageType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MssIdentifiedWaterLeaksWithImageTypeKeySpecifier | (() => undefined | MssIdentifiedWaterLeaksWithImageTypeKeySpecifier),
		fields?: MssIdentifiedWaterLeaksWithImageTypeFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	NotificationsByModuleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationsByModuleTypeKeySpecifier | (() => undefined | NotificationsByModuleTypeKeySpecifier),
		fields?: NotificationsByModuleTypeFieldPolicy,
	},
	PagWaterLevelReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PagWaterLevelReadingTypeKeySpecifier | (() => undefined | PagWaterLevelReadingTypeKeySpecifier),
		fields?: PagWaterLevelReadingTypeFieldPolicy,
	},
	PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier),
		fields?: PageInfoFieldPolicy,
	},
	PaginatedFileType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedFileTypeKeySpecifier | (() => undefined | PaginatedFileTypeKeySpecifier),
		fields?: PaginatedFileTypeFieldPolicy,
	},
	PaginatedFindAllRecordsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedFindAllRecordsTypeKeySpecifier | (() => undefined | PaginatedFindAllRecordsTypeKeySpecifier),
		fields?: PaginatedFindAllRecordsTypeFieldPolicy,
	},
	PaginatedGistmFileType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedGistmFileTypeKeySpecifier | (() => undefined | PaginatedGistmFileTypeKeySpecifier),
		fields?: PaginatedGistmFileTypeFieldPolicy,
	},
	PaginatedGistmFilesType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedGistmFilesTypeKeySpecifier | (() => undefined | PaginatedGistmFilesTypeKeySpecifier),
		fields?: PaginatedGistmFilesTypeFieldPolicy,
	},
	PaginatedListActionPlanPagType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedListActionPlanPagTypeKeySpecifier | (() => undefined | PaginatedListActionPlanPagTypeKeySpecifier),
		fields?: PaginatedListActionPlanPagTypeFieldPolicy,
	},
	PaginatedListActionPlanType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedListActionPlanTypeKeySpecifier | (() => undefined | PaginatedListActionPlanTypeKeySpecifier),
		fields?: PaginatedListActionPlanTypeFieldPolicy,
	},
	PaginatedListControlQuotasType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedListControlQuotasTypeKeySpecifier | (() => undefined | PaginatedListControlQuotasTypeKeySpecifier),
		fields?: PaginatedListControlQuotasTypeFieldPolicy,
	},
	PaginatedListNotificationsByModuleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedListNotificationsByModuleTypeKeySpecifier | (() => undefined | PaginatedListNotificationsByModuleTypeKeySpecifier),
		fields?: PaginatedListNotificationsByModuleTypeFieldPolicy,
	},
	PaginatedListPiezometerInaAlertLevelsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedListPiezometerInaAlertLevelsTypeKeySpecifier | (() => undefined | PaginatedListPiezometerInaAlertLevelsTypeKeySpecifier),
		fields?: PaginatedListPiezometerInaAlertLevelsTypeFieldPolicy,
	},
	PaginatedListPiezometerInaReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedListPiezometerInaReadingTypeKeySpecifier | (() => undefined | PaginatedListPiezometerInaReadingTypeKeySpecifier),
		fields?: PaginatedListPiezometerInaReadingTypeFieldPolicy,
	},
	PaginatedListSurfaceMarkerAlertLevelType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedListSurfaceMarkerAlertLevelTypeKeySpecifier | (() => undefined | PaginatedListSurfaceMarkerAlertLevelTypeKeySpecifier),
		fields?: PaginatedListSurfaceMarkerAlertLevelTypeFieldPolicy,
	},
	PaginatedListSurfaceMarkersReferenceType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedListSurfaceMarkersReferenceTypeKeySpecifier | (() => undefined | PaginatedListSurfaceMarkersReferenceTypeKeySpecifier),
		fields?: PaginatedListSurfaceMarkersReferenceTypeFieldPolicy,
	},
	PaginatedNotificationsByModuleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedNotificationsByModuleTypeKeySpecifier | (() => undefined | PaginatedNotificationsByModuleTypeKeySpecifier),
		fields?: PaginatedNotificationsByModuleTypeFieldPolicy,
	},
	PaginatedPaginatedPluviometerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedPaginatedPluviometerReadingTypeKeySpecifier | (() => undefined | PaginatedPaginatedPluviometerReadingTypeKeySpecifier),
		fields?: PaginatedPaginatedPluviometerReadingTypeFieldPolicy,
	},
	PaginatedPaginatedSurfaceMarkersReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedPaginatedSurfaceMarkersReadingTypeKeySpecifier | (() => undefined | PaginatedPaginatedSurfaceMarkersReadingTypeKeySpecifier),
		fields?: PaginatedPaginatedSurfaceMarkersReadingTypeFieldPolicy,
	},
	PaginatedPaginatedWaterLevelReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedPaginatedWaterLevelReadingTypeKeySpecifier | (() => undefined | PaginatedPaginatedWaterLevelReadingTypeKeySpecifier),
		fields?: PaginatedPaginatedWaterLevelReadingTypeFieldPolicy,
	},
	PaginatedPiezometerInaAlertLevels?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedPiezometerInaAlertLevelsKeySpecifier | (() => undefined | PaginatedPiezometerInaAlertLevelsKeySpecifier),
		fields?: PaginatedPiezometerInaAlertLevelsFieldPolicy,
	},
	PaginatedPiezometerInaControlQuotas?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedPiezometerInaControlQuotasKeySpecifier | (() => undefined | PaginatedPiezometerInaControlQuotasKeySpecifier),
		fields?: PaginatedPiezometerInaControlQuotasFieldPolicy,
	},
	PaginatedPiezometerInaReading?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedPiezometerInaReadingKeySpecifier | (() => undefined | PaginatedPiezometerInaReadingKeySpecifier),
		fields?: PaginatedPiezometerInaReadingFieldPolicy,
	},
	PaginatedPluviometerAlertLevels?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedPluviometerAlertLevelsKeySpecifier | (() => undefined | PaginatedPluviometerAlertLevelsKeySpecifier),
		fields?: PaginatedPluviometerAlertLevelsFieldPolicy,
	},
	PaginatedPluviometerReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedPluviometerReadingTypeKeySpecifier | (() => undefined | PaginatedPluviometerReadingTypeKeySpecifier),
		fields?: PaginatedPluviometerReadingTypeFieldPolicy,
	},
	PaginatedPluviometerWaterLevelAlertLevelsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedPluviometerWaterLevelAlertLevelsTypeKeySpecifier | (() => undefined | PaginatedPluviometerWaterLevelAlertLevelsTypeKeySpecifier),
		fields?: PaginatedPluviometerWaterLevelAlertLevelsTypeFieldPolicy,
	},
	PaginatedPsbFileType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedPsbFileTypeKeySpecifier | (() => undefined | PaginatedPsbFileTypeKeySpecifier),
		fields?: PaginatedPsbFileTypeFieldPolicy,
	},
	PaginatedReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedReadingTypeKeySpecifier | (() => undefined | PaginatedReadingTypeKeySpecifier),
		fields?: PaginatedReadingTypeFieldPolicy,
	},
	PaginatedRecordsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedRecordsTypeKeySpecifier | (() => undefined | PaginatedRecordsTypeKeySpecifier),
		fields?: PaginatedRecordsTypeFieldPolicy,
	},
	PaginatedSurfaceMarkerAlertLevels?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedSurfaceMarkerAlertLevelsKeySpecifier | (() => undefined | PaginatedSurfaceMarkerAlertLevelsKeySpecifier),
		fields?: PaginatedSurfaceMarkerAlertLevelsFieldPolicy,
	},
	PaginatedSurfaceMarkerReferences?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedSurfaceMarkerReferencesKeySpecifier | (() => undefined | PaginatedSurfaceMarkerReferencesKeySpecifier),
		fields?: PaginatedSurfaceMarkerReferencesFieldPolicy,
	},
	PaginatedSurfaceMarkersReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedSurfaceMarkersReadingTypeKeySpecifier | (() => undefined | PaginatedSurfaceMarkersReadingTypeKeySpecifier),
		fields?: PaginatedSurfaceMarkersReadingTypeFieldPolicy,
	},
	PaginatedWaterLevelAlertLevels?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedWaterLevelAlertLevelsKeySpecifier | (() => undefined | PaginatedWaterLevelAlertLevelsKeySpecifier),
		fields?: PaginatedWaterLevelAlertLevelsFieldPolicy,
	},
	PaginatedWaterLevelAlertLevelsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedWaterLevelAlertLevelsTypeKeySpecifier | (() => undefined | PaginatedWaterLevelAlertLevelsTypeKeySpecifier),
		fields?: PaginatedWaterLevelAlertLevelsTypeFieldPolicy,
	},
	PaginatedWaterLevelReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedWaterLevelReadingTypeKeySpecifier | (() => undefined | PaginatedWaterLevelReadingTypeKeySpecifier),
		fields?: PaginatedWaterLevelReadingTypeFieldPolicy,
	},
	PiezometerInaAlertLevelsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PiezometerInaAlertLevelsTypeKeySpecifier | (() => undefined | PiezometerInaAlertLevelsTypeKeySpecifier),
		fields?: PiezometerInaAlertLevelsTypeFieldPolicy,
	},
	PiezometerInaInfosType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PiezometerInaInfosTypeKeySpecifier | (() => undefined | PiezometerInaInfosTypeKeySpecifier),
		fields?: PiezometerInaInfosTypeFieldPolicy,
	},
	PiezometerInaLastReadingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PiezometerInaLastReadingTypeKeySpecifier | (() => undefined | PiezometerInaLastReadingTypeKeySpecifier),
		fields?: PiezometerInaLastReadingTypeFieldPolicy,
	},
	PiezometerInaReadingValuesType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PiezometerInaReadingValuesTypeKeySpecifier | (() => undefined | PiezometerInaReadingValuesTypeKeySpecifier),
		fields?: PiezometerInaReadingValuesTypeFieldPolicy,
	},
	PluviometerListType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PluviometerListTypeKeySpecifier | (() => undefined | PluviometerListTypeKeySpecifier),
		fields?: PluviometerListTypeFieldPolicy,
	},
	PluviometerWaterLevelAlertLevelsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PluviometerWaterLevelAlertLevelsTypeKeySpecifier | (() => undefined | PluviometerWaterLevelAlertLevelsTypeKeySpecifier),
		fields?: PluviometerWaterLevelAlertLevelsTypeFieldPolicy,
	},
	PsbDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PsbDataTypeKeySpecifier | (() => undefined | PsbDataTypeKeySpecifier),
		fields?: PsbDataTypeFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	RecentRecordsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecentRecordsTypeKeySpecifier | (() => undefined | RecentRecordsTypeKeySpecifier),
		fields?: RecentRecordsTypeFieldPolicy,
	},
	ResponsibleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ResponsibleTypeKeySpecifier | (() => undefined | ResponsibleTypeKeySpecifier),
		fields?: ResponsibleTypeFieldPolicy,
	},
	RiskAssessmentType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RiskAssessmentTypeKeySpecifier | (() => undefined | RiskAssessmentTypeKeySpecifier),
		fields?: RiskAssessmentTypeFieldPolicy,
	},
	RoleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RoleTypeKeySpecifier | (() => undefined | RoleTypeKeySpecifier),
		fields?: RoleTypeFieldPolicy,
	},
	SaveAtoRecordRedAlertPeriodType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveAtoRecordRedAlertPeriodTypeKeySpecifier | (() => undefined | SaveAtoRecordRedAlertPeriodTypeKeySpecifier),
		fields?: SaveAtoRecordRedAlertPeriodTypeFieldPolicy,
	},
	SavedActionPlanCommentType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavedActionPlanCommentTypeKeySpecifier | (() => undefined | SavedActionPlanCommentTypeKeySpecifier),
		fields?: SavedActionPlanCommentTypeFieldPolicy,
	},
	SavedActionPlanTeamMembersType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavedActionPlanTeamMembersTypeKeySpecifier | (() => undefined | SavedActionPlanTeamMembersTypeKeySpecifier),
		fields?: SavedActionPlanTeamMembersTypeFieldPolicy,
	},
	SectionType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SectionTypeKeySpecifier | (() => undefined | SectionTypeKeySpecifier),
		fields?: SectionTypeFieldPolicy,
	},
	StructureBaseType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StructureBaseTypeKeySpecifier | (() => undefined | StructureBaseTypeKeySpecifier),
		fields?: StructureBaseTypeFieldPolicy,
	},
	StructureComplexType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StructureComplexTypeKeySpecifier | (() => undefined | StructureComplexTypeKeySpecifier),
		fields?: StructureComplexTypeFieldPolicy,
	},
	StructureDamTailingVolumeType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StructureDamTailingVolumeTypeKeySpecifier | (() => undefined | StructureDamTailingVolumeTypeKeySpecifier),
		fields?: StructureDamTailingVolumeTypeFieldPolicy,
	},
	StructureGeometryType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StructureGeometryTypeKeySpecifier | (() => undefined | StructureGeometryTypeKeySpecifier),
		fields?: StructureGeometryTypeFieldPolicy,
	},
	StructureModulesType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StructureModulesTypeKeySpecifier | (() => undefined | StructureModulesTypeKeySpecifier),
		fields?: StructureModulesTypeFieldPolicy,
	},
	StructureReservoirHandlingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StructureReservoirHandlingTypeKeySpecifier | (() => undefined | StructureReservoirHandlingTypeKeySpecifier),
		fields?: StructureReservoirHandlingTypeFieldPolicy,
	},
	StructureResponsibleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StructureResponsibleTypeKeySpecifier | (() => undefined | StructureResponsibleTypeKeySpecifier),
		fields?: StructureResponsibleTypeFieldPolicy,
	},
	StructureSummaryType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StructureSummaryTypeKeySpecifier | (() => undefined | StructureSummaryTypeKeySpecifier),
		fields?: StructureSummaryTypeFieldPolicy,
	},
	StructureType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StructureTypeKeySpecifier | (() => undefined | StructureTypeKeySpecifier),
		fields?: StructureTypeFieldPolicy,
	},
	SummaryHydrologyType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SummaryHydrologyTypeKeySpecifier | (() => undefined | SummaryHydrologyTypeKeySpecifier),
		fields?: SummaryHydrologyTypeFieldPolicy,
	},
	SurfaceMarkerAlertLevel?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SurfaceMarkerAlertLevelKeySpecifier | (() => undefined | SurfaceMarkerAlertLevelKeySpecifier),
		fields?: SurfaceMarkerAlertLevelFieldPolicy,
	},
	SurfaceMarkerType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SurfaceMarkerTypeKeySpecifier | (() => undefined | SurfaceMarkerTypeKeySpecifier),
		fields?: SurfaceMarkerTypeFieldPolicy,
	},
	SurfaceMarkersBaseData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SurfaceMarkersBaseDataKeySpecifier | (() => undefined | SurfaceMarkersBaseDataKeySpecifier),
		fields?: SurfaceMarkersBaseDataFieldPolicy,
	},
	SurfaceMarkersReadingPaginatedType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SurfaceMarkersReadingPaginatedTypeKeySpecifier | (() => undefined | SurfaceMarkersReadingPaginatedTypeKeySpecifier),
		fields?: SurfaceMarkersReadingPaginatedTypeFieldPolicy,
	},
	UpdatedGenericDataType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdatedGenericDataTypeKeySpecifier | (() => undefined | UpdatedGenericDataTypeKeySpecifier),
		fields?: UpdatedGenericDataTypeFieldPolicy,
	},
	User?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier),
		fields?: UserFieldPolicy,
	},
	UserNotificationType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserNotificationTypeKeySpecifier | (() => undefined | UserNotificationTypeKeySpecifier),
		fields?: UserNotificationTypeFieldPolicy,
	},
	ValidateReadingValuesInfosModelType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ValidateReadingValuesInfosModelTypeKeySpecifier | (() => undefined | ValidateReadingValuesInfosModelTypeKeySpecifier),
		fields?: ValidateReadingValuesInfosModelTypeFieldPolicy,
	},
	VerifierApproverType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VerifierApproverTypeKeySpecifier | (() => undefined | VerifierApproverTypeKeySpecifier),
		fields?: VerifierApproverTypeFieldPolicy,
	},
	WaterLevelAlertLevelsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | WaterLevelAlertLevelsTypeKeySpecifier | (() => undefined | WaterLevelAlertLevelsTypeKeySpecifier),
		fields?: WaterLevelAlertLevelsTypeFieldPolicy,
	},
	WaterLevelListType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | WaterLevelListTypeKeySpecifier | (() => undefined | WaterLevelListTypeKeySpecifier),
		fields?: WaterLevelListTypeFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
export const namedOperations = {
  Query: {
    FindActionPlanData: 'FindActionPlanData' as const,
    FindActionPlanMembers: 'FindActionPlanMembers' as const,
    FindActionPlanResponsible: 'FindActionPlanResponsible' as const,
    Query: 'Query' as const,
    FindAllActionPlans: 'FindAllActionPlans' as const,
    FindAllAssociatedActionPlans: 'FindAllAssociatedActionPlans' as const,
    FindAllAto: 'FindAllAto' as const,
    FindAllAtoImages: 'FindAllAtoImages' as const,
    FindAllRecordsPag: 'FindAllRecordsPag' as const,
    FindAllAtoVideos: 'FindAllAtoVideos' as const,
    FindAllInspectionsByStructure: 'FindAllInspectionsByStructure' as const,
    FindAllPsbData: 'FindAllPsbData' as const,
    FindAtoApprovers: 'FindAtoApprovers' as const,
    FindGeneralInfo: 'FindGeneralInfo' as const,
    FindAtoVerifiers: 'FindAtoVerifiers' as const,
    FindDataByInspection: 'FindDataByInspection' as const,
    FindDataByRecord: 'FindDataByRecord' as const,
    FindDataToChart: 'FindDataToChart' as const,
    FindFilesPag: 'FindFilesPag' as const,
    ListAlertsLevelByPluviometerPag: 'ListAlertsLevelByPluviometerPag' as const,
    ListAlertsLevelByWaterLevelPag: 'ListAlertsLevelByWaterLevelPag' as const,
    ListAlertsLevelByPiezometersPag: 'ListAlertsLevelByPiezometersPag' as const,
    ListAssociatedStructures: 'ListAssociatedStructures' as const,
    ListCompanies: 'ListCompanies' as const,
    ListCompanyRelatedUsersAndStructures:
      'ListCompanyRelatedUsersAndStructures' as const,
    ListControlQuotasByPiezometersPag:
      'ListControlQuotasByPiezometersPag' as const,
    ListInstrumentById: 'ListInstrumentById' as const,
    ListInstrumentsByStructure: 'ListInstrumentsByStructure' as const,
    ListInstrumentTypes: 'ListInstrumentTypes' as const,
    ListLatestReadingByPluviometer: 'ListLatestReadingByPluviometer' as const,
    ListLatestReadingByPiezometer: 'ListLatestReadingByPiezometer' as const,
    ListLatestSurfaceMarkerReading: 'ListLatestSurfaceMarkerReading' as const,
    ListLatestUserNotification: 'ListLatestUserNotification' as const,
    ListLatestWaterLevelReading: 'ListLatestWaterLevelReading' as const,
    ListModules: 'ListModules' as const,
    ListModulesByStructureId: 'ListModulesByStructureId' as const,
    ListModulesRoles: 'ListModulesRoles' as const,
    ListNotificationsByModulePag: 'ListNotificationsByModulePag' as const,
    ListPiezometersByStructure: 'ListPiezometersByStructure' as const,
    ListPluviometersByStructure: 'ListPluviometersByStructure' as const,
    ListReadingByPiezometer: 'ListReadingByPiezometer' as const,
    ListReadingByPiezometersPag: 'ListReadingByPiezometersPag' as const,
    ListReadingByPluviometer: 'ListReadingByPluviometer' as const,
    ListReadingByPluviometerPag: 'ListReadingByPluviometerPag' as const,
    ListReadingBySurfaceMarkersPag: 'ListReadingBySurfaceMarkersPag' as const,
    ListReadingByWaterLevel: 'ListReadingByWaterLevel' as const,
    ListReadingByWaterLevelPag: 'ListReadingByWaterLevelPag' as const,
    ListSection: 'ListSection' as const,
    ListSectionsByStructure: 'ListSectionsByStructure' as const,
    ListStructureAndModules: 'ListStructureAndModules' as const,
    ListStructures: 'ListStructures' as const,
    ListSubscribedUserNotification: 'ListSubscribedUserNotification' as const,
    ListAlertsLevelBySurfaceMarkerPag:
      'ListAlertsLevelBySurfaceMarkerPag' as const,
    ListReadingBySurfaceMarkers: 'ListReadingBySurfaceMarkers' as const,
    ListReferencesBySurfaceMarkerPag:
      'ListReferencesBySurfaceMarkerPag' as const,
    ListSurfaceMarkersByStructure: 'ListSurfaceMarkersByStructure' as const,
    ListUnreadModuleNotifications: 'ListUnreadModuleNotifications' as const,
    ListUsers: 'ListUsers' as const,
    ListUsersPermissions: 'ListUsersPermissions' as const,
    ListWaterLevelByStructure: 'ListWaterLevelByStructure' as const,
    GetModuleByStructure: 'GetModuleByStructure' as const,
    GetUserStructuresList: 'GetUserStructuresList' as const,
    GetStructureSummary: 'GetStructureSummary' as const
  },
  Mutation: {
    EditStructureDamTailingVolume: 'EditStructureDamTailingVolume' as const,
    Mutation: 'Mutation' as const,
    ActionPlanApprovalFlow: 'ActionPlanApprovalFlow' as const,
    ActionPlanReprovalFlow: 'ActionPlanReprovalFlow' as const,
    UpdateAto: 'UpdateAto' as const,
    AddParticipants: 'AddParticipants' as const,
    AddPiezometerInaAlertLevels: 'AddPiezometerInaAlertLevels' as const,
    CreatePiezometer: 'CreatePiezometer' as const,
    AddPluviometerAlertLevels: 'AddPluviometerAlertLevels' as const,
    CreatePluviometer: 'CreatePluviometer' as const,
    AddStructureDamTailingVolume: 'AddStructureDamTailingVolume' as const,
    CreateSurfaceMarker: 'CreateSurfaceMarker' as const,
    AddSurfaceMarkerAlertLevels: 'AddSurfaceMarkerAlertLevels' as const,
    AddWaterLevelAlertLevels: 'AddWaterLevelAlertLevels' as const,
    CreateWaterLevel: 'CreateWaterLevel' as const,
    AssociateActionPlans: 'AssociateActionPlans' as const,
    SetAtoCanceled: 'SetAtoCanceled' as const,
    CreateActionPlan: 'CreateActionPlan' as const,
    CreateAto: 'CreateAto' as const,
    CreateCompany: 'CreateCompany' as const,
    CreateInspection: 'CreateInspection' as const,
    CreatePiezometerReading: 'CreatePiezometerReading' as const,
    CreatePluviometerReading: 'CreatePluviometerReading' as const,
    CreateSection: 'CreateSection' as const,
    CreateStructure: 'CreateStructure' as const,
    CreateStructureDamRaising: 'CreateStructureDamRaising' as const,
    CreateStructureGeometry: 'CreateStructureGeometry' as const,
    CreateStructureHydrology: 'CreateStructureHydrology' as const,
    CreateStructureModules: 'CreateStructureModules' as const,
    CreateStructureReservoirHandling:
      'CreateStructureReservoirHandling' as const,
    CreateStructureRiskAssessment: 'CreateStructureRiskAssessment' as const,
    CreateStructureSummary: 'CreateStructureSummary' as const,
    CreateSurfaceMarkersReading: 'CreateSurfaceMarkersReading' as const,
    CreateUser: 'CreateUser' as const,
    CreateUserInstrumentNotification:
      'CreateUserInstrumentNotification' as const,
    CreateUserPermission: 'CreateUserPermission' as const,
    CreateWaterLevelReading: 'CreateWaterLevelReading' as const,
    DeleteActionPlanComment: 'DeleteActionPlanComment' as const,
    DeleteActionPlanTeamMember: 'DeleteActionPlanTeamMember' as const,
    DeleteAtoDocs: 'DeleteAtoDocs' as const,
    DeleteCompany: 'DeleteCompany' as const,
    DeleteCompanyLogo: 'DeleteCompanyLogo' as const,
    DeleteInspection: 'DeleteInspection' as const,
    DeleteInstrument: 'DeleteInstrument' as const,
    DeleteInstrumentAlertLevel: 'DeleteInstrumentAlertLevel' as const,
    DeleteInstrumentDocById: 'DeleteInstrumentDocById' as const,
    DeleteInstrumentReading: 'DeleteInstrumentReading' as const,
    DeleteParticipant: 'DeleteParticipant' as const,
    DeletePsbFile: 'DeletePsbFile' as const,
    DeleteRecordActivities: 'DeleteRecordActivities' as const,
    DeleteRecordActivitiesImage: 'DeleteRecordActivitiesImage' as const,
    DeleteRecordActivitiesVideo: 'DeleteRecordActivitiesVideo' as const,
    DeleteRecordEquipmentAndLabor: 'DeleteRecordEquipmentAndLabor' as const,
    DeleteRecordImage: 'DeleteRecordImage' as const,
    DeleteRecordObservation: 'DeleteRecordObservation' as const,
    DeleteRecordRedAlertPeriod: 'DeleteRecordRedAlertPeriod' as const,
    DeleteRecordVideo: 'DeleteRecordVideo' as const,
    DeleteRecordWeatherConditions: 'DeleteRecordWeatherConditions' as const,
    DeleteRecordWorkShift: 'DeleteRecordWorkShift' as const,
    DeleteSection: 'DeleteSection' as const,
    DeleteStructure: 'DeleteStructure' as const,
    DeleteStructureDamRaising: 'DeleteStructureDamRaising' as const,
    DeleteStructureModule: 'DeleteStructureModule' as const,
    DeleteSubscribedUserNotification:
      'DeleteSubscribedUserNotification' as const,
    DeleteUser: 'DeleteUser' as const,
    DeleteUserPermission: 'DeleteUserPermission' as const,
    SetAtoDone: 'SetAtoDone' as const,
    EditCompany: 'EditCompany' as const,
    UpdateGenericData: 'UpdateGenericData' as const,
    EditPassword: 'EditPassword' as const,
    EditPiezometer: 'EditPiezometer' as const,
    EditPiezometerAlert: 'EditPiezometerAlert' as const,
    EditPiezometerControlCote: 'EditPiezometerControlCote' as const,
    EditPiezometerReading: 'EditPiezometerReading' as const,
    EditPluviometer: 'EditPluviometer' as const,
    EditPluviometerAndWaterLevelAlert:
      'EditPluviometerAndWaterLevelAlert' as const,
    EditPluviometerReading: 'EditPluviometerReading' as const,
    EditSection: 'EditSection' as const,
    EditStructure: 'EditStructure' as const,
    EditStructureAndModules: 'EditStructureAndModules' as const,
    EditStructureDamRaising: 'EditStructureDamRaising' as const,
    EditStructureGeometry: 'EditStructureGeometry' as const,
    EditStructureHydrology: 'EditStructureHydrology' as const,
    EditStructureReservoirHandling: 'EditStructureReservoirHandling' as const,
    EditStructureRiskAssessment: 'EditStructureRiskAssessment' as const,
    EditStructureSummary: 'EditStructureSummary' as const,
    EditSurfaceMarker: 'EditSurfaceMarker' as const,
    EditSurfaceMarkerAlertLevel: 'EditSurfaceMarkerAlertLevel' as const,
    EditSurfaceMarkersReading: 'EditSurfaceMarkersReading' as const,
    EditSurfaceMarkerReference: 'EditSurfaceMarkerReference' as const,
    EditUser: 'EditUser' as const,
    EditUserPermission: 'EditUserPermission' as const,
    EditWaterLevel: 'EditWaterLevel' as const,
    EditWaterLevelReading: 'EditWaterLevelReading' as const,
    ForgotPassword: 'ForgotPassword' as const,
    GenerateInspectionPdf: 'GenerateInspectionPdf' as const,
    Login: 'Login' as const,
    ReactivateAto: 'ReactivateAto' as const,
    RecordApprovalFlow: 'RecordApprovalFlow' as const,
    RecordReprovalFlow: 'RecordReprovalFlow' as const,
    SaveActionPlanComment: 'SaveActionPlanComment' as const,
    SaveActionPlanTeamMembers: 'SaveActionPlanTeamMembers' as const,
    SaveAtoDocs: 'SaveAtoDocs' as const,
    SaveConservationState: 'SaveConservationState' as const,
    SaveDataToSectionChart: 'SaveDataToSectionChart' as const,
    SaveGeneralComments: 'SaveGeneralComments' as const,
    SaveGenericData: 'SaveGenericData' as const,
    SaveInstrumentDocs: 'SaveInstrumentDocs' as const,
    SaveMassiveStructureShoulder: 'SaveMassiveStructureShoulder' as const,
    SavePiezometerControlQuota: 'SavePiezometerControlQuota' as const,
    SaveRecordActivities: 'SaveRecordActivities' as const,
    SaveRecordEquipmentAndLabor: 'SaveRecordEquipmentAndLabor' as const,
    SaveRecordObservation: 'SaveRecordObservation' as const,
    SaveRecordRedAlertPeriod: 'SaveRecordRedAlertPeriod' as const,
    SaveRecordWeatherConditions: 'SaveRecordWeatherConditions' as const,
    SaveRecordWorkShift: 'SaveRecordWorkShift' as const,
    SetActionPlanCanceled: 'SetActionPlanCanceled' as const,
    SetActionPlanProgress: 'SetActionPlanProgress' as const,
    SetNotificationUnreadStatus: 'SetNotificationUnreadStatus' as const,
    SignAtoRecord: 'SignAtoRecord' as const,
    UpdateActionPlan: 'UpdateActionPlan' as const,
    UpdateConservationState: 'UpdateConservationState' as const,
    UpdateDate: 'UpdateDate' as const,
    UpdateGeneralComments: 'UpdateGeneralComments' as const,
    UpdateRecordActivities: 'UpdateRecordActivities' as const,
    UpdateRecordEquipmentAndLabor: 'UpdateRecordEquipmentAndLabor' as const,
    UpdateRecordImageObservation: 'UpdateRecordImageObservation' as const,
    UpdateRecordObservation: 'UpdateRecordObservation' as const,
    UpdateRecordRedAlertPeriod: 'UpdateRecordRedAlertPeriod' as const,
    UpdateRecordWeatherConditions: 'UpdateRecordWeatherConditions' as const,
    UpdateRecordWorkShift: 'UpdateRecordWorkShift' as const,
    UpdateSectionChartParameters: 'UpdateSectionChartParameters' as const
  }
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: Date; output: Date };
  Upload: { input: any; output: any };
};

export enum AnmClassificationEnum {
  A = 'A',
  AA = 'AA',
  B = 'B',
  C = 'C',
  D = 'D',
  NA = 'NA'
}

export type ActionPlanCommentFilesType = {
  __typename?: 'ActionPlanCommentFilesType';
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Action plan comments */
export type ActionPlanCommentsDataType = {
  __typename?: 'ActionPlanCommentsDataType';
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  files: Array<ActionPlanCommentFilesType>;
  id: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

/** Action plan data */
export type ActionPlanDataType = {
  __typename?: 'ActionPlanDataType';
  code: Scalars['String']['output'];
  comments: Array<ActionPlanCommentsDataType>;
  description: Scalars['String']['output'];
  images: Array<Scalars['String']['output']>;
  imagesCount: Scalars['Int']['output'];
  priority?: Maybe<ActionPlanPriorityEnum>;
  responsible?: Maybe<ActionPlanResponsibleDataType>;
  situation?: Maybe<ActionPlanSituationEnum>;
  teamMembers: Array<ActionPlanTeamMembersDataType>;
  timeFrame?: Maybe<Scalars['DateTime']['output']>;
};

export type ActionPlanFilterInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<ActionPlanPriorityEnum>;
  responsibleName?: InputMaybe<Scalars['String']['input']>;
  situation?: InputMaybe<ActionPlanSituationEnum>;
  /** Array of given data, start and end */
  timeFramePeriod?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  type?: InputMaybe<ActionPlanTypeEnum>;
};

/** Action Plan priority Type Enum */
export enum ActionPlanPriorityEnum {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

/** Action plan responsible */
export type ActionPlanResponsibleDataType = {
  __typename?: 'ActionPlanResponsibleDataType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ActionPlanResponsibleType = {
  __typename?: 'ActionPlanResponsibleType';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Action Plan Situations */
export enum ActionPlanSituationEnum {
  Canceled = 'Canceled',
  Done = 'Done',
  Late = 'Late',
  Opened = 'Opened',
  Progress = 'Progress',
  Reproved = 'Reproved',
  Review = 'Review'
}

/** Action plan members */
export type ActionPlanTeamMembersDataType = {
  __typename?: 'ActionPlanTeamMembersDataType';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Action Plan Type */
export enum ActionPlanTypeEnum {
  Automatic = 'Automatic',
  Manual = 'Manual'
}

export type AddDamTailingVolumeInput = {
  date: Scalars['DateTime']['input'];
  reservoirHandlingId: Scalars['String']['input'];
  volume: Scalars['Float']['input'];
};

export type AddSurfaceMarkerAlertLevels = {
  coordinateE?: InputMaybe<Scalars['Float']['input']>;
  coordinateN?: InputMaybe<Scalars['Float']['input']>;
  date: Scalars['DateTime']['input'];
  elevation?: InputMaybe<Scalars['Float']['input']>;
  instrumentId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AdminBaseCompanyType = {
  __typename?: 'AdminBaseCompanyType';
  cnpj: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AdminUserRoleType = {
  __typename?: 'AdminUserRoleType';
  role: UserRole;
};

export type AdminUserType = {
  __typename?: 'AdminUserType';
  company: AdminBaseCompanyType;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  position: Scalars['String']['output'];
  role: AdminUserRoleType;
  signature?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type AlertLevelToChartType = {
  __typename?: 'AlertLevelToChartType';
  name: Scalars['String']['output'];
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

/** inspection data answers */
export enum AnswerEnum {
  Deficient = 'Deficient',
  Good = 'Good',
  NA = 'NA',
  No = 'No',
  Regular = 'Regular',
  Yes = 'Yes'
}

export type AssociatedStructureType = {
  __typename?: 'AssociatedStructureType';
  associatedStructureId?: Maybe<Scalars['String']['output']>;
  associatedStructureName?: Maybe<Scalars['String']['output']>;
};

export type AssociatedType = {
  __typename?: 'AssociatedType';
  active: Scalars['Boolean']['output'];
  hydrologyInfos: SummaryHydrologyType;
  id: Scalars['String']['output'];
  isAssociated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  summary: StructureSummaryType;
  type?: Maybe<Scalars['String']['output']>;
};

export type AtoDocsIdModelType = {
  __typename?: 'AtoDocsIdModelType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AtoDocsInput = {
  name: Scalars['String']['input'];
};

/** Ato Equipment Labor Type Enum */
export enum AtoEquipmentLaborTypeEnum {
  Equipment = 'Equipment',
  Labor = 'Labor'
}

export type AtoFilterInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  recordNumber?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AtoImageType = {
  __typename?: 'AtoImageType';
  image: Scalars['String']['output'];
};

export type AtoImagesType = {
  __typename?: 'AtoImagesType';
  images: Array<AtoImageType>;
  recordNumber: Scalars['String']['output'];
};

/** Ato Observation Type Enum */
export enum AtoObservationTypeEnum {
  InspectionObservation = 'InspectionObservation',
  ResponsibleCompanyObservation = 'ResponsibleCompanyObservation',
  WorkSafetyObservation = 'WorkSafetyObservation'
}

export type AtoRecordActivitiesDataType = {
  __typename?: 'AtoRecordActivitiesDataType';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images: Array<AtoRecordActivitiesImagesType>;
  videos: Array<AtoRecordActivitiesVideosType>;
};

export type AtoRecordActivitiesImagesType = {
  __typename?: 'AtoRecordActivitiesImagesType';
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
};

export type AtoRecordActivitiesVideosType = {
  __typename?: 'AtoRecordActivitiesVideosType';
  id: Scalars['String']['output'];
  video: Scalars['String']['output'];
};

export type AtoRecordEquipmentAndLaborDataType = {
  __typename?: 'AtoRecordEquipmentAndLaborDataType';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
};

export type AtoRecordImagesType = {
  __typename?: 'AtoRecordImagesType';
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
};

export type AtoRecordObservationsType = {
  __typename?: 'AtoRecordObservationsType';
  id: Scalars['String']['output'];
  observation: Scalars['String']['output'];
};

export type AtoRecordRedAlertPeriodType = {
  __typename?: 'AtoRecordRedAlertPeriodType';
  end: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
};

export type AtoRecordSignaturesDataType = {
  __typename?: 'AtoRecordSignaturesDataType';
  id: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  userType: UserTypeEnum;
};

export type AtoRecordVideoType = {
  __typename?: 'AtoRecordVideoType';
  id: Scalars['String']['output'];
  observation: Scalars['String']['output'];
  video: Scalars['String']['output'];
};

export type AtoRecordWeatherConditionsType = {
  __typename?: 'AtoRecordWeatherConditionsType';
  end: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
  weather: AtoWeatherConditionEnum;
};

export type AtoRecordWorkShiftType = {
  __typename?: 'AtoRecordWorkShiftType';
  entry: Scalars['DateTime']['output'];
  entryInterval: Scalars['DateTime']['output'];
  exit: Scalars['DateTime']['output'];
  exitInterval: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  observation: Scalars['String']['output'];
  totalHours: Scalars['String']['output'];
};

export type AtoReferencesDocsType = {
  __typename?: 'AtoReferencesDocsType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** ATO record situation */
export enum AtoSituationEnum {
  Approved = 'Approved',
  Canceled = 'Canceled',
  Done = 'Done',
  Late = 'Late',
  Opened = 'Opened',
  Progress = 'Progress',
  Reproved = 'Reproved',
  Review = 'Review'
}

export type AtoUserTypesType = {
  __typename?: 'AtoUserTypesType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  userType: UserTypeEnum;
};

export type AtoVideoType = {
  __typename?: 'AtoVideoType';
  video: Scalars['String']['output'];
};

export type AtoVideosType = {
  __typename?: 'AtoVideosType';
  recordNumber: Scalars['String']['output'];
  videos: Array<AtoVideoType>;
};

/** Ato weather conditions ENUM */
export enum AtoWeatherConditionEnum {
  Good = 'Good',
  Rainy = 'Rainy'
}

export type BaseEditInstrumentInput = {
  activationDate?: InputMaybe<Scalars['DateTime']['input']>;
  coordinateE: Scalars['Float']['input'];
  coordinateN: Scalars['Float']['input'];
  deactivationDate?: InputMaybe<Scalars['DateTime']['input']>;
  installLocation?: InputMaybe<Scalars['String']['input']>;
  installationDate?: InputMaybe<Scalars['DateTime']['input']>;
  instrumentId: Scalars['String']['input'];
  isUTM: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  operationalStatus: InstrumentStatusEnum;
  readingType: InstrumentReadingTypeEnum;
};

export type BaseIdNameType = {
  __typename?: 'BaseIdNameType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BaseInstrumentListType = {
  __typename?: 'BaseInstrumentListType';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  coordinateE?: Maybe<Scalars['Float']['output']>;
  coordinateN?: Maybe<Scalars['Float']['output']>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  installLocation?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  operationalStatus: InstrumentStatusEnum;
  readingType: Scalars['String']['output'];
};

export type BaseInstrumentType = {
  __typename?: 'BaseInstrumentType';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  installLocation?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['DateTime']['output']>;
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  operationalStatus: InstrumentStatusEnum;
  readingType: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  type?: Maybe<InstrumentType>;
};

export type BasePluviometerInput = {
  /** Type of the pluviometer */
  pluviometerType?: InputMaybe<Scalars['String']['input']>;
};

export type BasePluviometerReadingType = {
  __typename?: 'BasePluviometerReadingType';
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  /** rainfall */
  rainfall: Scalars['Float']['output'];
};

export type BaseSaveRecordRedPeriodAndWeatherConditionsDataInput = {
  end: Scalars['DateTime']['input'];
  recordId: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
};

export type BaseStructureModulesInput = {
  /** End date */
  end?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  /** Start date */
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BaseSurfaceMarkersReadingType = {
  __typename?: 'BaseSurfaceMarkersReadingType';
  coordinateE?: Maybe<Scalars['Float']['output']>;
  coordinateN?: Maybe<Scalars['Float']['output']>;
  date: Scalars['DateTime']['output'];
  elevation?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
};

export type BaseWaterLevelReadingType = {
  __typename?: 'BaseWaterLevelReadingType';
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type BasicDataToChartType = {
  __typename?: 'BasicDataToChartType';
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

export type BasicInstrumentType = {
  __typename?: 'BasicInstrumentType';
  activationDate?: Maybe<Scalars['String']['output']>;
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  deactivationDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  installLocation?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['String']['output']>;
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  operationalStatus: InstrumentStatusEnum;
  readingType: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  type: BasicInstrumentTypeType;
};

export type BasicInstrumentTypeType = {
  __typename?: 'BasicInstrumentTypeType';
  id: Scalars['String']['output'];
  instrumentType: Scalars['String']['output'];
};

export type BasicInstrumentTypeWithSectionAndDocs = {
  __typename?: 'BasicInstrumentTypeWithSectionAndDocs';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  docs: Array<InstrumentDocsType>;
  id: Scalars['String']['output'];
  installLocation?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['DateTime']['output']>;
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  operationalStatus: InstrumentStatusEnum;
  readingType: Scalars['String']['output'];
  sections: Array<BasicSectionsType>;
  status: Scalars['Boolean']['output'];
  type: BasicInstrumentTypeType;
};

export type BasicSectionsType = {
  __typename?: 'BasicSectionsType';
  id: Scalars['String']['output'];
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type BasicStructureType = {
  __typename?: 'BasicStructureType';
  /** structure id */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type BasicUserType = {
  __typename?: 'BasicUserType';
  /** User email */
  email: Scalars['String']['output'];
  /** User name */
  name: Scalars['String']['output'];
};

export type BasicUserWithIdType = {
  __typename?: 'BasicUserWithIdType';
  /** User email */
  email: Scalars['String']['output'];
  /** User id */
  id: Scalars['String']['output'];
  /** User name */
  name: Scalars['String']['output'];
};

export type BasicUserWithPhoneNumberType = {
  __typename?: 'BasicUserWithPhoneNumberType';
  /** User email */
  email: Scalars['String']['output'];
  /** User name */
  name: Scalars['String']['output'];
  /** User phone number */
  phoneNumber: Scalars['String']['output'];
};

/** Action plan data */
export type CalculatedInfosType = {
  __typename?: 'CalculatedInfosType';
  totalPlans: Scalars['Float']['output'];
  totalPlansDone: Scalars['Float']['output'];
  totalPlansHighPriority: Scalars['Float']['output'];
  totalPlansInProgress: Scalars['Float']['output'];
  totalPlansLate: Scalars['Float']['output'];
  totalPlansOpened: Scalars['Float']['output'];
};

export type ChartPiezometerReadingType = {
  __typename?: 'ChartPiezometerReadingType';
  alertLevels: Array<PiezometerInaAlertLevelsType>;
  cotes: Array<ControlCotesType>;
  instrumentId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  readings: Array<PiezometerInaReadingValuesType>;
};

export type ChartPluviometerReadingType = {
  __typename?: 'ChartPluviometerReadingType';
  instrumentId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  readings: Array<BasePluviometerReadingType>;
};

export type ChartSurfaceMarkerReadingType = {
  __typename?: 'ChartSurfaceMarkerReadingType';
  /** displacement value for elevation in mm */
  displacementElevationData: Array<GenericDisplacementSurfaceDataType>;
  elevationData: Array<GenericSurfaceMarkerReadingType>;
  instrumentId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ChartWaterLevelReadingType = {
  __typename?: 'ChartWaterLevelReadingType';
  instrumentId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  readings: Array<BaseWaterLevelReadingType>;
  spillwaySill?: Maybe<Scalars['Float']['output']>;
};

export enum ClassificationEnum {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  NA = 'NA'
}

export type CompanyAddressInput = {
  addressLine?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  number?: InputMaybe<Scalars['Float']['input']>;
  state: Scalars['String']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyAddressType = {
  __typename?: 'CompanyAddressType';
  addressLine?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  number?: Maybe<Scalars['Float']['output']>;
  state: Scalars['String']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type CompanyRelatedUsersAndStructuresType = {
  __typename?: 'CompanyRelatedUsersAndStructuresType';
  cnpj: Scalars['String']['output'];
  collaborators: Array<BasicUserWithIdType>;
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  structures: Array<StructureBaseType>;
};

export type CompanyType = {
  __typename?: 'CompanyType';
  address: CompanyAddressType;
  cnpj: Scalars['String']['output'];
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type CompanyWithStructureType = {
  __typename?: 'CompanyWithStructureType';
  address: CompanyAddressType;
  cnpj: Scalars['String']['output'];
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  structures: Array<BasicStructureType>;
};

/** inspection conservation state notes */
export enum ConservationStateNoteEnum {
  Five = 'Five',
  Four = 'Four',
  Six = 'Six',
  Ten = 'Ten',
  Three = 'Three',
  Two = 'Two',
  Zero = 'Zero'
}

/** inspection conservation state titles */
export enum ConservationStateTitleEnum {
  Deformations = 'Deformations',
  Percolation = 'Percolation',
  ReliabilityExtravasationStructures = 'ReliabilityExtravasationStructures',
  SlopeAndParametersDeterioration = 'SlopeAndParametersDeterioration',
  SurfaceDrainage = 'SurfaceDrainage'
}

export type ControlCotesType = {
  __typename?: 'ControlCotesType';
  bottomCote: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  referenceDate: Scalars['DateTime']['output'];
  topCote: Scalars['Float']['output'];
};

export type CreateActionPlanCommentInput = {
  actionPlanId: Scalars['String']['input'];
  comment: Scalars['String']['input'];
  files: Array<Scalars['Upload']['input']>;
};

export type CreateActionPlanInput = {
  description: Scalars['String']['input'];
  images: Array<Scalars['Upload']['input']>;
  priority?: InputMaybe<ActionPlanPriorityEnum>;
  responsibleId?: InputMaybe<Scalars['String']['input']>;
  structureId: Scalars['String']['input'];
  timeFrame?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateAtoInput = {
  clientApproverId?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['String']['input'];
  contract: Scalars['String']['input'];
  docs?: InputMaybe<Array<Scalars['String']['input']>>;
  finalDate: Scalars['DateTime']['input'];
  initialDate: Scalars['DateTime']['input'];
  responsibleCompanyId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  verifierId: Scalars['String']['input'];
};

export type CreateCompanyInput = {
  address: CompanyAddressInput;
  cnpj: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateGistmCommentsInput = {
  comment: Scalars['String']['input'];
  gistmId: Scalars['String']['input'];
};

export type CreateInspectionInput = {
  /** Date of inspection */
  date: Scalars['DateTime']['input'];
  /** Participants */
  participants: Array<CreateParticipantsInput>;
  /** Id of structure */
  structureId: Scalars['String']['input'];
};

export type CreateInspectionMobileInput = {
  /** Date of inspection */
  date: Scalars['DateTime']['input'];
  /** Participants */
  participants: Array<CreateParticipantsMobileInput>;
  /** Id of structure */
  structureId: Scalars['String']['input'];
};

export type CreateInstrumentInput = {
  activationDate?: InputMaybe<Scalars['DateTime']['input']>;
  coordinateE: Scalars['Float']['input'];
  coordinateN: Scalars['Float']['input'];
  deactivationDate?: InputMaybe<Scalars['DateTime']['input']>;
  docs: Array<Scalars['Upload']['input']>;
  installLocation?: InputMaybe<Scalars['String']['input']>;
  installationDate?: InputMaybe<Scalars['DateTime']['input']>;
  isUTM: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  operationalStatus: InstrumentStatusEnum;
  readingType: InstrumentReadingTypeEnum;
  /** instrument type id */
  typeId: Scalars['String']['input'];
};

export type CreateInstrumentWithSectionInput = {
  activationDate?: InputMaybe<Scalars['DateTime']['input']>;
  coordinateE: Scalars['Float']['input'];
  coordinateN: Scalars['Float']['input'];
  deactivationDate?: InputMaybe<Scalars['DateTime']['input']>;
  docs: Array<Scalars['Upload']['input']>;
  installLocation?: InputMaybe<Scalars['String']['input']>;
  installationDate?: InputMaybe<Scalars['DateTime']['input']>;
  isUTM: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  operationalStatus: InstrumentStatusEnum;
  readingType: InstrumentReadingTypeEnum;
  sectionsId?: InputMaybe<Array<Scalars['String']['input']>>;
  /** instrument type id */
  typeId: Scalars['String']['input'];
};

export type CreateParticipantsInput = {
  name: Scalars['String']['input'];
};

export type CreateParticipantsMobileInput = {
  company: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
  signatureImage?: InputMaybe<SaveInspectionImageType>;
};

export type CreatePiezometerAlertLevelInput = {
  coteValue?: InputMaybe<Scalars['Float']['input']>;
  drained?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  undrained?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePiezometerInaAlertLevelsInput = {
  coteValue: Scalars['Float']['input'];
  drained?: InputMaybe<Scalars['String']['input']>;
  instrumentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  undrained?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePiezometerInput = {
  bottomCote: Scalars['Float']['input'];
  /** botton and top cote reference date */
  referenceDate: Scalars['DateTime']['input'];
  topCote: Scalars['Float']['input'];
};

export type CreatePluviometerAndWaterLevelAlertLevelInput = {
  instrumentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type CreateReservoirHandlingInput = {
  reservoirOccupiedArea?: InputMaybe<Scalars['Float']['input']>;
  reservoirOccupiedVolume?: InputMaybe<Scalars['Float']['input']>;
  reservoirTotalVolume?: InputMaybe<Scalars['Float']['input']>;
  tailingVolumeHistory: Array<CreateStructureTailingVolumeInput>;
  waterVolume?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateSectionInput = {
  /** section final coordinate E */
  finalCoordinateE: Scalars['Float']['input'];
  /** section final coordinate N */
  finalCoordinateN: Scalars['Float']['input'];
  /** section initial coordinate E */
  initialCoordinateE: Scalars['Float']['input'];
  /** section initial coordinate N */
  initialCoordinateN: Scalars['Float']['input'];
  isUTM: Scalars['Boolean']['input'];
  /** section name */
  name: Scalars['String']['input'];
  /** structure id */
  structureId: Scalars['String']['input'];
  type: SectionTypeEnum;
};

export type CreateStructureComplexInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  neighborhood: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type CreateStructureDamRaisingInput = {
  date: Scalars['DateTime']['input'];
  elevation: Scalars['Float']['input'];
  method: Scalars['String']['input'];
  stage: Scalars['String']['input'];
};

export type CreateStructureGeometryInput = {
  bermLength?: InputMaybe<Scalars['Float']['input']>;
  crestElevation?: InputMaybe<Scalars['Float']['input']>;
  crestLength?: InputMaybe<Scalars['Float']['input']>;
  crestWidth?: InputMaybe<Scalars['Float']['input']>;
  globalSlopeDownstream?: InputMaybe<Scalars['String']['input']>;
  slopeBetweenBerms?: InputMaybe<Scalars['String']['input']>;
  upstreamSlopeInclination?: InputMaybe<Scalars['String']['input']>;
};

export type CreateStructureHydrologyInput = {
  NAMaximoMaximorum?: InputMaybe<Scalars['Float']['input']>;
  criticalDuration?: InputMaybe<Scalars['Float']['input']>;
  inflowRate?: InputMaybe<Scalars['Float']['input']>;
  outflowRate?: InputMaybe<Scalars['Float']['input']>;
  projectRainfall?: InputMaybe<Scalars['Float']['input']>;
  reservoirContributionArea?: InputMaybe<Scalars['Float']['input']>;
  returnPeriod?: InputMaybe<Scalars['String']['input']>;
  spillwaySill?: InputMaybe<Scalars['Float']['input']>;
  spillwayType?: InputMaybe<Scalars['String']['input']>;
  volumeTotalElevationCrest?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateStructureInput = {
  companyId: Scalars['String']['input'];
  complex?: InputMaybe<CreateStructureComplexInput>;
  isAssociated?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  responsibleId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateStructureModuleInput = {
  /** Installation date */
  end?: InputMaybe<Scalars['DateTime']['input']>;
  moduleId: Scalars['String']['input'];
  /** Installation date */
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateStructureModulesInput = {
  modules?: InputMaybe<Array<CreateStructureModuleInput>>;
  structureId: Scalars['String']['input'];
};

export type CreateStructureRiskAssessmentInput = {
  downstreamEnvironmentalInterest?: InputMaybe<Scalars['String']['input']>;
  downstreamHumanOccupation?: InputMaybe<Scalars['String']['input']>;
  downstreamInstallations?: InputMaybe<Scalars['String']['input']>;
  wasteType?: InputMaybe<Scalars['String']['input']>;
};

export type CreateStructureSummaryInput = {
  ANMClassification: AnmClassificationEnum;
  coordinateE: Scalars['Float']['input'];
  coordinateN: Scalars['Float']['input'];
  datumName: DatumEnum;
  decharacterizationDate?: InputMaybe<Scalars['DateTime']['input']>;
  isUTM: Scalars['Boolean']['input'];
  maxHeight: Scalars['Float']['input'];
  operationEnd?: InputMaybe<Scalars['DateTime']['input']>;
  operationStart: Scalars['DateTime']['input'];
  operationalStatus: Scalars['String']['input'];
  potentialDamage: ClassificationEnum;
  riskClassification: ClassificationEnum;
  structureId: Scalars['String']['input'];
  utmZone: Scalars['String']['input'];
};

export type CreateStructureTailingVolumeInput = {
  date: Scalars['DateTime']['input'];
  volume: Scalars['Float']['input'];
};

export type CreateSurfaceMarkersReadingInput = {
  /** Coordinate E */
  coordinateE?: InputMaybe<Scalars['Float']['input']>;
  /** Coordinate N */
  coordinateN?: InputMaybe<Scalars['Float']['input']>;
  /** Installation date */
  date: Scalars['DateTime']['input'];
  /** Elevation */
  elevation?: InputMaybe<Scalars['Float']['input']>;
  /** InstrumentId */
  instrumentId?: InputMaybe<Scalars['String']['input']>;
  /** InstrumentName */
  instrumentName: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserInput = {
  companyId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  position: Scalars['String']['input'];
  role: UserRoleInput;
};

export type CreateUserNotificationInput = {
  companyId: Scalars['String']['input'];
  criticalities: Array<Scalars['String']['input']>;
  instrumentType: InstrumentTypeEnum;
  receiverId: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
};

export type CreateUserPermissionInput = {
  companyId: Scalars['String']['input'];
  moduleId: Scalars['String']['input'];
  moduleRoleId: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateWaterLevelAlertLevelsInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateWaterMeterReadingInput = {
  date: Scalars['DateTime']['input'];
  /** instrument id */
  instrumentId?: InputMaybe<Scalars['String']['input']>;
  /** instument name */
  instrumentName: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
};

/** Generic data answer */
export type CreatedInspectionGenericDataType = {
  __typename?: 'CreatedInspectionGenericDataType';
  answer: AnswerEnum;
  id: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  title: TitleEnum;
  topic: Scalars['String']['output'];
};

/** massive structure shoulder data */
export type CreatedMassiveStructureShoulderDataType = {
  __typename?: 'CreatedMassiveStructureShoulderDataType';
  genericData: Array<MssGenericDataType>;
  identifiedCracks: Array<MssIdentifiedCracksType>;
  identifiedDeformations: Array<MssIdentifiedDeformationsType>;
  identifiedWaterLeaks: Array<MssIdentifiedWaterLeaksType>;
};

export type CrossSection = {
  __typename?: 'CrossSection';
  /** Cross section id */
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DamRaisingType = {
  __typename?: 'DamRaisingType';
  date?: Maybe<Scalars['DateTime']['output']>;
  elevation: Scalars['Float']['output'];
  /** Dam raising id */
  id: Scalars['String']['output'];
  method: Scalars['String']['output'];
  stage: Scalars['String']['output'];
};

export type DataForSectionChartType = {
  __typename?: 'DataForSectionChartType';
  imageHeight?: Maybe<Scalars['Float']['output']>;
  imageWidth?: Maybe<Scalars['Float']['output']>;
  inferiorPointY?: Maybe<Scalars['Float']['output']>;
  piezometers: Array<PiezometerToChartType>;
  sectionImage?: Maybe<Scalars['String']['output']>;
  sectionName: Scalars['String']['output'];
};

/** Data type image */
export enum DataTypeEnum {
  identifiedCracks = 'identifiedCracks',
  identifiedDeformation = 'identifiedDeformation',
  identifiedWaterLeaks = 'identifiedWaterLeaks',
  inspectionGenericData = 'inspectionGenericData',
  mssGenericData = 'mssGenericData'
}

export enum DatumEnum {
  ASTROCHUA = 'ASTROCHUA',
  CORREGOALEGRE = 'CORREGOALEGRE',
  SAD69 = 'SAD69',
  SIRGAS2000 = 'SIRGAS2000',
  WGS84 = 'WGS84'
}

export type DeleteAtoRecordEquipmentAndLaborInput = {
  id: Scalars['String']['input'];
  type: AtoEquipmentLaborTypeEnum;
};

export type DeleteAtoRecordObservationInput = {
  id: Scalars['String']['input'];
  type: AtoObservationTypeEnum;
};

export type DeleteCompanyInput = {
  id: Scalars['String']['input'];
};

export type DeleteInspectionDataImageInput = {
  dataType: DataTypeEnum;
  id: Scalars['String']['input'];
};

export type DeleteInstrumentAlertLevelInput = {
  alertId: Scalars['String']['input'];
  instrumentId: Scalars['String']['input'];
};

export type DeleteInstrumentReadingInput = {
  instrumentId: Scalars['String']['input'];
  readingId: Scalars['String']['input'];
};

export type DeleteUserInput = {
  id: Scalars['String']['input'];
};

export type DeleteUserPermissionInput = {
  moduleAssignmentId: Scalars['String']['input'];
  structureUserPermissionId: Scalars['String']['input'];
};

export type Displacements = {
  __typename?: 'Displacements';
  coordinateE?: Maybe<Scalars['Float']['output']>;
  coordinateN?: Maybe<Scalars['Float']['output']>;
  elevation?: Maybe<Scalars['Float']['output']>;
};

export type EditCompanyInput = {
  address: CompanyAddressInput;
  cnpj: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type EditGeometryInput = {
  bermLength?: InputMaybe<Scalars['Float']['input']>;
  crestElevation?: InputMaybe<Scalars['Float']['input']>;
  crestLength?: InputMaybe<Scalars['Float']['input']>;
  crestWidth?: InputMaybe<Scalars['Float']['input']>;
  globalSlopeDownstream?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  slopeBetweenBerms?: InputMaybe<Scalars['String']['input']>;
  structureId: Scalars['String']['input'];
  upstreamSlopeInclination?: InputMaybe<Scalars['String']['input']>;
};

export type EditHydrologyInput = {
  NAMaximoMaximorum?: InputMaybe<Scalars['Float']['input']>;
  criticalDuration?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  inflowRate?: InputMaybe<Scalars['Float']['input']>;
  outflowRate?: InputMaybe<Scalars['Float']['input']>;
  projectRainfall?: InputMaybe<Scalars['Float']['input']>;
  reservoirContributionArea?: InputMaybe<Scalars['Float']['input']>;
  returnPeriod?: InputMaybe<Scalars['String']['input']>;
  spillwaySill?: InputMaybe<Scalars['Float']['input']>;
  spillwayType?: InputMaybe<Scalars['String']['input']>;
  structureId: Scalars['String']['input'];
  volumeTotalElevationCrest?: InputMaybe<Scalars['Float']['input']>;
};

export type EditInstrumentAlertInput = {
  alertId: Scalars['String']['input'];
  instrumentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type EditInstrumentWithSectionInput = {
  activationDate?: InputMaybe<Scalars['DateTime']['input']>;
  coordinateE: Scalars['Float']['input'];
  coordinateN: Scalars['Float']['input'];
  deactivationDate?: InputMaybe<Scalars['DateTime']['input']>;
  installLocation?: InputMaybe<Scalars['String']['input']>;
  installationDate?: InputMaybe<Scalars['DateTime']['input']>;
  instrumentId: Scalars['String']['input'];
  isUTM: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  operationalStatus: InstrumentStatusEnum;
  readingType: InstrumentReadingTypeEnum;
  sectionsId: Array<Scalars['String']['input']>;
};

export type EditInstrumentWithoutSectionInput = {
  activationDate?: InputMaybe<Scalars['DateTime']['input']>;
  coordinateE: Scalars['Float']['input'];
  coordinateN: Scalars['Float']['input'];
  deactivationDate?: InputMaybe<Scalars['DateTime']['input']>;
  installLocation?: InputMaybe<Scalars['String']['input']>;
  installationDate?: InputMaybe<Scalars['DateTime']['input']>;
  instrumentId: Scalars['String']['input'];
  isUTM: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  operationalStatus: InstrumentStatusEnum;
  readingType: InstrumentReadingTypeEnum;
};

export type EditPasswordInput = {
  /** Password */
  newPassword: Scalars['String']['input'];
};

export type EditPiezometerAlertInput = {
  alertId: Scalars['String']['input'];
  coteValue: Scalars['Float']['input'];
  drained?: InputMaybe<Scalars['String']['input']>;
  instrumentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  undrained?: InputMaybe<Scalars['String']['input']>;
};

export type EditPiezometerControlCoteInput = {
  bottomCote: Scalars['Float']['input'];
  controlId: Scalars['String']['input'];
  instrumentId: Scalars['String']['input'];
  referenceDate: Scalars['DateTime']['input'];
  topCote: Scalars['Float']['input'];
};

export type EditPiezometerInaInput = {
  generalInfo: EditInstrumentWithSectionInput;
};

export type EditPiezometerInaReadingInput = {
  cote?: InputMaybe<Scalars['Float']['input']>;
  /** Installation date */
  date: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  isDry?: InputMaybe<Scalars['Boolean']['input']>;
  observation?: InputMaybe<Scalars['String']['input']>;
  reading?: InputMaybe<Scalars['Float']['input']>;
};

export type EditPiezometerInaReadingValuesInput = {
  /** reading cote */
  cote?: InputMaybe<Scalars['Float']['input']>;
  /** reading date */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  /** reading id */
  id: Scalars['String']['input'];
  /** reading is Dry */
  isDry: Scalars['Boolean']['input'];
  /** reading */
  reading: Scalars['Float']['input'];
  /** structure id */
  structureId: Scalars['String']['input'];
  /** structure type: main or associated */
  structureType: Scalars['String']['input'];
};

export type EditPluviometerInput = {
  generalInfo: EditInstrumentWithoutSectionInput;
  specificInfo: PluviometerInput;
};

export type EditPluviometerReadingInput = {
  /** date */
  date: Scalars['DateTime']['input'];
  /** reading id */
  id: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  /** rainfall in mm */
  rainfall: Scalars['Float']['input'];
};

export type EditReservoirHandlingInput = {
  id: Scalars['String']['input'];
  reservoirOccupiedArea?: InputMaybe<Scalars['Float']['input']>;
  reservoirOccupiedVolume?: InputMaybe<Scalars['Float']['input']>;
  reservoirTotalVolume?: InputMaybe<Scalars['Float']['input']>;
  structureId?: InputMaybe<Scalars['String']['input']>;
  waterVolume?: InputMaybe<Scalars['Float']['input']>;
};

export type EditRiskAssessmentInput = {
  downstreamEnvironmentalInterest?: InputMaybe<Scalars['String']['input']>;
  downstreamHumanOccupation?: InputMaybe<Scalars['String']['input']>;
  downstreamInstallations?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
  wasteType?: InputMaybe<Scalars['String']['input']>;
};

export type EditSectionInput = {
  finalCoordinateE: Scalars['Float']['input'];
  finalCoordinateN: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  initialCoordinateE: Scalars['Float']['input'];
  initialCoordinateN: Scalars['Float']['input'];
  isUTM: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
  type: SectionTypeEnum;
};

export type EditStructureInput = {
  complex?: InputMaybe<CreateStructureComplexInput>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type EditStructureModulesInput = {
  /** End date */
  end?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  /** Start date */
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EditStructureSummaryInput = {
  ANMClassification: AnmClassificationEnum;
  coordinateE: Scalars['Float']['input'];
  coordinateN: Scalars['Float']['input'];
  datumName: DatumEnum;
  decharacterizationDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  isUTM: Scalars['Boolean']['input'];
  maxHeight: Scalars['Float']['input'];
  operationEnd?: InputMaybe<Scalars['DateTime']['input']>;
  operationStart: Scalars['DateTime']['input'];
  operationalStatus: Scalars['String']['input'];
  potentialDamage: ClassificationEnum;
  riskClassification: ClassificationEnum;
  structureId: Scalars['String']['input'];
  utmZone: Scalars['String']['input'];
};

export type EditSurfaceMarkerAlertLevelInput = {
  alertId: Scalars['String']['input'];
  coordinateE?: InputMaybe<Scalars['Float']['input']>;
  coordinateN?: InputMaybe<Scalars['Float']['input']>;
  date: Scalars['DateTime']['input'];
  elevation?: InputMaybe<Scalars['Float']['input']>;
  instrumentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type EditSurfaceMarkerInput = {
  generalInfo: EditInstrumentWithSectionInput;
};

export type EditSurfaceMarkerReferenceInput = {
  coordinateE?: InputMaybe<Scalars['Float']['input']>;
  coordinateN?: InputMaybe<Scalars['Float']['input']>;
  date: Scalars['DateTime']['input'];
  elevation?: InputMaybe<Scalars['Float']['input']>;
  instrumentId: Scalars['String']['input'];
  referenceId: Scalars['String']['input'];
};

export type EditSurfaceMarkersReadingInput = {
  /** Coordinate E */
  coordinateE?: InputMaybe<Scalars['Float']['input']>;
  /** Coordinate N */
  coordinateN?: InputMaybe<Scalars['Float']['input']>;
  /** Installation date */
  date: Scalars['DateTime']['input'];
  /** Elevation */
  elevation: Scalars['Float']['input'];
  /** Reading id */
  id: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
};

export type EditTailingVolumeInput = {
  date: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
  volume: Scalars['Float']['input'];
};

export type EditUserInput = {
  companyId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  position: Scalars['String']['input'];
  role: UserRoleInput;
};

export type EditUserPermissionInput = {
  moduleAssignmentId: Scalars['String']['input'];
  moduleRoleId: Scalars['String']['input'];
  structureUserPermissionId: Scalars['String']['input'];
};

export type EditWaterLevelInput = {
  generalInfo: EditInstrumentWithoutSectionInput;
};

export type EditWaterLevelReadingInput = {
  date: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
};

/** Psb file */
export type FileType = {
  __typename?: 'FileType';
  date: Scalars['DateTime']['output'];
  file: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FilterInput = {
  /** End date */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Instruments id */
  instrumentsId?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ReadingType */
  readingType?: InputMaybe<InstrumentReadingTypeEnum>;
  /** Start date */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Status */
  status?: InputMaybe<InstrumentStatusEnum>;
};

export type FindAllAtoType = {
  __typename?: 'FindAllAtoType';
  canFinish: Scalars['Boolean']['output'];
  client: BaseIdNameType;
  clientApprover?: Maybe<BaseIdNameType>;
  contract: Scalars['String']['output'];
  docs: Array<BaseIdNameType>;
  finalDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  initialDate: Scalars['DateTime']['output'];
  responsibleCompany: BaseIdNameType;
  situation: AtoSituationEnum;
  title: Scalars['String']['output'];
  verifier: BaseIdNameType;
};

export type FindAllRecordsType = {
  __typename?: 'FindAllRecordsType';
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  recordImagesCount: Scalars['Float']['output'];
  recordNumber: Scalars['String']['output'];
  recordVideosCount: Scalars['Float']['output'];
  situation: AtoSituationEnum;
};

export type FindDataByRecordType = {
  __typename?: 'FindDataByRecordType';
  activities: Array<AtoRecordActivitiesDataType>;
  clientLogo?: Maybe<Scalars['String']['output']>;
  contract: Scalars['String']['output'];
  contractualPeriod: Scalars['String']['output'];
  elaborator?: Maybe<Scalars['String']['output']>;
  elapsedTerm: Scalars['String']['output'];
  equipments: Array<AtoRecordEquipmentAndLaborDataType>;
  inspectionObservations: Array<AtoRecordObservationsType>;
  labor: Array<AtoRecordEquipmentAndLaborDataType>;
  recordDate: Scalars['DateTime']['output'];
  recordImages: Array<AtoRecordImagesType>;
  recordNumber: Scalars['String']['output'];
  recordSignatures: Array<AtoRecordSignaturesDataType>;
  recordVideos: Array<AtoRecordVideoType>;
  redAlertPeriods: Array<AtoRecordRedAlertPeriodType>;
  referencesDocs: Array<AtoReferencesDocsType>;
  remainingTerm: Scalars['String']['output'];
  responsibleCompany: Scalars['String']['output'];
  responsibleCompanyLogo?: Maybe<Scalars['String']['output']>;
  responsibleCompanyObservations: Array<AtoRecordObservationsType>;
  situation: AtoSituationEnum;
  title: Scalars['String']['output'];
  userTypes: Array<AtoUserTypesType>;
  weatherConditions: Array<AtoRecordWeatherConditionsType>;
  workSafetyObservations: Array<AtoRecordObservationsType>;
  workShifts: Array<AtoRecordWorkShiftType>;
};

export type FindGeneralInfoType = {
  __typename?: 'FindGeneralInfoType';
  activitiesCount: Scalars['Float']['output'];
  contract: Scalars['String']['output'];
  contractualPeriod: Scalars['String']['output'];
  finalDate: Scalars['DateTime']['output'];
  imagesCount: Scalars['Float']['output'];
  initialDate: Scalars['DateTime']['output'];
  observationsCount: Scalars['Float']['output'];
  occurrencesCount: Scalars['Float']['output'];
  recentPhotos: Array<Scalars['String']['output']>;
  recentRecords: Array<RecentRecordsType>;
  recentVideos: Array<Scalars['String']['output']>;
  recordsCount: Scalars['Float']['output'];
  remainingTerm: Scalars['String']['output'];
  situation: AtoSituationEnum;
  title: Scalars['String']['output'];
  videosCount: Scalars['Float']['output'];
};

export type FindGenericDataInput = {
  dataType: TitleEnum;
  inspectionId: Scalars['String']['input'];
};

export type GenericDisplacementSurfaceDataType = {
  __typename?: 'GenericDisplacementSurfaceDataType';
  date: Scalars['DateTime']['output'];
  displacement?: Maybe<Scalars['Float']['output']>;
};

export type GenericSurfaceMarkerReadingType = {
  __typename?: 'GenericSurfaceMarkerReadingType';
  date: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

/** Gistm comments */
export type GistmCommentsType = {
  __typename?: 'GistmCommentsType';
  comment: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

/** Gistm data */
export type GistmDataType = {
  __typename?: 'GistmDataType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nested?: Maybe<Array<GistmDataType>>;
  path: Scalars['String']['output'];
};

/** Gistm file */
export type GistmFileType = {
  __typename?: 'GistmFileType';
  createdAt: Scalars['DateTime']['output'];
  file: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

/** if the anomaly is an depression/sagging or deformation */
export enum IdenfiedAnomaliesEnum {
  Deformation = 'Deformation',
  DepressionSagging = 'DepressionSagging'
}

export type InfosInstrumentsType = {
  __typename?: 'InfosInstrumentsType';
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  operationalStatus: InstrumentStatusEnum;
};

/** inspection generic data */
export type InspectionConservationStateType = {
  __typename?: 'InspectionConservationStateType';
  id: Scalars['String']['output'];
  note: ConservationStateNoteEnum;
  title: ConservationStateTitleEnum;
  topic: Scalars['String']['output'];
};

/** identified deformations data with images */
export type InspectionDataImageType = {
  __typename?: 'InspectionDataImageType';
  date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Boolean']['output'];
};

/** inspection data */
export type InspectionDataType = {
  __typename?: 'InspectionDataType';
  accessSituation: Array<InspectionGenericDataType>;
  conservationState: Array<InspectionConservationStateType>;
  extravasator: Array<InspectionGenericDataType>;
  instrumentation: Array<InspectionGenericDataType>;
  massiveStructureShoulder: MassiveStructureShoulderDataType;
  reservoir: Array<InspectionGenericDataType>;
  waterWastePumpingPiping: Array<InspectionGenericDataType>;
};

/** inspection generic data */
export type InspectionGenericDataType = {
  __typename?: 'InspectionGenericDataType';
  answer: AnswerEnum;
  id: Scalars['String']['output'];
  images?: Maybe<Array<InspectionDataImageType>>;
  observation?: Maybe<Scalars['String']['output']>;
  title: TitleEnum;
  topic: Scalars['String']['output'];
};

/** Participants */
export type InspectionParticipantsType = {
  __typename?: 'InspectionParticipantsType';
  company?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

/** Inspections */
export type InspectionsType = {
  __typename?: 'InspectionsType';
  conservationStateCalc?: Maybe<Scalars['Float']['output']>;
  date: Scalars['DateTime']['output'];
  generalComments?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  participants: Array<InspectionParticipantsType>;
  responsible: Scalars['String']['output'];
  structureId: Scalars['String']['output'];
  systemId: Scalars['Float']['output'];
  systemPdf?: Maybe<Scalars['String']['output']>;
  userPdf?: Maybe<Scalars['String']['output']>;
};

export type InstrumentDocsType = {
  __typename?: 'InstrumentDocsType';
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type InstrumentInfoByUserStructureType = {
  __typename?: 'InstrumentInfoByUserStructureType';
  ina: PiezometerInaInfosType;
  piezometer: PiezometerInaInfosType;
  pluviometer: InstrumentInfosType;
  structureId: Scalars['String']['output'];
  structureName: Scalars['String']['output'];
  surfaceMarker: InstrumentInfosType;
  waterLevel: InstrumentInfosType;
};

export type InstrumentInfosType = {
  __typename?: 'InstrumentInfosType';
  inAlert: Scalars['Float']['output'];
  instruments: Array<LastInstrumentReadingType>;
  quantity: Scalars['Float']['output'];
};

/** ReadingType */
export enum InstrumentReadingTypeEnum {
  Automatic = 'Automatic',
  Manual = 'Manual'
}

/** InstrumentStatusEnum: Active or Inactive */
export enum InstrumentStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type InstrumentType = {
  __typename?: 'InstrumentType';
  id: Scalars['String']['output'];
  instrumentType: Scalars['String']['output'];
};

/** Instrument types */
export enum InstrumentTypeEnum {
  Ina = 'Ina',
  Piezometer = 'Piezometer',
  Pluviometer = 'Pluviometer',
  SurfaceMarker = 'SurfaceMarker',
  WaterLevel = 'WaterLevel'
}

export type InstrumentsParameterToChartType = {
  id: Scalars['String']['input'];
  x: Scalars['Float']['input'];
};

export type LastInstrumentReadingType = {
  __typename?: 'LastInstrumentReadingType';
  lastReading?: Maybe<LastReadingType>;
  name: Scalars['String']['output'];
};

export type LastPiezometerInaReadingType = {
  __typename?: 'LastPiezometerInaReadingType';
  lastReading?: Maybe<PiezometerInaLastReadingType>;
  name: Scalars['String']['output'];
};

export type LastReadingType = {
  __typename?: 'LastReadingType';
  date: Scalars['DateTime']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  reading?: Maybe<Scalars['Float']['output']>;
  readingId: Scalars['String']['output'];
};

export type LatestUserNotificationType = {
  __typename?: 'LatestUserNotificationType';
  alertLevel: Scalars['String']['output'];
  installLocation?: Maybe<Scalars['String']['output']>;
  instrumentNotificationId: Scalars['String']['output'];
  readingDate: Scalars['DateTime']['output'];
  sentAt: Scalars['DateTime']['output'];
  structureName: Scalars['String']['output'];
  unread: Scalars['Boolean']['output'];
};

export type ListActionPlanPagType = {
  __typename?: 'ListActionPlanPagType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  priority?: Maybe<ActionPlanPriorityEnum>;
  responsibleName?: Maybe<Scalars['String']['output']>;
  situation: ActionPlanSituationEnum;
  timeFrame?: Maybe<Scalars['DateTime']['output']>;
  type: ActionPlanTypeEnum;
};

export type ListAllActionPlansToExportTableType = {
  __typename?: 'ListAllActionPlansToExportTableType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  priority?: Maybe<ActionPlanPriorityEnum>;
  responsibleName?: Maybe<Scalars['String']['output']>;
  situation: ActionPlanSituationEnum;
  teamMembersName: Array<Scalars['String']['output']>;
  timeFrame?: Maybe<Scalars['DateTime']['output']>;
  type: ActionPlanTypeEnum;
};

export type ListAllAssociatedActionPlansType = {
  __typename?: 'ListAllAssociatedActionPlansType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images: Array<Scalars['String']['output']>;
};

export type ListAllInstrumentsType = {
  __typename?: 'ListAllInstrumentsType';
  sectionsList: Array<ListInstrumentsBySectionsType>;
  typesList: Array<ListInstrumentsByTypeType>;
};

export type ListAllPiezometerReadingType = {
  __typename?: 'ListAllPiezometerReadingType';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  bottomCote?: Maybe<Scalars['Float']['output']>;
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  cote?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  installLocation?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['DateTime']['output']>;
  instrumentId: Scalars['String']['output'];
  isDry: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  operationalStatus: InstrumentStatusEnum;
  reading?: Maybe<Scalars['Float']['output']>;
  readingId: Scalars['String']['output'];
  readingType: InstrumentReadingTypeEnum;
  topCote?: Maybe<Scalars['Float']['output']>;
};

export type ListAllPluviometerReadingType = {
  __typename?: 'ListAllPluviometerReadingType';
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  date: Scalars['DateTime']['output'];
  installLocation?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  operationalStatus: InstrumentStatusEnum;
  rainfall: Scalars['Float']['output'];
  readingType: InstrumentReadingTypeEnum;
  structureType?: Maybe<Scalars['String']['output']>;
};

export type ListAllSurfaceMarkerReadingType = {
  __typename?: 'ListAllSurfaceMarkerReadingType';
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  displacements: Displacements;
  installLocation?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  operationalStatus: InstrumentStatusEnum;
  reading: BaseSurfaceMarkersReadingType;
  readingType: Scalars['String']['output'];
  readingsType?: Maybe<InstrumentReadingTypeEnum>;
  structureType?: Maybe<Scalars['String']['output']>;
};

/** Water level infos to use on download data */
export type ListAllWaterLevelReadingType = {
  __typename?: 'ListAllWaterLevelReadingType';
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  date: Scalars['DateTime']['output'];
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  operationalStatus: InstrumentStatusEnum;
  readingType?: Maybe<Scalars['String']['output']>;
  structureType?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type ListCompaniesInput = {
  listAllCompanies: Scalars['Boolean']['input'];
};

export type ListCompanyRelatedUsersAndStructuresInput = {
  id: Scalars['String']['input'];
};

export type ListControlQuotasType = {
  __typename?: 'ListControlQuotasType';
  bottomCote: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  referenceDate: Scalars['DateTime']['output'];
  topCote: Scalars['Float']['output'];
};

/** List Gistm comments */
export type ListGistmCommentsType = {
  __typename?: 'ListGistmCommentsType';
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type ListInstrumentsBySectionsType = {
  __typename?: 'ListInstrumentsBySectionsType';
  finalCoordinateE: Scalars['Float']['output'];
  finalCoordinateN: Scalars['Float']['output'];
  finalLat: Scalars['Float']['output'];
  finalLon: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  initialCoordinateE: Scalars['Float']['output'];
  initialCoordinateN: Scalars['Float']['output'];
  initialLat: Scalars['Float']['output'];
  initialLon: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  typesList: Array<ListInstrumentsByTypeType>;
};

export type ListInstrumentsByTypeType = {
  __typename?: 'ListInstrumentsByTypeType';
  instruments: Array<InfosInstrumentsType>;
  totalActive: Scalars['Float']['output'];
  totalInactive: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

export type ListLatestPiezometerReadingType = {
  __typename?: 'ListLatestPiezometerReadingType';
  alertLevels?: Maybe<Scalars['String']['output']>;
  cote: Scalars['Float']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  operationalStatus: Scalars['String']['output'];
};

export type ListLatestPluviometerReadingType = {
  __typename?: 'ListLatestPluviometerReadingType';
  alertLevels?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  operationalStatus: InstrumentStatusEnum;
  rainfall: Scalars['Float']['output'];
};

export type ListLatestSurfaceMarkerReadingType = {
  __typename?: 'ListLatestSurfaceMarkerReadingType';
  alertLevels?: Maybe<Scalars['String']['output']>;
  coordinateE?: Maybe<Scalars['Float']['output']>;
  coordinateN?: Maybe<Scalars['Float']['output']>;
  date: Scalars['DateTime']['output'];
  elevation?: Maybe<Scalars['Float']['output']>;
  instrumentId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  operationalStatus: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ListLatestUserNotificationType = {
  __typename?: 'ListLatestUserNotificationType';
  latestNotifications: Array<LatestUserNotificationType>;
  unreadCount: Scalars['Float']['output'];
};

export type ListLatestWaterLevelReadingType = {
  __typename?: 'ListLatestWaterLevelReadingType';
  alertLevels?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  operationalStatus: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type ListModuleNotificationsType = {
  __typename?: 'ListModuleNotificationsType';
  count: Scalars['Int']['output'];
  module: ModuleEnum;
  moduleId: Scalars['String']['output'];
};

export type ListPiezometerInaAlertLevelsType = {
  __typename?: 'ListPiezometerInaAlertLevelsType';
  coteValue: Scalars['Float']['output'];
  drained?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  undrained?: Maybe<Scalars['String']['output']>;
};

export type ListPiezometerInaByStructure = {
  __typename?: 'ListPiezometerInaByStructure';
  activationDate?: Maybe<Scalars['String']['output']>;
  bottomCote?: Maybe<Scalars['Float']['output']>;
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  deactivationDate?: Maybe<Scalars['String']['output']>;
  depth?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  installLocation?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['String']['output']>;
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  operationalStatus: InstrumentStatusEnum;
  readingType: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  topCote?: Maybe<Scalars['Float']['output']>;
  type: BasicInstrumentTypeType;
};

export type ListPiezometerInaReadingType = {
  __typename?: 'ListPiezometerInaReadingType';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  bottomCote?: Maybe<Scalars['Float']['output']>;
  coordinateE?: Maybe<Scalars['Float']['output']>;
  coordinateN?: Maybe<Scalars['Float']['output']>;
  cote?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  installLocation?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['DateTime']['output']>;
  instrumentId: Scalars['String']['output'];
  isDry?: Maybe<Scalars['Boolean']['output']>;
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  operationalStatus: InstrumentStatusEnum;
  reading?: Maybe<Scalars['Float']['output']>;
  readingId: Scalars['String']['output'];
  readingType: Scalars['String']['output'];
  structureType?: Maybe<Scalars['String']['output']>;
  topCote?: Maybe<Scalars['Float']['output']>;
};

export type ListSectionsType = {
  __typename?: 'ListSectionsType';
  finalCoordinateE: Scalars['Float']['output'];
  finalCoordinateN: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  initialCoordinateE: Scalars['Float']['output'];
  initialCoordinateN: Scalars['Float']['output'];
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  structureName: Scalars['String']['output'];
  type: SectionTypeEnum;
};

export type ListStructureModulesType = {
  __typename?: 'ListStructureModulesType';
  end: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  moduleName: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
  status: Scalars['Boolean']['output'];
  structureName: Scalars['String']['output'];
};

export type ListStructureSummary = {
  __typename?: 'ListStructureSummary';
  ANMClassification: AnmClassificationEnum;
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  datumName: DatumEnum;
  decharacterizationDate?: Maybe<Scalars['DateTime']['output']>;
  /** Summary id */
  id: Scalars['String']['output'];
  isUTM: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  maxHeight?: Maybe<Scalars['Float']['output']>;
  operationEnd?: Maybe<Scalars['DateTime']['output']>;
  operationStart?: Maybe<Scalars['DateTime']['output']>;
  operationalStatus?: Maybe<Scalars['String']['output']>;
  potentialDamage?: Maybe<ClassificationEnum>;
  riskClassification: ClassificationEnum;
  utmZone?: Maybe<Scalars['String']['output']>;
};

export type ListStructuresType = {
  __typename?: 'ListStructuresType';
  companyName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  structureId: Scalars['String']['output'];
};

export type ListSubscribedUserNotificationFIltersInput = {
  criticalities?: InputMaybe<Array<Scalars['String']['input']>>;
  instrumentType?: InputMaybe<InstrumentTypeEnum>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type ListSubscribedUserNotificationType = {
  __typename?: 'ListSubscribedUserNotificationType';
  criticality: Scalars['String']['output'];
  id: Scalars['String']['output'];
  instrumentType: InstrumentTypeEnum;
  status: Scalars['Boolean']['output'];
  user: Scalars['String']['output'];
};

export type ListSurfaceMarkerAlertLevelType = {
  __typename?: 'ListSurfaceMarkerAlertLevelType';
  coordinateE?: Maybe<Scalars['Float']['output']>;
  coordinateN?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  elevation?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** List surface markers */
export type ListSurfaceMarkerType = {
  __typename?: 'ListSurfaceMarkerType';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  installLocation?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['DateTime']['output']>;
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  operationalStatus: InstrumentStatusEnum;
  readingType: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  surfaceMarker?: Maybe<SurfaceMarkerType>;
  type?: Maybe<InstrumentType>;
};

export type ListSurfaceMarkersReferenceType = {
  __typename?: 'ListSurfaceMarkersReferenceType';
  coordinateE?: Maybe<Scalars['Float']['output']>;
  coordinateN?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  elevation?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
};

export type ListUserPermissionType = {
  __typename?: 'ListUserPermissionType';
  companyName: Scalars['String']['output'];
  moduleAssignmentId: Scalars['String']['output'];
  moduleName: ModuleEnum;
  moduleRoleName: ModuleRoleEnum;
  structureName: Scalars['String']['output'];
  structureUserPermissionId: Scalars['String']['output'];
  userEmail: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type ListUsersInput = {
  listAllUsers: Scalars['Boolean']['input'];
};

/** Login response object */
export type Login = {
  __typename?: 'Login';
  hasAcceptedTerms: Scalars['Boolean']['output'];
  isLoggedWithTemporaryPassword: Scalars['Boolean']['output'];
  /** JWT token */
  token: Scalars['String']['output'];
  /** User */
  user: User;
};

/** Infos para realizar login */
export type LoginInput = {
  /** E-mail */
  email: Scalars['String']['input'];
  /** Password */
  password: Scalars['String']['input'];
};

export type LongitudinalSection = {
  __typename?: 'LongitudinalSection';
  /** Longitudinal section id */
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** inspection massive structure shoulder data */
export type MassiveStructureShoulderDataType = {
  __typename?: 'MassiveStructureShoulderDataType';
  coating?: Maybe<MssGenericDataWithoutTopicType>;
  identifiedCracks?: Maybe<Array<MssIdentifiedCracksWithImageType>>;
  identifiedDeformations?: Maybe<Array<MssIdentifiedDeformationsWithImageType>>;
  identifiedWaterLeaks?: Maybe<Array<MssIdentifiedWaterLeaksWithImageType>>;
  internalDrainage?: Maybe<Array<MssGenericDataWithTopicType>>;
  presenceOfTreesAnimalsInsects?: Maybe<Array<MssGenericDataWithTopicType>>;
  surfaceDrainage?: Maybe<Array<MssGenericDataWithTopicType>>;
  surfaceErosions?: Maybe<MssGenericDataWithoutTopicType>;
};

/** List structures and modules that exists on that structure, also list the most recent two inspections */
export type MobInitialInspectionDataModelType = {
  __typename?: 'MobInitialInspectionDataModelType';
  id: Scalars['String']['output'];
  modules: Array<MobModuleInfoType>;
  name: Scalars['String']['output'];
};

/** List module id and module name, and the last data from the inspection data */
export type MobInspectionDataModelType = {
  __typename?: 'MobInspectionDataModelType';
  data?: Maybe<InspectionDataType>;
  date: Scalars['String']['output'];
  generalComments?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  participants: Array<InspectionParticipantsType>;
  responsible: ResponsibleType;
};

/** List module id and module name, and the last data from the inspection data */
export type MobModuleInfoType = {
  __typename?: 'MobModuleInfoType';
  id: Scalars['String']['output'];
  lastData?: Maybe<Array<MobInspectionDataModelType>>;
  name: Scalars['String']['output'];
};

/** modules */
export enum ModuleEnum {
  ATO = 'ATO',
  ActionPlan = 'ActionPlan',
  DamBreak = 'DamBreak',
  Dashboard = 'Dashboard',
  EoR = 'EoR',
  GISTM = 'GISTM',
  General = 'General',
  Inspections = 'Inspections',
  Monitoring = 'Monitoring',
  PAEBM = 'PAEBM',
  PSB = 'PSB',
  Report = 'Report',
  RisckAnalisys = 'RisckAnalisys',
  Stability = 'Stability'
}

export type ModuleFlagType = {
  __typename?: 'ModuleFlagType';
  id: Scalars['String']['output'];
  name: ModuleEnum;
  role?: Maybe<ModuleRoleEnum>;
};

/** module roles */
export enum ModuleRoleEnum {
  Admin = 'Admin',
  Editor = 'Editor',
  Forbidden = 'Forbidden',
  Viewer = 'Viewer'
}

export type ModuleRoleType = {
  __typename?: 'ModuleRoleType';
  id: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

export type ModuleType = {
  __typename?: 'ModuleType';
  id: Scalars['String']['output'];
  index: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type ModulesTypes = {
  __typename?: 'ModulesTypes';
  active: Scalars['Boolean']['output'];
  /** module end */
  end: Scalars['DateTime']['output'];
  module: ModuleEnum;
  /** module id */
  moduleId: Scalars['ID']['output'];
  /** module start */
  start: Scalars['DateTime']['output'];
};

/** mass generic data */
export type MssGenericBaseDataType = {
  __typename?: 'MssGenericBaseDataType';
  answer: AnswerEnum;
  id: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  title: MssGenericDataTitleEnum;
};

export type MssGenericDataInput = {
  answer: AnswerEnum;
  observation?: InputMaybe<Scalars['String']['input']>;
  title: MssGenericDataTitleEnum;
  topic?: InputMaybe<Scalars['String']['input']>;
};

/** massive structure shoulder titles */
export enum MssGenericDataTitleEnum {
  Coating = 'Coating',
  InternalDrainage = 'InternalDrainage',
  PresenceOfTreesAnimalsInsects = 'PresenceOfTreesAnimalsInsects',
  SurfaceDrainage = 'SurfaceDrainage',
  SurfaceErosions = 'SurfaceErosions'
}

/** mass generic data */
export type MssGenericDataType = {
  __typename?: 'MssGenericDataType';
  answer: AnswerEnum;
  id: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  title: MssGenericDataTitleEnum;
  topic?: Maybe<Scalars['String']['output']>;
};

export type MssGenericDataWithImagesInput = {
  answer: AnswerEnum;
  images?: InputMaybe<Array<SaveInspectionImageType>>;
  observation?: InputMaybe<Scalars['String']['input']>;
  title: MssGenericDataTitleEnum;
  topic?: InputMaybe<Scalars['String']['input']>;
};

/** mass generic data with topic */
export type MssGenericDataWithTopicType = {
  __typename?: 'MssGenericDataWithTopicType';
  answer: AnswerEnum;
  id: Scalars['String']['output'];
  images?: Maybe<Array<InspectionDataImageType>>;
  observation?: Maybe<Scalars['String']['output']>;
  title: MssGenericDataTitleEnum;
  topic: Scalars['String']['output'];
};

/** mass generic data without topic */
export type MssGenericDataWithoutTopicType = {
  __typename?: 'MssGenericDataWithoutTopicType';
  answer: AnswerEnum;
  id: Scalars['String']['output'];
  images?: Maybe<Array<InspectionDataImageType>>;
  observation?: Maybe<Scalars['String']['output']>;
  title: MssGenericDataTitleEnum;
};

export type MssIdentifiedCracksInput = {
  length: Scalars['String']['input'];
  longitudinalTransverse: Scalars['String']['input'];
  name: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  opening: Scalars['String']['input'];
};

/** identified cracks data */
export type MssIdentifiedCracksType = {
  __typename?: 'MssIdentifiedCracksType';
  id: Scalars['String']['output'];
  length: Scalars['String']['output'];
  longitudinalTransverse: Scalars['String']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  opening: Scalars['String']['output'];
};

/** identified cracks data with images */
export type MssIdentifiedCracksWithImageType = {
  __typename?: 'MssIdentifiedCracksWithImageType';
  id: Scalars['String']['output'];
  images?: Maybe<Array<InspectionDataImageType>>;
  length: Scalars['String']['output'];
  longitudinalTransverse: Scalars['String']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  opening: Scalars['String']['output'];
};

export type MssIdentifiedCracksWithImagesInput = {
  images?: InputMaybe<Array<SaveInspectionImageType>>;
  length: Scalars['String']['input'];
  longitudinalTransverse: Scalars['String']['input'];
  name: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  opening: Scalars['String']['input'];
};

export type MssIdentifiedDeformationsInput = {
  identifiedAnomaly: IdenfiedAnomaliesEnum;
  local: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
};

/** identified deformations data */
export type MssIdentifiedDeformationsType = {
  __typename?: 'MssIdentifiedDeformationsType';
  id: Scalars['String']['output'];
  identifiedAnomaly: Scalars['String']['output'];
  local: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
};

/** identified deformations data with images */
export type MssIdentifiedDeformationsWithImageType = {
  __typename?: 'MssIdentifiedDeformationsWithImageType';
  id: Scalars['String']['output'];
  identifiedAnomaly: Scalars['String']['output'];
  images?: Maybe<Array<InspectionDataImageType>>;
  local: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
};

export type MssIdentifiedDeformationsWithImagesInput = {
  identifiedAnomaly: IdenfiedAnomaliesEnum;
  images?: InputMaybe<Array<SaveInspectionImageType>>;
  local: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
};

export type MssIdentifiedWaterLeaksInput = {
  local: Scalars['String']['input'];
  name: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  visibleSolidTransport: Scalars['String']['input'];
};

/** identified water leaks data */
export type MssIdentifiedWaterLeaksType = {
  __typename?: 'MssIdentifiedWaterLeaksType';
  id: Scalars['String']['output'];
  local: Scalars['String']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  visibleSolidTransport: Scalars['String']['output'];
};

/** identified water leaks data with images */
export type MssIdentifiedWaterLeaksWithImageType = {
  __typename?: 'MssIdentifiedWaterLeaksWithImageType';
  id: Scalars['String']['output'];
  images?: Maybe<Array<InspectionDataImageType>>;
  local: Scalars['String']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  visibleSolidTransport: Scalars['String']['output'];
};

export type MssIdentifiedWaterLeaksWithImagesInput = {
  images?: InputMaybe<Array<SaveInspectionImageType>>;
  local: Scalars['String']['input'];
  name: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  visibleSolidTransport: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept terms */
  acceptTerms: Scalars['String']['output'];
  /** Approve an action plan */
  actionPlanApprovalFlow: Scalars['String']['output'];
  /** Reprove an action plan */
  actionPlanReprovalFlow: Scalars['String']['output'];
  /** Add identified crack */
  addIdentifiedCrack: Scalars['String']['output'];
  /** Add identified deformation */
  addIdentifiedDeformation: Scalars['String']['output'];
  /** Add identified water leak */
  addIdentifiedWaterLeak: Scalars['String']['output'];
  /** Add new participants */
  addParticipants: Scalars['String']['output'];
  /** Add piezometer ina alert levels */
  addPiezometerInaAlertLevels: Scalars['String']['output'];
  /** Add pluviometer alert levels */
  addPluviometerAlertLevels: Scalars['String']['output'];
  /** Create structure dam tailing volume */
  addStructureDamTailingVolume: Scalars['String']['output'];
  /** Add surface markers alert levels */
  addSurfaceMarkerAlertLevels: Scalars['String']['output'];
  /** Add water level alert levels */
  addWaterLevelAlertLevels: Scalars['String']['output'];
  /** Bind an action plan with its parent */
  associateActionPlans: Scalars['String']['output'];
  /** Create an action plan */
  createActionPlan: Scalars['String']['output'];
  /** Create ATO initial ATO records data */
  createAto: Scalars['String']['output'];
  /** Create company */
  createCompany: Scalars['String']['output'];
  /** create inspection */
  createInspection: Scalars['String']['output'];
  /** create piezometer */
  createPiezometer: Scalars['String']['output'];
  /** create piezometer reading */
  createPiezometerReading: Array<ValidateReadingValuesInfosModelType>;
  /** create a pluviometer */
  createPluviometer: Scalars['String']['output'];
  /** add reading value for a pluviometer */
  createPluviometerReading: Array<ValidateReadingValuesInfosModelType>;
  /** Create section */
  createSection: Scalars['String']['output'];
  /** Create structure */
  createStructure: Scalars['String']['output'];
  /** Create structure dam raising */
  createStructureDamRaising: Scalars['String']['output'];
  /** Create structure massive geometry */
  createStructureGeometry: Scalars['String']['output'];
  /** Create structure hydrology */
  createStructureHydrology: Scalars['String']['output'];
  /** Create structure modules */
  createStructureModules: Scalars['String']['output'];
  /** Create structure reservoir handling */
  createStructureReservoirHandling: Scalars['String']['output'];
  /** Create structure risk assessment */
  createStructureRiskAssessment: Scalars['String']['output'];
  /** Create structure summary */
  createStructureSummary: Scalars['String']['output'];
  /** create a surface marker instrument */
  createSurfaceMarker: Scalars['String']['output'];
  /** create a surface marker instrument */
  createSurfaceMarkersReading: Array<ValidateReadingValuesInfosModelType>;
  /** Create user */
  createUser: Scalars['String']['output'];
  /** Create user notification */
  createUserInstrumentNotification: Scalars['String']['output'];
  /** Create user permission */
  createUserPermission: Scalars['String']['output'];
  /** create a water level reading */
  createWaterLevel: Scalars['String']['output'];
  /** create a water level instrument */
  createWaterLevelReading: Array<ValidateReadingValuesInfosModelType>;
  /** Delete a comment from an action plan */
  deleteActionPlanComment: Scalars['String']['output'];
  /** Remove a member from an action plan */
  deleteActionPlanTeamMember: Scalars['String']['output'];
  /** delete documents from ATO */
  deleteAtoDocs: Scalars['String']['output'];
  /** Delete company */
  deleteCompany: Scalars['String']['output'];
  /** Delete company logo */
  deleteCompanyLogo: Scalars['String']['output'];
  /** delete a gistm comment */
  deleteGistmComment: Scalars['String']['output'];
  /** Delete a gistm file */
  deleteGistmFile: Scalars['String']['output'];
  /** Delete identified crack */
  deleteIdentifiedCrack: Scalars['String']['output'];
  /** Delete identified deformation */
  deleteIdentifiedDeformation: Scalars['String']['output'];
  /** Delete identified water leak */
  deleteIdentifiedWaterLeak: Scalars['String']['output'];
  /** Delete inspection */
  deleteInspection: Scalars['String']['output'];
  /** Delete inspection data image */
  deleteInspectionDataImage: Scalars['String']['output'];
  /** Delete instrument */
  deleteInstrument: Scalars['String']['output'];
  /** Delete instrument alert level */
  deleteInstrumentAlertLevel: Scalars['String']['output'];
  deleteInstrumentDocById: Scalars['String']['output'];
  /** Delete instrument reading */
  deleteInstrumentReading: Scalars['String']['output'];
  /** Delete a participant */
  deleteParticipant: Scalars['String']['output'];
  /** Delete file from psb */
  deletePsbFile: Scalars['String']['output'];
  /** delete an ato activity */
  deleteRecordActivities: Scalars['String']['output'];
  /** delete image from activity */
  deleteRecordActivitiesImage: Scalars['String']['output'];
  /** delete a video from activity */
  deleteRecordActivitiesVideo: Scalars['String']['output'];
  /** delete equipment/labor from ato record */
  deleteRecordEquipmentAndLabor: Scalars['String']['output'];
  /** delete image from ato record */
  deleteRecordImage: Scalars['String']['output'];
  /** delete observation from an ato record */
  deleteRecordObservation: Scalars['String']['output'];
  /** delete a red alert period from ato record */
  deleteRecordRedAlertPeriod: Scalars['String']['output'];
  /** delete a video from ato record */
  deleteRecordVideo: Scalars['String']['output'];
  /** delete a weather condition from ato record */
  deleteRecordWeatherConditions: Scalars['String']['output'];
  /** delete a work shift from ato record */
  deleteRecordWorkShift: Scalars['String']['output'];
  /** Delete section */
  deleteSection: Scalars['String']['output'];
  /** Delete structure */
  deleteStructure: Scalars['String']['output'];
  /** Delete structure dam raising */
  deleteStructureDamRaising: Scalars['String']['output'];
  /** Delete structure module */
  deleteStructureModule: Scalars['String']['output'];
  /** delete subscribed user */
  deleteSubscribedUserNotification: Scalars['String']['output'];
  /** Delete user */
  deleteUser: Scalars['String']['output'];
  /** Delete user permission */
  deleteUserPermission: Scalars['String']['output'];
  /** Edit company */
  editCompany: Scalars['String']['output'];
  /** Edit password */
  editPassword: Scalars['String']['output'];
  /** edit a piezometer */
  editPiezometer: Scalars['String']['output'];
  /** edit a piezometer alert level */
  editPiezometerAlert: Scalars['String']['output'];
  /** edit a piezometer control cote */
  editPiezometerControlCote: Scalars['String']['output'];
  /** create piezometer reading */
  editPiezometerReading: Scalars['String']['output'];
  /** edit pluviometer data */
  editPluviometer: Scalars['String']['output'];
  /** Edit instrument alert (water level and pluviometer) */
  editPluviometerAndWaterLevelAlert: Scalars['String']['output'];
  /** edit pluviometer reading data */
  editPluviometerReading: Scalars['String']['output'];
  /** Edit section */
  editSection: Scalars['String']['output'];
  /** Edit structure */
  editStructure: Scalars['String']['output'];
  /** Edit structure modules */
  editStructureAndModules: Scalars['String']['output'];
  /** Edit structure dam raising */
  editStructureDamRaising: Scalars['String']['output'];
  /** Edit structure dam tailing volume history */
  editStructureDamTailingVolume: Scalars['String']['output'];
  /** Edit structure geometry */
  editStructureGeometry: Scalars['String']['output'];
  /** Edit structure hydrology */
  editStructureHydrology: Scalars['String']['output'];
  /** Edit structure reservoir handling */
  editStructureReservoirHandling: Scalars['String']['output'];
  /** Edit structure risk assessment */
  editStructureRiskAssessment: Scalars['String']['output'];
  /** Edit structure summary */
  editStructureSummary: Scalars['String']['output'];
  /** edit a surface marker */
  editSurfaceMarker: Scalars['String']['output'];
  /** edit a surface marker alert level */
  editSurfaceMarkerAlertLevel: Scalars['String']['output'];
  /** edit a surface marker reference */
  editSurfaceMarkerReference: Scalars['String']['output'];
  /** edit a surface marker reading */
  editSurfaceMarkersReading: Scalars['String']['output'];
  /** Edit user */
  editUser: Scalars['String']['output'];
  /** Edit user permission */
  editUserPermission: Scalars['String']['output'];
  /** edit water level data */
  editWaterLevel: Scalars['String']['output'];
  /** edit water level instrument */
  editWaterLevelReading: Scalars['String']['output'];
  /** Forgot password */
  forgotPassword: Scalars['String']['output'];
  /** generate inspection pdf */
  generateInspectionPdf: Scalars['String']['output'];
  /** Authenticate as user or admin */
  login: Login;
  /** Save inspection data all at once */
  mobSaveAllInpectionData: Scalars['String']['output'];
  /** Reactivate an ATO */
  reactivateAto: Scalars['String']['output'];
  /** Record approval flow */
  recordApprovalFlow: Scalars['String']['output'];
  /** Record approval flow */
  recordReprovalFlow: Scalars['String']['output'];
  /** Save action plan comments */
  saveActionPlanComment: SavedActionPlanCommentType;
  /** Save action plan team members */
  saveActionPlanTeamMembers: Array<SavedActionPlanTeamMembersType>;
  /** save documents related to ATO */
  saveAtoDocs: Array<AtoDocsIdModelType>;
  /** save conservation state */
  saveConservationState: Scalars['String']['output'];
  /** Save data to section chart */
  saveDataToSectionChart: Scalars['String']['output'];
  /** save general comments */
  saveGeneralComments: Scalars['String']['output'];
  /** Salvar dados de situação de acesso, reservatório, extravasores, instrumentação e bombeamento  */
  saveGenericData: Array<CreatedInspectionGenericDataType>;
  /** save gistm comments */
  saveGistmComments: Array<GistmCommentsType>;
  saveInstrumentDocs: Array<InstrumentDocsType>;
  /** save massive structure shoulder */
  saveMassiveStructureShoulder: CreatedMassiveStructureShoulderDataType;
  /** Save piezometer-ina control quota */
  savePiezometerControlQuota: Scalars['String']['output'];
  /** insert ato activities information */
  saveRecordActivities: Scalars['String']['output'];
  /** insert equipment or labor information */
  saveRecordEquipmentAndLabor: Scalars['String']['output'];
  /** Save Ato Record Observation */
  saveRecordObservation: Scalars['String']['output'];
  /** Save Ato Record Red Alert Period */
  saveRecordRedAlertPeriod: Scalars['String']['output'];
  /** Save Ato Record Weather Conditions */
  saveRecordWeatherConditions: Scalars['String']['output'];
  /** Save Ato Record Work Shift */
  saveRecordWorkShift: Scalars['String']['output'];
  /** Set action plan situation to canceled */
  setActionPlanCanceled: Scalars['String']['output'];
  /** Set action plan situation to progress */
  setActionPlanProgress: Scalars['String']['output'];
  /** Cancel ATO */
  setAtoCanceled: Scalars['String']['output'];
  /** Set ATO as done */
  setAtoDone: Scalars['String']['output'];
  /** Set Inspection Situation Done */
  setInspectionSituationDone: Scalars['String']['output'];
  /** set notification unread status */
  setNotificationUnreadStatus: Scalars['String']['output'];
  /** Sign ato record */
  signAtoRecord: Scalars['String']['output'];
  /** Update responsible, timeFrame and priority data from an action plan */
  updateActionPlan: Scalars['String']['output'];
  /** update information in ATO */
  updateAto: Scalars['String']['output'];
  /** Update conservation state */
  updateConservationState: Scalars['String']['output'];
  /** Update inspection date */
  updateDate: Scalars['String']['output'];
  /** Update general comments */
  updateGeneralComments: Scalars['String']['output'];
  /** Atualizar dados de situação de acesso, reservatório, extravasores, instrumentação e bombeamento  */
  updateGenericData: Scalars['String']['output'];
  /** Update identified crack */
  updateIdentifiedCrack: Scalars['String']['output'];
  /** Update identified deformation */
  updateIdentifiedDeformation: Scalars['String']['output'];
  /** Update water leaks */
  updateIdentifiedWaterLeaks: Scalars['String']['output'];
  /** Update Coating, SurfaceErosions, SurfaceDrainage, PresenceOfTreesAnimalsInsects and InternalDrainage */
  updateMssGenericData: Scalars['String']['output'];
  /** Update data of an activity register from ato record */
  updateRecordActivities: Scalars['String']['output'];
  /** Update data of an equipment/labor register from ato record */
  updateRecordEquipmentAndLabor: Scalars['String']['output'];
  /** Update data of an image record register from ato record */
  updateRecordImageObservation: Scalars['String']['output'];
  /** Update observation register from ato record */
  updateRecordObservation: Scalars['String']['output'];
  /** Update a red alert data register from ato record */
  updateRecordRedAlertPeriod: Scalars['String']['output'];
  /** Update video observation */
  updateRecordVideoObservation: Scalars['String']['output'];
  /** Update data of a weather condition register from ato record */
  updateRecordWeatherConditions: Scalars['String']['output'];
  /** Update data of a work shift register from ato record */
  updateRecordWorkShift: Scalars['String']['output'];
  /** Update section chart parameters */
  updateSectionChartParameters: Scalars['String']['output'];
};

export type MutationActionPlanApprovalFlowArgs = {
  actionPlanId: Scalars['String']['input'];
};

export type MutationActionPlanReprovalFlowArgs = {
  actionPlanId: Scalars['String']['input'];
  comment: Scalars['String']['input'];
};

export type MutationAddIdentifiedCrackArgs = {
  inspectionId: Scalars['String']['input'];
  newIdentifiedCrack: MssIdentifiedCracksInput;
};

export type MutationAddIdentifiedDeformationArgs = {
  inspectionId: Scalars['String']['input'];
  newIdentifiedDeformation: MssIdentifiedDeformationsInput;
};

export type MutationAddIdentifiedWaterLeakArgs = {
  inspectionId: Scalars['String']['input'];
  newIdentifiedWaterLeak: MssIdentifiedWaterLeaksInput;
};

export type MutationAddParticipantsArgs = {
  inspectionId: Scalars['String']['input'];
  participants: Array<CreateParticipantsInput>;
};

export type MutationAddPiezometerInaAlertLevelsArgs = {
  data: Array<CreatePiezometerInaAlertLevelsInput>;
  structureId: Scalars['String']['input'];
};

export type MutationAddPluviometerAlertLevelsArgs = {
  data: Array<CreatePluviometerAndWaterLevelAlertLevelInput>;
  structureId: Scalars['String']['input'];
};

export type MutationAddStructureDamTailingVolumeArgs = {
  data: Array<AddDamTailingVolumeInput>;
  structureId: Scalars['String']['input'];
};

export type MutationAddSurfaceMarkerAlertLevelsArgs = {
  data: Array<AddSurfaceMarkerAlertLevels>;
  structureId: Scalars['String']['input'];
};

export type MutationAddWaterLevelAlertLevelsArgs = {
  data: Array<CreatePluviometerAndWaterLevelAlertLevelInput>;
  structureId: Scalars['String']['input'];
};

export type MutationAssociateActionPlansArgs = {
  actionPlanId: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
};

export type MutationCreateActionPlanArgs = {
  data: CreateActionPlanInput;
};

export type MutationCreateAtoArgs = {
  data: CreateAtoInput;
  structureId: Scalars['String']['input'];
};

export type MutationCreateCompanyArgs = {
  data: CreateCompanyInput;
};

export type MutationCreateInspectionArgs = {
  data: CreateInspectionInput;
};

export type MutationCreatePiezometerArgs = {
  alertLevels: Array<CreatePiezometerAlertLevelInput>;
  generalInfo: CreateInstrumentWithSectionInput;
  specificInfo: CreatePiezometerInput;
  structureInfo: StructureInfoInput;
};

export type MutationCreatePiezometerReadingArgs = {
  data: Array<PiezometerInaReadingValuesInput>;
  structureInfo: StructureInfoInput;
};

export type MutationCreatePluviometerArgs = {
  alertLevels: Array<PluviometerAlertLevelInput>;
  generalInfo: CreateInstrumentInput;
  specificInfo: BasePluviometerInput;
  structureInfo: StructureInfoInput;
};

export type MutationCreatePluviometerReadingArgs = {
  data: Array<PluviometerReadingInput>;
  structureInfo: StructureInfoInput;
};

export type MutationCreateSectionArgs = {
  data: CreateSectionInput;
};

export type MutationCreateStructureArgs = {
  data: CreateStructureInput;
};

export type MutationCreateStructureDamRaisingArgs = {
  data: Array<CreateStructureDamRaisingInput>;
  structureId: Scalars['String']['input'];
};

export type MutationCreateStructureGeometryArgs = {
  data: CreateStructureGeometryInput;
  structureId: Scalars['String']['input'];
};

export type MutationCreateStructureHydrologyArgs = {
  data: CreateStructureHydrologyInput;
  structureId: Scalars['String']['input'];
};

export type MutationCreateStructureModulesArgs = {
  data: CreateStructureModulesInput;
};

export type MutationCreateStructureReservoirHandlingArgs = {
  data: CreateReservoirHandlingInput;
  structureId: Scalars['String']['input'];
};

export type MutationCreateStructureRiskAssessmentArgs = {
  data: CreateStructureRiskAssessmentInput;
  structureId: Scalars['String']['input'];
};

export type MutationCreateStructureSummaryArgs = {
  data: CreateStructureSummaryInput;
};

export type MutationCreateSurfaceMarkerArgs = {
  alertLevels: Array<SurfaceMarkersAlertLevelInput>;
  generalInfo: CreateInstrumentWithSectionInput;
  references: Array<SurfaceMarkersBaseDataInput>;
  structureInfo: StructureInfoInput;
};

export type MutationCreateSurfaceMarkersReadingArgs = {
  data: Array<CreateSurfaceMarkersReadingInput>;
  structureInfo: StructureInfoInput;
};

export type MutationCreateUserArgs = {
  data: CreateUserInput;
};

export type MutationCreateUserInstrumentNotificationArgs = {
  data: CreateUserNotificationInput;
};

export type MutationCreateUserPermissionArgs = {
  data: CreateUserPermissionInput;
};

export type MutationCreateWaterLevelArgs = {
  alertLevels: Array<CreateWaterLevelAlertLevelsInput>;
  generalInfo: CreateInstrumentInput;
  structureInfo: StructureInfoInput;
};

export type MutationCreateWaterLevelReadingArgs = {
  data: Array<CreateWaterMeterReadingInput>;
  structureInfo: StructureInfoInput;
};

export type MutationDeleteActionPlanCommentArgs = {
  commentId: Scalars['String']['input'];
};

export type MutationDeleteActionPlanTeamMemberArgs = {
  actionPlanId: Scalars['String']['input'];
  teamMemberId: Scalars['String']['input'];
};

export type MutationDeleteAtoDocsArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteCompanyArgs = {
  data: DeleteCompanyInput;
};

export type MutationDeleteCompanyLogoArgs = {
  companyId: Scalars['String']['input'];
};

export type MutationDeleteGistmCommentArgs = {
  commentId: Scalars['String']['input'];
};

export type MutationDeleteGistmFileArgs = {
  fileId: Scalars['String']['input'];
};

export type MutationDeleteIdentifiedCrackArgs = {
  identifiedCrackId: Scalars['String']['input'];
  inspectionId: Scalars['String']['input'];
};

export type MutationDeleteIdentifiedDeformationArgs = {
  identifiedDeformationId: Scalars['String']['input'];
  inspectionId: Scalars['String']['input'];
};

export type MutationDeleteIdentifiedWaterLeakArgs = {
  identifiedWaterLeakId: Scalars['String']['input'];
  inspectionId: Scalars['String']['input'];
};

export type MutationDeleteInspectionArgs = {
  inspectionId: Scalars['String']['input'];
};

export type MutationDeleteInspectionDataImageArgs = {
  image: DeleteInspectionDataImageInput;
  inspectionId: Scalars['String']['input'];
};

export type MutationDeleteInstrumentArgs = {
  instrumentId: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
};

export type MutationDeleteInstrumentAlertLevelArgs = {
  data: DeleteInstrumentAlertLevelInput;
  structureId: Scalars['String']['input'];
};

export type MutationDeleteInstrumentDocByIdArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteInstrumentReadingArgs = {
  data: DeleteInstrumentReadingInput;
  structureId: Scalars['String']['input'];
};

export type MutationDeleteParticipantArgs = {
  participantId: Scalars['String']['input'];
};

export type MutationDeletePsbFileArgs = {
  fileId: Scalars['String']['input'];
};

export type MutationDeleteRecordActivitiesArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteRecordActivitiesImageArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteRecordActivitiesVideoArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteRecordEquipmentAndLaborArgs = {
  data: DeleteAtoRecordEquipmentAndLaborInput;
};

export type MutationDeleteRecordImageArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteRecordObservationArgs = {
  data: DeleteAtoRecordObservationInput;
};

export type MutationDeleteRecordRedAlertPeriodArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteRecordVideoArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteRecordWeatherConditionsArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteRecordWorkShiftArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteSectionArgs = {
  sectionId: Scalars['String']['input'];
};

export type MutationDeleteStructureArgs = {
  structureId: Scalars['String']['input'];
};

export type MutationDeleteStructureDamRaisingArgs = {
  damRaisingId: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
};

export type MutationDeleteStructureModuleArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteSubscribedUserNotificationArgs = {
  userNotificationId: Scalars['String']['input'];
};

export type MutationDeleteUserArgs = {
  data: DeleteUserInput;
};

export type MutationDeleteUserPermissionArgs = {
  data: DeleteUserPermissionInput;
};

export type MutationEditCompanyArgs = {
  data: EditCompanyInput;
};

export type MutationEditPasswordArgs = {
  data: EditPasswordInput;
};

export type MutationEditPiezometerArgs = {
  data: EditPiezometerInaInput;
  structureInfo: StructureInfoInput;
};

export type MutationEditPiezometerAlertArgs = {
  data: EditPiezometerAlertInput;
  structureInfo: StructureInfoInput;
};

export type MutationEditPiezometerControlCoteArgs = {
  data: EditPiezometerControlCoteInput;
  structureInfo: StructureInfoInput;
};

export type MutationEditPiezometerReadingArgs = {
  data: EditPiezometerInaReadingInput;
  structureInfo: StructureInfoInput;
};

export type MutationEditPluviometerArgs = {
  data: EditPluviometerInput;
  structureInfo: StructureInfoInput;
};

export type MutationEditPluviometerAndWaterLevelAlertArgs = {
  data: EditInstrumentAlertInput;
  structureId: Scalars['String']['input'];
};

export type MutationEditPluviometerReadingArgs = {
  data: EditPluviometerReadingInput;
  structureInfo: StructureInfoInput;
};

export type MutationEditSectionArgs = {
  data: EditSectionInput;
};

export type MutationEditStructureArgs = {
  data: EditStructureInput;
};

export type MutationEditStructureAndModulesArgs = {
  data: EditStructureModulesInput;
};

export type MutationEditStructureDamRaisingArgs = {
  data: EditStructureDamRaisingInput;
};

export type MutationEditStructureDamTailingVolumeArgs = {
  data: EditTailingVolumeInput;
};

export type MutationEditStructureGeometryArgs = {
  data: EditGeometryInput;
};

export type MutationEditStructureHydrologyArgs = {
  data: EditHydrologyInput;
};

export type MutationEditStructureReservoirHandlingArgs = {
  data: EditReservoirHandlingInput;
};

export type MutationEditStructureRiskAssessmentArgs = {
  data: EditRiskAssessmentInput;
};

export type MutationEditStructureSummaryArgs = {
  data: EditStructureSummaryInput;
};

export type MutationEditSurfaceMarkerArgs = {
  data: EditSurfaceMarkerInput;
  structureInfo: StructureInfoInput;
};

export type MutationEditSurfaceMarkerAlertLevelArgs = {
  data: EditSurfaceMarkerAlertLevelInput;
  structureInfo: StructureInfoInput;
};

export type MutationEditSurfaceMarkerReferenceArgs = {
  data: EditSurfaceMarkerReferenceInput;
  structureInfo: StructureInfoInput;
};

export type MutationEditSurfaceMarkersReadingArgs = {
  data: EditSurfaceMarkersReadingInput;
  structureInfo: StructureInfoInput;
};

export type MutationEditUserArgs = {
  data: EditUserInput;
};

export type MutationEditUserPermissionArgs = {
  data: EditUserPermissionInput;
};

export type MutationEditWaterLevelArgs = {
  data: EditWaterLevelInput;
  structureInfo: StructureInfoInput;
};

export type MutationEditWaterLevelReadingArgs = {
  data: EditWaterLevelReadingInput;
  structureInfo: StructureInfoInput;
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationGenerateInspectionPdfArgs = {
  inspectionId: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  data: LoginInput;
};

export type MutationMobSaveAllInpectionDataArgs = {
  data: SaveInpectionAllDataInput;
};

export type MutationReactivateAtoArgs = {
  id: Scalars['String']['input'];
};

export type MutationRecordApprovalFlowArgs = {
  recordId: Scalars['String']['input'];
};

export type MutationRecordReprovalFlowArgs = {
  commentary: Scalars['String']['input'];
  recordId: Scalars['String']['input'];
};

export type MutationSaveActionPlanCommentArgs = {
  data: CreateActionPlanCommentInput;
};

export type MutationSaveActionPlanTeamMembersArgs = {
  actionPlanId: Scalars['String']['input'];
  usersId: Array<Scalars['String']['input']>;
};

export type MutationSaveAtoDocsArgs = {
  atoId: Scalars['String']['input'];
  docs: Array<AtoDocsInput>;
};

export type MutationSaveConservationStateArgs = {
  conservationState: Array<SaveConservationStateInput>;
  inspectionId: Scalars['String']['input'];
};

export type MutationSaveDataToSectionChartArgs = {
  parameters: ParametersToChartInput;
  sectionId: Scalars['String']['input'];
};

export type MutationSaveGeneralCommentsArgs = {
  generalComments: Scalars['String']['input'];
  inspectionId: Scalars['String']['input'];
};

export type MutationSaveGenericDataArgs = {
  genericData: Array<SaveGenericDataInput>;
  inspectionId: Scalars['String']['input'];
};

export type MutationSaveGistmCommentsArgs = {
  comments: Array<CreateGistmCommentsInput>;
  structureId: Scalars['String']['input'];
};

export type MutationSaveInstrumentDocsArgs = {
  data: SaveInstrumentDocsInput;
};

export type MutationSaveMassiveStructureShoulderArgs = {
  data: SaveMassiveStructureShoulderDataInput;
  inspectionId: Scalars['String']['input'];
};

export type MutationSavePiezometerControlQuotaArgs = {
  data: CreatePiezometerInput;
  instrumentId: Scalars['String']['input'];
};

export type MutationSaveRecordActivitiesArgs = {
  description: Scalars['String']['input'];
  recordId: Scalars['String']['input'];
};

export type MutationSaveRecordEquipmentAndLaborArgs = {
  data: SaveRecordEquipmentAndLaborInput;
};

export type MutationSaveRecordObservationArgs = {
  data: SaveAtoRecordObservationInput;
};

export type MutationSaveRecordRedAlertPeriodArgs = {
  data: SaveAtoRecordRedAlertPeriodInput;
};

export type MutationSaveRecordWeatherConditionsArgs = {
  data: SaveAtoRecordWeatherConditionsInput;
};

export type MutationSaveRecordWorkShiftArgs = {
  data: SaveAtoRecordWorkShiftInput;
};

export type MutationSetActionPlanCanceledArgs = {
  actionPlanId: Scalars['String']['input'];
  comment: Scalars['String']['input'];
};

export type MutationSetActionPlanProgressArgs = {
  data: SetActionPlanProgressInput;
};

export type MutationSetAtoCanceledArgs = {
  id: Scalars['String']['input'];
};

export type MutationSetAtoDoneArgs = {
  id: Scalars['String']['input'];
};

export type MutationSetInspectionSituationDoneArgs = {
  inspectionId: Scalars['String']['input'];
};

export type MutationSetNotificationUnreadStatusArgs = {
  notificationId: Scalars['String']['input'];
  notificationModuleType: NotificationTypeEnum;
};

export type MutationSignAtoRecordArgs = {
  recordId: Scalars['String']['input'];
};

export type MutationUpdateActionPlanArgs = {
  data: UpdateActionPlanInput;
};

export type MutationUpdateAtoArgs = {
  data: UpdateAtoInput;
};

export type MutationUpdateConservationStateArgs = {
  inspectionId: Scalars['String']['input'];
  newData: Array<UpdateConservationStateInput>;
};

export type MutationUpdateDateArgs = {
  inspectionId: Scalars['String']['input'];
  newDate: Scalars['DateTime']['input'];
};

export type MutationUpdateGeneralCommentsArgs = {
  inspectionId: Scalars['String']['input'];
  newGeneralComments: Scalars['String']['input'];
};

export type MutationUpdateGenericDataArgs = {
  newData: Array<UpdateGenericDataInput>;
};

export type MutationUpdateIdentifiedCrackArgs = {
  inspectionId: Scalars['String']['input'];
  newData: UpdateMssIdentifiedCrackInput;
};

export type MutationUpdateIdentifiedDeformationArgs = {
  inspectionId: Scalars['String']['input'];
  newData: UpdateMssIdentifiedDeformationInput;
};

export type MutationUpdateIdentifiedWaterLeaksArgs = {
  inspectionId: Scalars['String']['input'];
  newData: UpdateMssIdentifiedWaterLeaksInput;
};

export type MutationUpdateMssGenericDataArgs = {
  inspectionId: Scalars['String']['input'];
  newData: Array<UpdateMssGenericDataInput>;
};

export type MutationUpdateRecordActivitiesArgs = {
  data: UpdateRecordActivityInput;
};

export type MutationUpdateRecordEquipmentAndLaborArgs = {
  data: UpdateEquipmentLaborInput;
};

export type MutationUpdateRecordImageObservationArgs = {
  data: UpdateRecordImageObservationInput;
};

export type MutationUpdateRecordObservationArgs = {
  data: UpdateRecordObservationInput;
};

export type MutationUpdateRecordRedAlertPeriodArgs = {
  data: UpdateRecordRedAlertPeriodInput;
};

export type MutationUpdateRecordVideoObservationArgs = {
  data: UpdateRecordVideoObservationInput;
};

export type MutationUpdateRecordWeatherConditionsArgs = {
  data: UpdateRecordWeatherConditionsInput;
};

export type MutationUpdateRecordWorkShiftArgs = {
  data: UpdateRecordWorkShiftInput;
};

export type MutationUpdateSectionChartParametersArgs = {
  parameters: UpdateSectionChartParametersInput;
  sectionId: Scalars['String']['input'];
};

export type NotificationFilterInput = {
  /** Array of given data, start and end */
  dateRange?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum NotificationTypeEnum {
  ActionPlanNotification = 'ActionPlanNotification',
  AtoNotification = 'AtoNotification',
  InstrumentNotification = 'InstrumentNotification'
}

export type NotificationsByModuleType = {
  __typename?: 'NotificationsByModuleType';
  body?: Maybe<Scalars['String']['output']>;
  contentId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  unread: Scalars['Boolean']['output'];
};

export type PagWaterLevelReadingType = {
  __typename?: 'PagWaterLevelReadingType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of PaginatedWaterLevelReadingType */
  nodes: Array<PaginatedWaterLevelReadingType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** Has next page */
  hasNextPage: Scalars['Boolean']['output'];
  /** Has previous page */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Max items per result */
  limit: Scalars['Int']['output'];
  /** Number of skipped elements */
  offset: Scalars['Int']['output'];
  /** Current page - 0-index based */
  page: Scalars['Int']['output'];
  /** Total number of pages */
  totalPages: Scalars['Int']['output'];
};

export type PageInput = {
  /** Max items per result */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Page to retrieve  */
  page: Scalars['Int']['input'];
};

export type PaginatedFileType = {
  __typename?: 'PaginatedFileType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of FileType */
  nodes: Array<FileType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedFindAllRecordsType = {
  __typename?: 'PaginatedFindAllRecordsType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of FindAllRecordsType */
  nodes: Array<FindAllRecordsType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedGistmFileType = {
  __typename?: 'PaginatedGistmFileType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of GistmFileType */
  nodes: Array<GistmFileType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedGistmFilesType = {
  __typename?: 'PaginatedGistmFilesType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of GistmFileType */
  nodes: Array<GistmFileType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedListActionPlanPagType = {
  __typename?: 'PaginatedListActionPlanPagType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListActionPlanPagType */
  nodes: Array<ListActionPlanPagType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedListActionPlanType = {
  __typename?: 'PaginatedListActionPlanType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListActionPlanPagType */
  nodes: Array<ListActionPlanPagType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedListControlQuotasType = {
  __typename?: 'PaginatedListControlQuotasType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListControlQuotasType */
  nodes: Array<ListControlQuotasType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedListNotificationsByModuleType = {
  __typename?: 'PaginatedListNotificationsByModuleType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of NotificationsByModuleType */
  nodes: Array<NotificationsByModuleType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedListPiezometerInaAlertLevelsType = {
  __typename?: 'PaginatedListPiezometerInaAlertLevelsType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListPiezometerInaAlertLevelsType */
  nodes: Array<ListPiezometerInaAlertLevelsType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedListPiezometerInaReadingType = {
  __typename?: 'PaginatedListPiezometerInaReadingType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListPiezometerInaReadingType */
  nodes: Array<ListPiezometerInaReadingType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedListSurfaceMarkerAlertLevelType = {
  __typename?: 'PaginatedListSurfaceMarkerAlertLevelType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListSurfaceMarkerAlertLevelType */
  nodes: Array<ListSurfaceMarkerAlertLevelType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedListSurfaceMarkersReferenceType = {
  __typename?: 'PaginatedListSurfaceMarkersReferenceType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListSurfaceMarkersReferenceType */
  nodes: Array<ListSurfaceMarkersReferenceType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedNotificationsByModuleType = {
  __typename?: 'PaginatedNotificationsByModuleType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of NotificationsByModuleType */
  nodes: Array<NotificationsByModuleType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedPaginatedPluviometerReadingType = {
  __typename?: 'PaginatedPaginatedPluviometerReadingType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of PaginatedPluviometerReadingType */
  nodes: Array<PaginatedPluviometerReadingType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedPaginatedSurfaceMarkersReadingType = {
  __typename?: 'PaginatedPaginatedSurfaceMarkersReadingType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of PaginatedSurfaceMarkersReadingType */
  nodes: Array<PaginatedSurfaceMarkersReadingType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedPaginatedWaterLevelReadingType = {
  __typename?: 'PaginatedPaginatedWaterLevelReadingType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of PaginatedWaterLevelReadingType */
  nodes: Array<PaginatedWaterLevelReadingType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedPiezometerInaAlertLevels = {
  __typename?: 'PaginatedPiezometerInaAlertLevels';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListPiezometerInaAlertLevelsType */
  nodes: Array<ListPiezometerInaAlertLevelsType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedPiezometerInaControlQuotas = {
  __typename?: 'PaginatedPiezometerInaControlQuotas';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListControlQuotasType */
  nodes: Array<ListControlQuotasType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedPiezometerInaReading = {
  __typename?: 'PaginatedPiezometerInaReading';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListPiezometerInaReadingType */
  nodes: Array<ListPiezometerInaReadingType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedPluviometerAlertLevels = {
  __typename?: 'PaginatedPluviometerAlertLevels';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of PluviometerWaterLevelAlertLevelsType */
  nodes: Array<PluviometerWaterLevelAlertLevelsType>;
  /** Page information */
  pageInfo: PageInfo;
};

/** Paginated infos to list pluviometers reading */
export type PaginatedPluviometerReadingType = {
  __typename?: 'PaginatedPluviometerReadingType';
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  date: Scalars['DateTime']['output'];
  installLocation?: Maybe<Scalars['String']['output']>;
  instrumentId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  operationalStatus: InstrumentStatusEnum;
  rainfall: Scalars['Float']['output'];
  readingId: Scalars['String']['output'];
  readingType: InstrumentReadingTypeEnum;
  structureType?: Maybe<Scalars['String']['output']>;
};

export type PaginatedPluviometerWaterLevelAlertLevelsType = {
  __typename?: 'PaginatedPluviometerWaterLevelAlertLevelsType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of PluviometerWaterLevelAlertLevelsType */
  nodes: Array<PluviometerWaterLevelAlertLevelsType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedPsbFileType = {
  __typename?: 'PaginatedPsbFileType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of FileType */
  nodes: Array<FileType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedReadingType = {
  __typename?: 'PaginatedReadingType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of PaginatedPluviometerReadingType */
  nodes: Array<PaginatedPluviometerReadingType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedRecordsType = {
  __typename?: 'PaginatedRecordsType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of FindAllRecordsType */
  nodes: Array<FindAllRecordsType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedSurfaceMarkerAlertLevels = {
  __typename?: 'PaginatedSurfaceMarkerAlertLevels';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListSurfaceMarkerAlertLevelType */
  nodes: Array<ListSurfaceMarkerAlertLevelType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedSurfaceMarkerReferences = {
  __typename?: 'PaginatedSurfaceMarkerReferences';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of ListSurfaceMarkersReferenceType */
  nodes: Array<ListSurfaceMarkersReferenceType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedSurfaceMarkersReadingType = {
  __typename?: 'PaginatedSurfaceMarkersReadingType';
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  displacements: Displacements;
  installLocation?: Maybe<Scalars['String']['output']>;
  instrumentId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  operationalStatus: InstrumentStatusEnum;
  reading: BaseSurfaceMarkersReadingType;
  readingType: Scalars['String']['output'];
  readingsType?: Maybe<InstrumentReadingTypeEnum>;
  structureType?: Maybe<Scalars['String']['output']>;
};

export type PaginatedWaterLevelAlertLevels = {
  __typename?: 'PaginatedWaterLevelAlertLevels';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of WaterLevelAlertLevelsType */
  nodes: Array<WaterLevelAlertLevelsType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedWaterLevelAlertLevelsType = {
  __typename?: 'PaginatedWaterLevelAlertLevelsType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of WaterLevelAlertLevelsType */
  nodes: Array<WaterLevelAlertLevelsType>;
  /** Page information */
  pageInfo: PageInfo;
};

export type PaginatedWaterLevelReadingType = {
  __typename?: 'PaginatedWaterLevelReadingType';
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  date: Scalars['DateTime']['output'];
  instrumentId: Scalars['String']['output'];
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  operationalStatus: InstrumentStatusEnum;
  readingId: Scalars['String']['output'];
  readingType?: Maybe<Scalars['String']['output']>;
  structureType?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type ParametersToChartInput = {
  imageHeight: Scalars['Float']['input'];
  imageWidth: Scalars['Float']['input'];
  inferiorPointY: Scalars['Float']['input'];
  instruments: Array<InstrumentsParameterToChartType>;
};

export type PeriodInput = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type PiezometerInaAlertLevelsType = {
  __typename?: 'PiezometerInaAlertLevelsType';
  coteValue: Scalars['Float']['output'];
  drained?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  undrained?: Maybe<Scalars['String']['output']>;
};

export type PiezometerInaFilterInput = {
  /** End date */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Instruments id */
  instrumentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ReadingType */
  readingType?: InputMaybe<InstrumentReadingTypeEnum>;
  /** Start date */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Status */
  status?: InputMaybe<InstrumentStatusEnum>;
};

export type PiezometerInaInfosType = {
  __typename?: 'PiezometerInaInfosType';
  inAlert: Scalars['Float']['output'];
  instruments: Array<LastPiezometerInaReadingType>;
  quantity: Scalars['Float']['output'];
};

export type PiezometerInaLastReadingType = {
  __typename?: 'PiezometerInaLastReadingType';
  cote: Scalars['Float']['output'];
  date: Scalars['DateTime']['output'];
  isDry: Scalars['Boolean']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  reading?: Maybe<Scalars['Float']['output']>;
  readingId: Scalars['String']['output'];
};

export type PiezometerInaReadingValuesInput = {
  /** reading cote */
  cote?: InputMaybe<Scalars['Float']['input']>;
  /** reading date */
  date: Scalars['DateTime']['input'];
  /** instrument id */
  instrumentId?: InputMaybe<Scalars['String']['input']>;
  /** instument name */
  instrumentName: Scalars['String']['input'];
  /** is dry */
  isDry?: InputMaybe<Scalars['Boolean']['input']>;
  observation?: InputMaybe<Scalars['String']['input']>;
  /** reading value */
  reading?: InputMaybe<Scalars['Float']['input']>;
};

export type PiezometerInaReadingValuesType = {
  __typename?: 'PiezometerInaReadingValuesType';
  cote: Scalars['Float']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isDry: Scalars['Boolean']['output'];
};

export type PiezometerToChartType = {
  __typename?: 'PiezometerToChartType';
  alertLevels: Array<AlertLevelToChartType>;
  chartItemType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  readings: Array<ReadingsToChartType>;
};

export type PluviometerAlertLevelInput = {
  name: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type PluviometerInput = {
  /** Type of the pluviometer */
  pluviometerType?: InputMaybe<Scalars['String']['input']>;
};

export type PluviometerListType = {
  __typename?: 'PluviometerListType';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  installLocation?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['DateTime']['output']>;
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  operationalStatus: InstrumentStatusEnum;
  /** pluviometer type */
  pluviometerType?: Maybe<Scalars['String']['output']>;
  readingType: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  type?: Maybe<InstrumentType>;
};

export type PluviometerReadingInput = {
  /** date */
  date: Scalars['DateTime']['input'];
  /** instrument id */
  instrumentId?: InputMaybe<Scalars['String']['input']>;
  /** instument name */
  instrumentName: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  /** rainfall in mm */
  rainfall: Scalars['Float']['input'];
};

export type PluviometerWaterLevelAlertLevelsType = {
  __typename?: 'PluviometerWaterLevelAlertLevelsType';
  date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

/** Psb data */
export type PsbDataType = {
  __typename?: 'PsbDataType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nested?: Maybe<Array<PsbDataType>>;
  path: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** list all pluviometer reading */
  ListAllPluviometerReadingModel: Array<ListAllPluviometerReadingType>;
  /** Return an action plan data */
  findActionPlanData: ActionPlanDataType;
  /** Find users that meet the requirements to be considered a team member */
  findActionPlanMembers: Array<ActionPlanTeamMembersDataType>;
  /** find action plans paginated */
  findActionPlanPag: PaginatedListActionPlanType;
  /** Find users that meet the requirements to be considered "responsible" */
  findActionPlanResponsible: Array<ActionPlanResponsibleType>;
  /** Return all images from an action plan */
  findAllActionPlanImages: Array<Scalars['String']['output']>;
  /** find all action plans to export table */
  findAllActionPlans: Array<ListAllActionPlansToExportTableType>;
  /** find all associated action plans */
  findAllAssociatedActionPlans: Array<ListAllAssociatedActionPlansType>;
  /** Find all ATOs from a given structure */
  findAllAto: Array<FindAllAtoType>;
  /** Find all ato images */
  findAllAtoImages: Array<AtoImagesType>;
  /** Find all ato videos */
  findAllAtoVideos: Array<AtoVideosType>;
  /** find all gistm data */
  findAllGistmData: Array<GistmDataType>;
  /** find all inspections by structure id */
  findAllInspectionsByStructure: Array<InspectionsType>;
  /** find all psb data */
  findAllPsbData: Array<PsbDataType>;
  /** get paginated ATO records */
  findAllRecordsPag: PaginatedRecordsType;
  /** Find ato approvers */
  findAtoApprovers: Array<VerifierApproverType>;
  /** Find ato verifiers */
  findAtoVerifiers: Array<VerifierApproverType>;
  /** Return action plan calculated data */
  findCalculatedInfos: CalculatedInfosType;
  /** find data by inspection */
  findDataByInspection: InspectionDataType;
  /** Find ato record data */
  findDataByRecord: FindDataByRecordType;
  /** Find data for section chart */
  findDataToChart: DataForSectionChartType;
  /** Find psb files paginated */
  findFilesPag: PaginatedPsbFileType;
  /** Find ato general info */
  findGeneralInfo: FindGeneralInfoType;
  /** List all comments from gistm topic */
  findGistmComments: Array<ListGistmCommentsType>;
  /** Find gistm files paginated */
  findGistmFilesPag: PaginatedGistmFilesType;
  /** find inspection conservation state data */
  findInspectionConservationStateData: Array<InspectionConservationStateType>;
  /** find inspection generic data */
  findInspectionGenericData: Array<InspectionGenericDataType>;
  /** find inspection mss data */
  findInspectionMssData: MassiveStructureShoulderDataType;
  /** Get modules info by structure ID and user ID */
  getModuleByStructure: Array<ModuleFlagType>;
  /** Get structure summary by structureId */
  getStructureSummary: StructureType;
  /** Get user structures list */
  getUserStructuresList: CompanyWithStructureType;
  /** get paginated alert levels by piezometers */
  listAlertsLevelByPiezometersPag: PaginatedPiezometerInaAlertLevels;
  /** get paginated alert levels by pluviometer */
  listAlertsLevelByPluviometerPag: PaginatedPluviometerAlertLevels;
  /** get paginated alert levels by surface marker */
  listAlertsLevelBySurfaceMarkerPag: PaginatedSurfaceMarkerAlertLevels;
  /** get paginated alert levels by water level */
  listAlertsLevelByWaterLevelPag: PaginatedWaterLevelAlertLevels;
  /** list all piezometer reading */
  listAllPiezometerReading: Array<ListAllPiezometerReadingType>;
  /** list all surface marker reading */
  listAllSurfaceMarkerReading: Array<ListAllSurfaceMarkerReadingType>;
  /** list all water level reading */
  listAllWaterLevelReading: Array<ListAllWaterLevelReadingType>;
  /** List associated structures by structure id */
  listAssociatedStructures: Array<AssociatedType>;
  /** List companies */
  listCompanies: Array<CompanyType>;
  /** List company related users and structures */
  listCompanyRelatedUsersAndStructures: CompanyRelatedUsersAndStructuresType;
  /** get paginated control quotas by piezometers */
  listControlQuotasByPiezometersPag: PaginatedPiezometerInaControlQuotas;
  /** List instrument by id */
  listInstrumentById: BasicInstrumentTypeWithSectionAndDocs;
  /** List all instrument types, like piezometer, ina and so on */
  listInstrumentTypes: Array<InstrumentType>;
  /** List all instruments by structure or associated structure */
  listInstrumentsByStructure: Array<ListAllInstrumentsType>;
  /** list all user notification */
  listInsturmentUserNotification: Array<UserNotificationType>;
  /** get reading values by piezometer */
  listLatestReadingByPiezometer: ListLatestPiezometerReadingType;
  /** get reading values by pluviometer */
  listLatestReadingByPluviometer: ListLatestPluviometerReadingType;
  /** get the most recent reading of a surface marker */
  listLatestSurfaceMarkerReading: ListLatestSurfaceMarkerReadingType;
  /** list latest user notification */
  listLatestUserNotification: ListLatestUserNotificationType;
  /** get reading values by water level */
  listLatestWaterLevelReading: ListLatestWaterLevelReadingType;
  /** List all modules */
  listModules: Array<ModuleType>;
  /** List modules by structure id */
  listModulesByStructureId: Array<ModuleType>;
  /** List all modules roles */
  listModulesRoles: Array<ModuleRoleType>;
  /** list notifications by module pag */
  listNotificationsByModulePag: PaginatedListNotificationsByModuleType;
  /** List piezometers by structure */
  listPiezometersByStructure: Array<ListPiezometerInaByStructure>;
  listPluviometersByStructure: Array<PluviometerListType>;
  /** get reading values by piezometer */
  listReadingByPiezometer: Array<ChartPiezometerReadingType>;
  /** get paginated reading values by instruments */
  listReadingByPiezometersPag: PaginatedPiezometerInaReading;
  /** get reading values by pluviometer */
  listReadingByPluviometer: Array<ChartPluviometerReadingType>;
  /** get reading values by pluviometer paginated */
  listReadingByPluviometerPag: PaginatedReadingType;
  /** get reading values by surface markes, on a interval from a start date to a end date. To be used on charts. */
  listReadingBySurfaceMarkers: Array<ChartSurfaceMarkerReadingType>;
  /** get reading values by surface marker paginated */
  listReadingBySurfaceMarkersPag: SurfaceMarkersReadingPaginatedType;
  /** get reading values by water levels ids */
  listReadingByWaterLevel: Array<ChartWaterLevelReadingType>;
  /** get paginated reading values by water levels ids */
  listReadingByWaterLevelPag: PagWaterLevelReadingType;
  /** get paginated references by surface marker */
  listReferencesBySurfaceMarkerPag: PaginatedSurfaceMarkerReferences;
  /** List all sections */
  listSection: Array<ListSectionsType>;
  /** list sections by structure */
  listSectionsByStructure: Array<SectionType>;
  /** List all modules */
  listStructureAndModules: Array<ListStructureModulesType>;
  /** List structure */
  listStructures: Array<ListStructuresType>;
  /** list subscribed user notification */
  listSubscribedUserNotification: Array<ListSubscribedUserNotificationType>;
  /** list all surface markers by structure */
  listSurfaceMarkersByStructure: Array<ListSurfaceMarkerType>;
  /** list unread module notifications count */
  listUnreadModuleNotifications: Array<ListModuleNotificationsType>;
  /** List users */
  listUsers: Array<AdminUserType>;
  /** List users permissions */
  listUsersPermissions: Array<ListUserPermissionType>;
  /** List water level by structure */
  listWaterLevelByStructure: Array<WaterLevelListType>;
  /** List instruments count and alert by type and structure */
  mobGetInstrumentInfosByUserStructures: Array<InstrumentInfoByUserStructureType>;
  /** list all user initial data for mobile */
  mobLoadStructuresInitialData: Array<MobInitialInspectionDataModelType>;
};

export type QueryListAllPluviometerReadingModelArgs = {
  period: PeriodInput;
  structureInfo: StructureInfoInput;
};

export type QueryFindActionPlanDataArgs = {
  actionPlanId: Scalars['String']['input'];
};

export type QueryFindActionPlanMembersArgs = {
  actionPlanId: Scalars['String']['input'];
};

export type QueryFindActionPlanPagArgs = {
  filters?: InputMaybe<ActionPlanFilterInput>;
  pageInfo: PageInput;
  sortInfo?: InputMaybe<Array<SortInput>>;
  structureId: Scalars['String']['input'];
};

export type QueryFindActionPlanResponsibleArgs = {
  structureId: Scalars['String']['input'];
};

export type QueryFindAllActionPlanImagesArgs = {
  actionPlanId: Scalars['String']['input'];
};

export type QueryFindAllActionPlansArgs = {
  structureId: Scalars['String']['input'];
};

export type QueryFindAllAssociatedActionPlansArgs = {
  actionPlanId: Scalars['String']['input'];
};

export type QueryFindAllAtoArgs = {
  structureId: Scalars['String']['input'];
};

export type QueryFindAllAtoImagesArgs = {
  atoId: Scalars['String']['input'];
};

export type QueryFindAllAtoVideosArgs = {
  atoId: Scalars['String']['input'];
};

export type QueryFindAllGistmDataArgs = {
  structureId: Scalars['String']['input'];
};

export type QueryFindAllInspectionsByStructureArgs = {
  structureId: Scalars['String']['input'];
};

export type QueryFindAllPsbDataArgs = {
  structureId: Scalars['String']['input'];
};

export type QueryFindAllRecordsPagArgs = {
  atoId: Scalars['String']['input'];
  filters?: InputMaybe<AtoFilterInput>;
  pageInfo: PageInput;
  sortInfo?: InputMaybe<Array<SortInput>>;
};

export type QueryFindAtoApproversArgs = {
  clientId: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
};

export type QueryFindAtoVerifiersArgs = {
  responsibleCompanyId: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
};

export type QueryFindCalculatedInfosArgs = {
  structureId: Scalars['String']['input'];
};

export type QueryFindDataByInspectionArgs = {
  inspectionId: Scalars['String']['input'];
};

export type QueryFindDataByRecordArgs = {
  recordId: Scalars['String']['input'];
};

export type QueryFindDataToChartArgs = {
  setionId: Scalars['String']['input'];
};

export type QueryFindFilesPagArgs = {
  pageInfo?: InputMaybe<PageInput>;
  psbId: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
};

export type QueryFindGeneralInfoArgs = {
  atoId: Scalars['String']['input'];
};

export type QueryFindGistmCommentsArgs = {
  gistmId: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
};

export type QueryFindGistmFilesPagArgs = {
  gistmId: Scalars['String']['input'];
  pageInfo?: InputMaybe<PageInput>;
  structureId: Scalars['String']['input'];
};

export type QueryFindInspectionConservationStateDataArgs = {
  inspectionId: Scalars['String']['input'];
};

export type QueryFindInspectionGenericDataArgs = {
  data: FindGenericDataInput;
};

export type QueryFindInspectionMssDataArgs = {
  inspectionId: Scalars['String']['input'];
};

export type QueryGetModuleByStructureArgs = {
  structureInfo: StructureInfoInput;
  userId: Scalars['String']['input'];
};

export type QueryGetStructureSummaryArgs = {
  structureInfo: StructureInfoInput;
};

export type QueryGetUserStructuresListArgs = {
  userId: Scalars['String']['input'];
};

export type QueryListAlertsLevelByPiezometersPagArgs = {
  instrumentId: Scalars['String']['input'];
  pageInfo: PageInput;
};

export type QueryListAlertsLevelByPluviometerPagArgs = {
  instrumentId: Scalars['String']['input'];
  pageInfo: PageInput;
};

export type QueryListAlertsLevelBySurfaceMarkerPagArgs = {
  instrumentId: Scalars['String']['input'];
  pageInfo: PageInput;
};

export type QueryListAlertsLevelByWaterLevelPagArgs = {
  instrumentId: Scalars['String']['input'];
  pageInfo: PageInput;
};

export type QueryListAllPiezometerReadingArgs = {
  period: PeriodInput;
  structureInfo: StructureInfoInput;
};

export type QueryListAllSurfaceMarkerReadingArgs = {
  period: PeriodInput;
  structureInfo: StructureInfoInput;
};

export type QueryListAllWaterLevelReadingArgs = {
  period: PeriodInput;
  structureInfo: StructureInfoInput;
};

export type QueryListAssociatedStructuresArgs = {
  structureId: Scalars['String']['input'];
};

export type QueryListCompaniesArgs = {
  data: ListCompaniesInput;
};

export type QueryListCompanyRelatedUsersAndStructuresArgs = {
  data: ListCompanyRelatedUsersAndStructuresInput;
};

export type QueryListControlQuotasByPiezometersPagArgs = {
  instrumentId: Scalars['String']['input'];
  pageInfo: PageInput;
};

export type QueryListInstrumentByIdArgs = {
  instrumentId: Scalars['String']['input'];
};

export type QueryListInstrumentsByStructureArgs = {
  structureInfo: StructureInfoInput;
};

export type QueryListInsturmentUserNotificationArgs = {
  filters: UserNotificationFilters;
};

export type QueryListLatestReadingByPiezometerArgs = {
  instrumentId: Scalars['String']['input'];
};

export type QueryListLatestReadingByPluviometerArgs = {
  instrumentId: Scalars['String']['input'];
};

export type QueryListLatestSurfaceMarkerReadingArgs = {
  instrumentId: Scalars['String']['input'];
};

export type QueryListLatestWaterLevelReadingArgs = {
  instrumentId: Scalars['String']['input'];
};

export type QueryListModulesByStructureIdArgs = {
  structureId: Scalars['String']['input'];
};

export type QueryListNotificationsByModulePagArgs = {
  filters?: InputMaybe<NotificationFilterInput>;
  moduleId: Scalars['String']['input'];
  pageInfo: PageInput;
};

export type QueryListPiezometersByStructureArgs = {
  structureInfo: StructureInfoInput;
};

export type QueryListPluviometersByStructureArgs = {
  structureInfo: StructureInfoInput;
};

export type QueryListReadingByPiezometerArgs = {
  endDate: Scalars['DateTime']['input'];
  instrumentIds: Array<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type QueryListReadingByPiezometersPagArgs = {
  filters?: InputMaybe<FilterInput>;
  instrumentType: InstrumentTypeEnum;
  pageInfo: PageInput;
  sortInfo?: InputMaybe<Array<SortInput>>;
  structureInfo: StructureInfoInput;
};

export type QueryListReadingByPluviometerArgs = {
  endDate: Scalars['DateTime']['input'];
  instrumentIds: Array<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type QueryListReadingByPluviometerPagArgs = {
  filters?: InputMaybe<FilterInput>;
  pageInfo?: InputMaybe<PageInput>;
  sortInfo?: InputMaybe<Array<SortInput>>;
  structureInfo: StructureInfoInput;
};

export type QueryListReadingBySurfaceMarkersArgs = {
  endDate: Scalars['DateTime']['input'];
  instrumentIds: Array<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type QueryListReadingBySurfaceMarkersPagArgs = {
  filters?: InputMaybe<FilterInput>;
  pageInfo?: InputMaybe<PageInput>;
  sortInfo?: InputMaybe<Array<SortInput>>;
  structureInfo: StructureInfoInput;
};

export type QueryListReadingByWaterLevelArgs = {
  endDate: Scalars['DateTime']['input'];
  instrumentIds: Array<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  structureId: Scalars['String']['input'];
};

export type QueryListReadingByWaterLevelPagArgs = {
  filters?: InputMaybe<FilterInput>;
  pageInfo?: InputMaybe<PageInput>;
  sortInfo?: InputMaybe<Array<SortInput>>;
  structureInfo: StructureInfoInput;
};

export type QueryListReferencesBySurfaceMarkerPagArgs = {
  instrumentId: Scalars['String']['input'];
  pageInfo: PageInput;
};

export type QueryListSectionsByStructureArgs = {
  structureId: Scalars['String']['input'];
};

export type QueryListSubscribedUserNotificationArgs = {
  filters: ListSubscribedUserNotificationFIltersInput;
};

export type QueryListSurfaceMarkersByStructureArgs = {
  structureInfo: StructureInfoInput;
};

export type QueryListUsersArgs = {
  data: ListUsersInput;
};

export type QueryListWaterLevelByStructureArgs = {
  structureInfo: StructureInfoInput;
};

export type QueryMobGetInstrumentInfosByUserStructuresArgs = {
  structureIds: Array<Scalars['String']['input']>;
};

export type ReadingsToChartType = {
  __typename?: 'ReadingsToChartType';
  bottomQuote?: Maybe<Scalars['Float']['output']>;
  quota: Scalars['Float']['output'];
  topQuote?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

export type RecentRecordsType = {
  __typename?: 'RecentRecordsType';
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  imagesCount: Scalars['Float']['output'];
  recordNumber: Scalars['String']['output'];
  situation: AtoSituationEnum;
  videosCount: Scalars['Float']['output'];
};

export type ResponsibleType = {
  __typename?: 'ResponsibleType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ResponsibleUserInput = {
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type RiskAssessmentType = {
  __typename?: 'RiskAssessmentType';
  downstreamEnvironmentalInterest?: Maybe<Scalars['String']['output']>;
  downstreamHumanOccupation?: Maybe<Scalars['String']['output']>;
  downstreamInstallations?: Maybe<Scalars['String']['output']>;
  /** Risk assessment id */
  id: Scalars['String']['output'];
  wasteType?: Maybe<Scalars['String']['output']>;
};

export type RoleType = {
  __typename?: 'RoleType';
  /** Role name */
  role: Scalars['String']['output'];
};

export type SaveAtoRecordObservationInput = {
  observation: Scalars['String']['input'];
  recordId: Scalars['String']['input'];
  type: AtoObservationTypeEnum;
};

export type SaveAtoRecordRedAlertPeriodInput = {
  end: Scalars['DateTime']['input'];
  recordId: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
};

export type SaveAtoRecordRedAlertPeriodType = {
  __typename?: 'SaveAtoRecordRedAlertPeriodType';
  end: Scalars['DateTime']['output'];
  recordId: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
};

export type SaveAtoRecordWeatherConditionsInput = {
  end: Scalars['DateTime']['input'];
  recordId: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
  weather: AtoWeatherConditionEnum;
};

export type SaveAtoRecordWorkShiftInput = {
  entry: Scalars['DateTime']['input'];
  entryInterval: Scalars['DateTime']['input'];
  exit: Scalars['DateTime']['input'];
  exitInterval: Scalars['DateTime']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  recordId: Scalars['String']['input'];
};

export type SaveConservationStateInput = {
  note: ConservationStateNoteEnum;
  title: ConservationStateTitleEnum;
  topic: Scalars['String']['input'];
};

export type SaveGenericDataInput = {
  answer: AnswerEnum;
  observation?: InputMaybe<Scalars['String']['input']>;
  title: TitleEnum;
  topic: Scalars['String']['input'];
};

export type SaveGenericDataWithImagesInput = {
  answer: AnswerEnum;
  images?: InputMaybe<Array<SaveInspectionImageType>>;
  observation?: InputMaybe<Scalars['String']['input']>;
  title: TitleEnum;
  topic: Scalars['String']['input'];
};

export type SaveInpectionAllDataInput = {
  accessSituation: Array<SaveGenericDataWithImagesInput>;
  /** Revestimento */
  coating: MssGenericDataWithImagesInput;
  conservationState: Array<SaveConservationStateInput>;
  extravasators: Array<SaveGenericDataWithImagesInput>;
  generalComments: Scalars['String']['input'];
  /** Trincas identificadas */
  identifiedCracks: Array<MssIdentifiedCracksWithImagesInput>;
  /** Recalques identificados */
  identifiedDeformations: Array<MssIdentifiedDeformationsWithImagesInput>;
  /** Surgência dágua */
  identifiedWaterLeaks: Array<MssIdentifiedWaterLeaksWithImagesInput>;
  inspection: CreateInspectionMobileInput;
  instrumentation: Array<SaveGenericDataWithImagesInput>;
  internalDrainage: Array<MssGenericDataWithImagesInput>;
  presenceOfTreesAnimalsInsects: Array<MssGenericDataWithImagesInput>;
  reservoir: Array<SaveGenericDataWithImagesInput>;
  /** Drenagem superficial */
  surfaceDrainage: Array<MssGenericDataWithImagesInput>;
  /** Erosões superficiais */
  surfaceErosions: MssGenericDataWithImagesInput;
  /** Bombeamento de rejeito/captação de água */
  waterWastePumpingPiping: Array<SaveGenericDataWithImagesInput>;
};

export type SaveInspectionImageType = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  image: Scalars['Upload']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
};

export type SaveInstrumentDocsInput = {
  docs: Array<Scalars['Upload']['input']>;
  instrumentId: Scalars['String']['input'];
};

export type SaveMassiveStructureShoulderDataInput = {
  genericData: Array<MssGenericDataInput>;
  identifiedCracks: Array<MssIdentifiedCracksInput>;
  identifiedDeformations: Array<MssIdentifiedDeformationsInput>;
  identifiedWaterLeaks: Array<MssIdentifiedWaterLeaksInput>;
};

export type SaveRecordEquipmentAndLaborInput = {
  description: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  recordId: Scalars['String']['input'];
  type: AtoEquipmentLaborTypeEnum;
};

export type SavedActionPlanCommentType = {
  __typename?: 'SavedActionPlanCommentType';
  comment: Scalars['String']['output'];
  files: Array<ActionPlanCommentFilesType>;
  id: Scalars['String']['output'];
};

export type SavedActionPlanTeamMembersType = {
  __typename?: 'SavedActionPlanTeamMembersType';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SectionType = {
  __typename?: 'SectionType';
  finalCoordinateE: Scalars['Float']['output'];
  finalCoordinateN: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  initialCoordinateE: Scalars['Float']['output'];
  initialCoordinateN: Scalars['Float']['output'];
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export enum SectionTypeEnum {
  Cross = 'Cross',
  Longitudinal = 'Longitudinal'
}

export type SetActionPlanProgressInput = {
  actionPlanId: Scalars['String']['input'];
  priority: ActionPlanPriorityEnum;
  responsibleId: Scalars['String']['input'];
  timeFrame: Scalars['DateTime']['input'];
};

/** Sort direction */
export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SortInput = {
  /** Sort direction */
  direction?: InputMaybe<SortDirection>;
  /** Field to use as sort reference */
  field: Scalars['String']['input'];
};

export type StructureBaseType = {
  __typename?: 'StructureBaseType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type StructureComplexType = {
  __typename?: 'StructureComplexType';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  neighborhood: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type StructureDamTailingVolumeType = {
  __typename?: 'StructureDamTailingVolumeType';
  date: Scalars['DateTime']['output'];
  /** Dam tailing volume id */
  id: Scalars['String']['output'];
  volume: Scalars['Float']['output'];
};

export type StructureGeometryType = {
  __typename?: 'StructureGeometryType';
  bermLength?: Maybe<Scalars['Float']['output']>;
  crestElevation?: Maybe<Scalars['Float']['output']>;
  crestLength?: Maybe<Scalars['Float']['output']>;
  crestWidth?: Maybe<Scalars['Float']['output']>;
  globalSlopeDownstream?: Maybe<Scalars['String']['output']>;
  /** Geometry id */
  id: Scalars['String']['output'];
  slopeBetweenBerms?: Maybe<Scalars['String']['output']>;
  upstreamSlopeInclination?: Maybe<Scalars['String']['output']>;
};

export type StructureInfoInput = {
  associatedStructureId?: InputMaybe<Scalars['String']['input']>;
  structureId: Scalars['String']['input'];
};

export type StructureModulesType = {
  __typename?: 'StructureModulesType';
  associatedStructures: Array<AssociatedType>;
  /** structure id */
  id: Scalars['String']['output'];
  modules: Array<ModulesTypes>;
  /** structure name */
  name: Scalars['String']['output'];
};

export type StructureReservoirHandlingType = {
  __typename?: 'StructureReservoirHandlingType';
  /** Reservoir id */
  id: Scalars['String']['output'];
  reservoirOccupiedArea?: Maybe<Scalars['Float']['output']>;
  reservoirOccupiedVolume?: Maybe<Scalars['Float']['output']>;
  reservoirTotalVolume?: Maybe<Scalars['Float']['output']>;
  tailingVolumeHistory: Array<StructureDamTailingVolumeType>;
  waterVolume?: Maybe<Scalars['Float']['output']>;
};

export type StructureResponsibleType = {
  __typename?: 'StructureResponsibleType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type StructureSummaryType = {
  __typename?: 'StructureSummaryType';
  ANMClassification: AnmClassificationEnum;
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  datumName: DatumEnum;
  decharacterizationDate?: Maybe<Scalars['DateTime']['output']>;
  /** Summary id */
  id: Scalars['String']['output'];
  isUTM: Scalars['Boolean']['output'];
  maxHeight?: Maybe<Scalars['Float']['output']>;
  operationEnd?: Maybe<Scalars['DateTime']['output']>;
  operationStart?: Maybe<Scalars['DateTime']['output']>;
  operationalStatus?: Maybe<Scalars['String']['output']>;
  potentialDamage?: Maybe<ClassificationEnum>;
  riskClassification: ClassificationEnum;
  utmZone?: Maybe<Scalars['String']['output']>;
};

export type StructureType = {
  __typename?: 'StructureType';
  associatedStructures?: Maybe<Array<AssociatedType>>;
  company: CompanyType;
  complex?: Maybe<StructureComplexType>;
  damRaisingHistory: Array<DamRaisingType>;
  geometry?: Maybe<StructureGeometryType>;
  hydrologyInfos?: Maybe<SummaryHydrologyType>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  reservoirHandling?: Maybe<StructureReservoirHandlingType>;
  responsible: StructureResponsibleType;
  riskAssessment?: Maybe<RiskAssessmentType>;
  sections?: Maybe<Array<SectionType>>;
  status: Scalars['Boolean']['output'];
  summary?: Maybe<ListStructureSummary>;
  type: Scalars['String']['output'];
};

export type SummaryHydrologyType = {
  __typename?: 'SummaryHydrologyType';
  NAMaximoMaximorum?: Maybe<Scalars['Float']['output']>;
  criticalDuration?: Maybe<Scalars['Float']['output']>;
  /** Hydrology id */
  id: Scalars['String']['output'];
  inflowRate?: Maybe<Scalars['Float']['output']>;
  outflowRate?: Maybe<Scalars['Float']['output']>;
  projectRainfall?: Maybe<Scalars['Float']['output']>;
  reservoirContributionArea?: Maybe<Scalars['Float']['output']>;
  returnPeriod?: Maybe<Scalars['String']['output']>;
  spillwaySill?: Maybe<Scalars['Float']['output']>;
  spillwayType?: Maybe<Scalars['String']['output']>;
  volumeTotalElevationCrest?: Maybe<Scalars['Float']['output']>;
};

export type SurfaceMarkerAlertLevel = {
  __typename?: 'SurfaceMarkerAlertLevel';
  coordinateE?: Maybe<Scalars['Float']['output']>;
  coordinateN?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  elevation?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Surface marker */
export type SurfaceMarkerType = {
  __typename?: 'SurfaceMarkerType';
  /** Surface marker id */
  id: Scalars['String']['output'];
};

export type SurfaceMarkersAlertLevelInput = {
  coordinateE?: InputMaybe<Scalars['Float']['input']>;
  coordinateN?: InputMaybe<Scalars['Float']['input']>;
  date: Scalars['DateTime']['input'];
  elevation?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
};

export type SurfaceMarkersBaseData = {
  __typename?: 'SurfaceMarkersBaseData';
  coordinateE?: Maybe<Scalars['Float']['output']>;
  coordinateN?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  elevation?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
};

export type SurfaceMarkersBaseDataInput = {
  coordinateE?: InputMaybe<Scalars['Float']['input']>;
  coordinateN?: InputMaybe<Scalars['Float']['input']>;
  date: Scalars['DateTime']['input'];
  elevation?: InputMaybe<Scalars['Float']['input']>;
};

export type SurfaceMarkersReadingPaginatedType = {
  __typename?: 'SurfaceMarkersReadingPaginatedType';
  /** Total number of elements */
  count: Scalars['Int']['output'];
  /** Array of PaginatedSurfaceMarkersReadingType */
  nodes: Array<PaginatedSurfaceMarkersReadingType>;
  /** Page information */
  pageInfo: PageInfo;
};

/** inspection data titles */
export enum TitleEnum {
  AccessSituation = 'AccessSituation',
  Extravasator = 'Extravasator',
  Instrumentation = 'Instrumentation',
  Reservoir = 'Reservoir',
  WaterWastePumpingPiping = 'WaterWastePumpingPiping'
}

export type UpdateActionPlanInput = {
  actionPlanId: Scalars['String']['input'];
  priority: ActionPlanPriorityEnum;
  responsibleId: Scalars['String']['input'];
  timeFrame: Scalars['DateTime']['input'];
};

export type UpdateAtoInput = {
  clientApproverId?: InputMaybe<Scalars['String']['input']>;
  contract: Scalars['String']['input'];
  finalDate: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  responsibleCompanyId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  verifierId: Scalars['String']['input'];
};

export type UpdateConservationStateInput = {
  id: Scalars['String']['input'];
  note: ConservationStateNoteEnum;
  title: ConservationStateTitleEnum;
  topic: Scalars['String']['input'];
};

export type UpdateEquipmentLaborInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  type: AtoEquipmentLaborTypeEnum;
};

export type UpdateGenericDataInput = {
  answer: AnswerEnum;
  id: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  title: TitleEnum;
  topic: Scalars['String']['input'];
};

export type UpdateMssGenericDataInput = {
  answer: AnswerEnum;
  id: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  title: MssGenericDataTitleEnum;
  topic?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMssIdentifiedCrackInput = {
  id: Scalars['String']['input'];
  length: Scalars['String']['input'];
  longitudinalTransverse: Scalars['String']['input'];
  name: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  opening: Scalars['String']['input'];
};

export type UpdateMssIdentifiedDeformationInput = {
  id: Scalars['String']['input'];
  identifiedAnomaly: IdenfiedAnomaliesEnum;
  local: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMssIdentifiedWaterLeaksInput = {
  id: Scalars['String']['input'];
  local: Scalars['String']['input'];
  name: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  visibleSolidTransport: Scalars['String']['input'];
};

export type UpdateRecordActivityInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type UpdateRecordImageObservationInput = {
  id: Scalars['String']['input'];
  observation: Scalars['String']['input'];
};

export type UpdateRecordObservationInput = {
  id: Scalars['String']['input'];
  observation: Scalars['String']['input'];
  type: AtoObservationTypeEnum;
};

export type UpdateRecordRedAlertPeriodInput = {
  end: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
};

export type UpdateRecordVideoObservationInput = {
  id: Scalars['String']['input'];
  observation: Scalars['String']['input'];
};

export type UpdateRecordWeatherConditionsInput = {
  end: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
  weather: AtoWeatherConditionEnum;
};

export type UpdateRecordWorkShiftInput = {
  entry: Scalars['DateTime']['input'];
  entryInterval: Scalars['DateTime']['input'];
  exit: Scalars['DateTime']['input'];
  exitInterval: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSectionChartParametersInput = {
  imageHeight: Scalars['Float']['input'];
  imageWidth: Scalars['Float']['input'];
  inferiorPointY: Scalars['Float']['input'];
  instruments: Array<InstrumentsParameterToChartType>;
};

/** Generic data answer */
export type UpdatedGenericDataType = {
  __typename?: 'UpdatedGenericDataType';
  answer: AnswerEnum;
  id: Scalars['String']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  title: TitleEnum;
  topic: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  /** User email */
  email: Scalars['String']['output'];
  /** User id */
  id: Scalars['String']['output'];
  /** User name */
  name: Scalars['String']['output'];
  /** User role */
  role: RoleType;
};

export type UserNotificationFilters = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserNotificationType = {
  __typename?: 'UserNotificationType';
  alertLevel: Scalars['String']['output'];
  alertLevelValue: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  installLocation?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  reading: Scalars['Float']['output'];
  readingDate: Scalars['DateTime']['output'];
  sentAt: Scalars['DateTime']['output'];
  structureName: Scalars['String']['output'];
  unread: Scalars['Boolean']['output'];
};

/** user roles */
export enum UserRole {
  Owner = 'Owner',
  Viewer = 'Viewer'
}

export type UserRoleInput = {
  role: UserRole;
};

/** User type */
export enum UserTypeEnum {
  ClientApprover = 'ClientApprover',
  Elaborator = 'Elaborator',
  Verifier = 'Verifier'
}

export type ValidateReadingValuesInfosModelType = {
  __typename?: 'ValidateReadingValuesInfosModelType';
  error?: Maybe<Scalars['String']['output']>;
  instrument?: Maybe<Scalars['String']['output']>;
  line?: Maybe<Scalars['Float']['output']>;
};

export type VerifierApproverType = {
  __typename?: 'VerifierApproverType';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type WaterLevelAlertLevelsType = {
  __typename?: 'WaterLevelAlertLevelsType';
  date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type WaterLevelListType = {
  __typename?: 'WaterLevelListType';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  coordinateE: Scalars['Float']['output'];
  coordinateN: Scalars['Float']['output'];
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  installLocation?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['DateTime']['output']>;
  isUTM: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  operationalStatus: InstrumentStatusEnum;
  readingType: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  type?: Maybe<InstrumentType>;
};

export type EditStructureDamRaisingInput = {
  date: Scalars['DateTime']['input'];
  elevation: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  method: Scalars['String']['input'];
  stage: Scalars['String']['input'];
  structureId: Scalars['String']['input'];
};
