import React, { useContext, useState } from 'react';
import Button from '../../Atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { useDeleteInstrumen } from '../../../../data/hooks/use-delete-instrument';
import Text from '../../Atoms/Text/Text';
import { capitalizeFirstLetter } from '../../../../utils/StringManipulattor';
import { RegisterInstrument } from '../../../Instruments/Register/RegisterInstrument';
import { useQuery } from '@apollo/client';
import {
  ListSurfaceMarkersByStructureDocument,
  ListSurfaceMarkersByStructureQuery,
  ListSurfaceMarkersByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listSurfaceMarkersByStructure.query';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import Enums from '../../../../utils/enumns';
import { ProjectContext } from '../../../../Context/ContextAPI';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import {
  DivContainerTable,
  DivTable
} from '../../../../Modules/Monitoring/MonitoringScreenStyle';
import TableDraggableComponent from '../../Molecules/TableDraggable/TableDraggableComponent';
import { TABLE_IDS } from '../../../../utils/TableConsts';
const SurfaceMarkersListPage = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { structureId, instrumentType } = useParams();
  const { deleteInstruments } = useDeleteInstrumen();
  const { UserRoles } = Enums();
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRole = getUserRoleByModule('monitoring');

  const [idDelete, setIdDelete] = useState<string>('');
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);

  const { data: listAllSurfaceMarkers, loading } = useQuery<
    ListSurfaceMarkersByStructureQuery,
    ListSurfaceMarkersByStructureQueryVariables
  >(ListSurfaceMarkersByStructureDocument, {
    variables: {
      structureInfo: {
        structureId: structureId!,
        associatedStructureId: null
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const actionsTable: AvailableContextActionsProps<any>[] = [
    {
      name: `${translate('Delete')}`,
      icon: <MdDelete />,
      onClick: (instrument) => {
        setIdDelete(instrument.id);
      },
      canShow: 'canDelete'
    }
  ];

  const handleClickTableRow = (row: any) => {
    const currentPath = window.location.pathname;
    userRole !== UserRoles.Viewer
      ? navigate(`${currentPath}/edit/${row.id}`)
      : navigate(`${currentPath}/view/${row.id}`);
  };

  const handleDeleteInstrument = async (instrumentId: string) => {
    const response = await deleteInstruments({
      variables: {
        structureId: structureId!,
        instrumentId: instrumentId
      }
    });
    toastifyLoading('Deletando Instrumento...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      toastfySuccess('Instrumento Deletada Com Sucesso');
      toastfyDimiss('toastfySuccess');
      setIdDelete('');
    } else if (response.errors) {
      toastfyDimiss('toastLoading');
      toastfyError(
        response.errors[0].message ||
          translate(ToastifyModel().toastifyMessage.error)
      );
      setIdDelete('');
    }
  };

  const instrumentfilteredData =
    listAllSurfaceMarkers?.listSurfaceMarkersByStructure;

  const rowsInstrumentData = instrumentfilteredData?.map((instrument) => {
    return {
      ...instrument,
      operationalStatus: translate(instrument.operationalStatus),
      readingType: translate(instrument.readingType),
      installationDate: instrument.installationDate
        ? new Date(instrument.installationDate).toLocaleDateString()
        : translate('NoDataAvailable'),
      deactivationDate: instrument.deactivationDate
        ? new Date(instrument.deactivationDate).toLocaleDateString()
        : translate('NoDataAvailable'),
      activationDate: instrument.activationDate
        ? new Date(instrument.activationDate).toLocaleDateString()
        : translate('NoDataAvailable'),
      installLocation: instrument.installLocation
        ? translate(instrument.installLocation)
        : translate('NoDataAvailable')
    };
  });

  const SurfaceMarkerColumns = [
    {
      key: 'name',
      label: translate('name')
    },
    {
      key: 'operationalStatus',
      label: translate('operationalStatus')
    },
    {
      key: 'coordinateE',
      label: translate('coordinateE')
    },
    {
      key: 'coordinateN',
      label: translate('coordinateN')
    },
    {
      key: 'readingType',
      label: translate('readingType')
    },
    {
      key: 'installLocation',
      label: translate('installLocation')
    },
    {
      key: 'installationDate',
      label: translate('installationDate')
    },
    {
      key: 'activationDate',
      label: translate('activationDate')
    },
    {
      key: 'deactivationDate',
      label: translate('deactivationDate')
    }
  ];

  return (
    <>
      <SimpleContentTemplate
        loading={loading}
        title={
          <Text type="h1" size={'large'}>
            {translate(capitalizeFirstLetter(instrumentType!))}
          </Text>
        }
        button={
          userRole !== UserRoles.Viewer && (
            <Button
              variant={'primary'}
              text={translate('RegisterInstrument')}
              onClick={() => setShowModalCreate(true)}
            />
          )
        }
        content={
          <>
            <DivContainerTable>
              <DivTable>
                <TableDraggableComponent
                  tableId={TABLE_IDS.SURFACE_MARKER_LIST_TABLE}
                  columnsArray={SurfaceMarkerColumns}
                  tableData={rowsInstrumentData || []}
                  handleClickRow={handleClickTableRow}
                  actions={
                    userRole !== UserRoles.Viewer ? actionsTable : undefined
                  }
                  sortUrl
                  searchUrl
                  columnSelect
                  showCountColumn
                />
              </DivTable>
            </DivContainerTable>
          </>
        }
      />
      {idDelete && (
        <ModalConfirm
          title={translate('Deletar Instrumento')}
          text={translate('ConfirmDelete')}
          subtitle={translate('ActionCannotBeUndone')}
          onClose={() => setIdDelete('')}
          onConfirm={() => {
            handleDeleteInstrument(idDelete);
          }}
        />
      )}
      {showModalCreate && (
        <RegisterInstrument
          setShowModal={setShowModalCreate}
          showModal={showModalCreate}
          setDataChanged={setShowModalCreate}
        />
      )}
    </>
  );
};

export default SurfaceMarkersListPage;
